<template>
    <div class="check-box-group">
        <span class="check-box">{{ value === normalValue ? '☑' : '☐' }}</span>{{ normalText }}

        <span class="check-box-separator"></span>
        <span class="check-box">{{ value === abnormalValue ? '☑' : '☐' }}</span>{{ abnormalText }}
    </div>
</template>

<script>
export default {
    name: 'CheckBox',
    props: {
        value: {
            type: String,
            default: ''
        },
        normalText: {
            type: String,
            default: '正常'
        },
        abnormalText: {
            type: String,
            default: '异常'
        },
        normalValue: {
            type: String,
            default: '1'
        },
        abnormalValue: {
            type: String,
            default: '0'
        }
    }
};
</script>

<style lang="less" scoped>
.check-box-group {
    display: inline-flex;
    align-items: center;

    .check-box {
        font-family: "Segoe UI Symbol", sans-serif;
        margin: 0 4px;
        font-size: 16px;
    }

    .check-box-separator {
        width: 10px;
        height: 10px;
    }
}
</style>