<template>
  <div class="root-box">
    <div class="query-container">
      <el-form size="small" :inline="true">
        <el-form-item label="路线：" prop="pathId">
          <el-select style="width: 160px" v-model="param.pathId" placeholder="请选择" @change="query" clearable>
            <el-option v-for="item in pathOpts" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="区域：" prop="isStart">
          <el-select style="width: 160px" v-model="param.isStart" placeholder="请选择" @change="query" clearable>
            <el-option label="起点" :value="true"></el-option>
            <el-option label="终点" :value="false"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button style="width: 100px; margin-right: 20px" type="primary" @click="query">查询</el-button>
        </el-form-item>


        <el-form-item v-if="chooseItem" style="float: right">
          <el-button-group>
            <el-button type="success" icon="el-icon-delete" @click="removeOverlays">删除</el-button>
            <el-button type="success" icon="el-icon-edit" @click="editArea">修改</el-button>
            <el-button type="success" icon="el-icon-refresh-right" @click="clearChoose">清除选择</el-button>
            <el-button v-if="tempOverlay" type="warning" icon="el-icon-upload" @click="updateOverlay">保存修改</el-button>
          </el-button-group>
        </el-form-item>
      </el-form>
    </div>

    <div class="up_price_map">
      <div id="up_price_map"></div>

      <div class="input-card">
        <div class="input-item">
          <el-radio-group v-model="drawType">
            <el-radio :label="SHAPE.polygon">画多边形</el-radio>
            <el-radio :label="SHAPE.none">不选择</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="visible" width="500px" max-height="600" title="区域设置" :close-on-click-modal="false">
      <el-form ref="form" :model="form" label-width="140px" :size="size" :rules="rules">

        <el-form-item label="路线：" prop="pathId">
          <el-select disabled v-model="form.pathId" style="width: 100%">
            <el-option v-for="item in pathOpts" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="区域类型：" prop="areaType">
          <el-select disabled v-model="form.areaType" style="width: 100%">
            <el-option v-for="item in areaTypeOpts" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="form.areaType === AREA_TYPE.CONDITION_AREA" label="是否场站：" prop="isStation">
          <el-select v-model="form.isStation" style="width: 100%">
            <el-option v-for="item in yesNoOpts" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="起点或终点生效：" prop="depOrDest">
          <el-select v-model="form.depOrDest" multiple style="width: 100%">
            <el-option v-for="item in depOrDestOptions" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="推荐点文案：" prop="recommendReason">
          <el-input type="textarea" :rows="2" v-model="form.recommendReason" style="width: 100%"></el-input>
        </el-form-item>

      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="save">保存</el-button>
        <el-button type="info" :size="size" @click="cancel">取消</el-button>
      </span>
    </el-dialog>

    <RecPointModal ref="recPointModal" @save="saveRecPoints" />

  </div>
</template>

<script>
import RecPointModal from "./RecPointModal.vue";
import {
  listUpriceArea,
  addUpriceArea,
  modifyUpriceArea,
  removeUpriceArea,
} from "@/api";
import { SHOW_LOCATION_SELECT_MAP } from "@/core/const/global-event";
import { MapUtil, Toolkit } from "@/util";
import { BaseDataService } from "@/service";
import { mapState } from "vuex";
import config from "@/config/config";
import _ from "lodash";
const markB = require("../../../assets/image/mark_b.png");

const SHAPE = {
  polygon: "3",
  none: "none",
};

const AREA_TYPE = {
  UPRICE_AREA: 1,
  CITY_SCOPE: 2,
  STANDARD_AREA: 3,
  RECOMMEND_ADDRESS: 4,
  EXPRESSWAY_AREA: 5,
  CONDITION_AREA: 6,
  DISCOUNT_AREA: 7,
  SPEC_AREA: 10,
  SPEC_AREA2: 100,
};

export default {
  components: {
    RecPointModal,
  },
  mounted() {
    this.initMap();
    this.draw(this.drawType);
    this.query();
  },
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      areaTypeOpts: (state) => state.enumMap["AREA_TYPE"],
      areaOpts: (state) => state.areaOpts,
      pathOpts: (state) => {
        const pathList = state.pathList;
        return pathList
          .filter((item) => item.isStartPath);
      },
    }),

  },
  watch: {
    drawType(val) {
      this.draw(val);
    },

    'param.areaType': {
      handler: function () {
        this.tempOverlay = null;
      },

    },

  },
  data() {
    return {
      SHAPE,
      AREA_TYPE,
      yesNoOpts: BaseDataService.yesNoOpts(),
      param: {
        areaType: AREA_TYPE.CONDITION_AREA,
        isStart: true,
        pathId: config.initPath,
      },
      drawType: SHAPE.none,
      visible: false,
      drawerVisible: false,
      depOrDestOptions: [
        { label: '起点', value: 1 },
        { label: '终点', value: 2 },
      ],
      form: {},
      rules: {},
      list: [],
      copyParam: {},
      chooseItem: null,
      tempOverlay: null,
      priceItem: null,
      upStyle: {},
      pointForm: {},
    };
  },
  methods: {
    initMap() {
      this.map = new AMap.Map("up_price_map", {
        resizeEnable: true,
        dragEnable: true,
        zoom: 14,
      });

      // this.map.on("rightclick", this.rightclick);

      // 绘制矢量图形：https://lbs.amap.com/api/javascript-api/guide/overlays/editable-vector-overlay/?sug_index=0
      AMap.plugin(
        [
          "AMap.MouseTool",
          "AMap.PolygonEditor",
          "AMap.Scale",
        ],
        () => {
          const mouseTool = new AMap.MouseTool(this.map);

          const scale = new AMap.Scale();
          this.map.addControl(scale);

          this.mouseTool = mouseTool;
          //监听draw事件可获取画好的覆盖物
          mouseTool.on("draw", (e) => {
            if (this.param.pathId) {
              setTimeout(() => {
                this.openForm(e.obj);
              }, 100);
            }
          });
        }
      );
    },


    async query() {
      if (!this.param.pathId) {
        return;
      }
      this.drawType = SHAPE.none;

      this.clearChoose();
      this.map.clearMap();
      const param2 = {
        ...this.param,
        areaType: AREA_TYPE.CITY_SCOPE,
      };
      const res2 = await listUpriceArea(param2);
      const list2 = res2.data || [];
      this.renderMap(list2, false, {
        strokeColor: "#000",
        fillColor: "transparent",
      });


      const param = {
        ...this.param,
        areaType: AREA_TYPE.CONDITION_AREA,
      };
      const res = await listUpriceArea(param);
      this.list = res.data || [];
      this.renderMap(this.list, true, {
        strokeColor: "#409EFF",
        fillColor: "#bae7ff",
      });

      this.list.forEach((item) => {
        this.showConditionAreaMarker(item);
      });
    },

    saveRecPoints(recPoints) {
      const overlay = this.tempOverlay;
      const areaPoints = MapUtil.overlay2Points(overlay);
      const extData = overlay.getExtData();
      const param = {
        ...extData.originData,
        areaPoints,
        recPoints
      };
      modifyUpriceArea(param).then(() => {
        this.$message.success("区域更新成功");
      });
    },

    draw(type) {
      const mouseTool = this.mouseTool;

      const fillColor = "#80d8ff";
      const strokeColor = "#00b0ff";
      switch (type) {
        case SHAPE.polygon: {
          mouseTool.polygon({
            fillColor,
            strokeColor,
          });
          break;
        }
        default:
          mouseTool && mouseTool.close(true);
          break;
      }
    },

    openForm(overlay) {

      this.visible = true;
      this.overlay = overlay;
      const { pathId, areaType } = this.param;

      if (!pathId || !areaType) {
        this.$message.error("请选择条件");
        return;
      }

      this.form = {
        ...this.param,
        recommendReason: "非公共道路区域，请在就近推荐点上车",
      };
    },

    save() {

      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        this.visible = false;

        if (this.form.id) {
          const overlay = this.tempOverlay;
          const areaPoints = MapUtil.overlay2Points(overlay);

          // 获取最新的区域
          const param = {
            ...this.form,
            areaPoints,
          };

          modifyUpriceArea(param).then(() => {
            this.drawType = SHAPE.none;
            this.$message.success("区域修改成功");
            this.query();
          });
        } else {
          const areaPoints = MapUtil.overlay2Points(this.overlay);
          const param = {
            ...this.form,
            areaPoints,
          };
          addUpriceArea(param).then(() => {
            this.drawType = SHAPE.none;
            this.$message.success("区域保存成功");
            this.query();
          });
        }
      });
    },

    cancel() {
      this.drawType = SHAPE.none;
      this.visible = false;
      if (this.overlay) {
        this.map.remove(this.overlay);
      }
    },

    renderMap(data = [], clickable = true, colorInfo) {
      const map = this.map;
      this.drawType = SHAPE.none;
      const overlays = data.map((item) => MapUtil.json2Overlay(item, colorInfo));

      this.overlays = overlays;

      if (clickable) {
        overlays.forEach((item) => {
          item.on("click", () => {
            this.chooseOverlay(item);
          });
          item.on("mouseover", () => {
            this.priceItem = item;
          });
        });
      }

      map.add(overlays);
      map.setFitView();
    },


    showConditionAreaMarker(itemData) {
      const markers = [];
      // 找到叶子层的节点
      const leafList = Toolkit.findLeafNodes(itemData.recPoints, 'nextRecPoints');
      leafList.forEach((item) => {
        const marker = new AMap.Marker({
          position: [item.lng, item.lat],
          title: item.name,
          draggable: false,
          extData: item,
          label: {
            content: "<div class='info'>" + item.name + "</div>",
            direction: "top",
          },

          anchor: "bottom-center",
        });

        // 推荐点点击事件
        // marker.on("click", this.handleRecPointClickEvent);
        markers.push(marker);
      });
      this.map.add(markers);
    },


    updateOverlay() {
      this.drawType = SHAPE.none;
      // 延迟100ms保存，等待polyEditor.close执行完成
      setTimeout(() => {
        if (this.tempOverlay) {
          const overlay = this.tempOverlay;
          const areaPoints = MapUtil.overlay2Points(overlay);
          const extData = overlay.getExtData();
          const param = {
            ...extData.originData,
            areaPoints,
          };

          modifyUpriceArea(param).then(() => {
            this.$message.success("区域更新成功");
            // this.query();
          });
        }
      }, 100);
    },

    saveTempOverlay(event) {
      // 存储临时修改的数据
      const overlay = event.target;
      this.tempOverlay = overlay;
    },

    chooseOverlay(item) {
      this.drawType = SHAPE.none;
      const itemData = item.getExtData().originData;
      if (itemData.areaType === AREA_TYPE.CONDITION_AREA) {
        const { pathId, isStart } = this.param;
        const path = this.pathOpts.find(d => d.id === pathId);
        const area = this.areaOpts.find(d => d.id === (isStart ? path.startArea : path.endArea));
        this.$refs.recPointModal.show(itemData, area);
      }
      if (this.polyEditor && this.chooseItem && this.chooseItem._amap_id === item._amap_id) {
        return;
      }

      this.clearChoose();
      // 监听拖动鼠标抬起事件
      item.on("mouseup", this.saveTempOverlay);
      const polyEditor = new AMap.PolygonEditor(this.map, item);
      polyEditor.on("end", this.saveTempOverlay);
      polyEditor.on("addnode", this.saveTempOverlay);
      polyEditor.on("adjust", this.saveTempOverlay);
      polyEditor.on("removenode", this.saveTempOverlay);
      polyEditor.open();
      this.polyEditor = polyEditor;
      this.chooseItem = item;

    },

    showLocationSelectMap(point) {
      this.drawType = SHAPE.none;

      const { pathId, isStart } = this.param;
      const area = BaseDataService.getRouteAreaByPathId(pathId, isStart);
      this.$bus.$emit(SHOW_LOCATION_SELECT_MAP, point, area, ({ lat, lng }) => {
        this.createWayPoint([lng, lat]);
      });
    },

    createWayPoint(position) {
      if (this.marker) {
        this.map.remove(this.marker);
      }
      const marker = new AMap.Marker({
        position,
        title: "途经点",
        icon: new AMap.Icon({
          image: markB,
        }),
        anchor: "bottom-center",
        draggable: true,
        label: {
          direction: "top",
          content: "<div class='info active'>途经点</div>",
        },
      });
      marker.on("click", () => {
        this.showLocationSelectMap({
          lat: position[1],
          lng: position[0],
          name: "途经点",
        });
      });
      this.marker = marker;
      this.map.add(marker);
    },

    clearChoose() {
      if (this.chooseItem) {
        const extData = this.chooseItem.getExtData();
        const itemData = extData.originData;
        // const opts = MapUtil.getOverlayDefaultOpts(originData);
        // this.chooseItem.setOptions(opts);
        this.chooseItem = null;
        this.tempOverlay = null;

        if (itemData.areaType !== AREA_TYPE.CONDITION_AREA) {
          // 清除所有marker
          const markers = this.map.getAllOverlays("marker");
          markers.forEach((item) => {
            this.map.remove(item);
          });
        }
      }
      if (this.polyEditor) {
        this.polyEditor.close();
        this.polyEditor = null;
      }
    },
    removeOverlays() {
      const extData = this.chooseItem.getExtData();
      const param = { id: extData.yxId };

      this.$confirm("确认删除该区域吗?", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        removeUpriceArea(param).then(() => {
          this.$message.success("区域删除成功");
          this.query();
        });
      });
    },
    editArea() {
      const extData = this.chooseItem.getExtData();
      const id = extData.yxId;
      const item = this.list.find((item) => item.id === id);
      if (item) {
        this.form = _.cloneDeep(item);
        this.visible = true;
      }
    },
  },
};
</script>

<style scoped lang="less">
#up_price_map {
  height: 100%;
}

.up_price_map {
  width: 100%;
  height: 750px;
  position: relative;
  overflow: hidden;

  .input-card {
    padding: 20px;
    position: absolute;
    background-color: #ffffff;
    right: 5px;
    bottom: 5px;
    font-size: 14px;
    border-radius: 0.4rem;
    box-shadow: 0 2px 6px 0 rgba(114, 124, 245, 0.5);
  }

  // .uprice-info {
  //   padding: 10px;
  //   position: absolute;
  //   background-color: #ffffff;
  //   right: 5px;
  //   width: 150px;
  //   top: 5px;
  //   font-size: 14px;
  //   border-radius: 0.4rem;
  //   box-shadow: 0 2px 6px 0 rgba(114, 124, 245, 0.5);
  // }

  .uprice-info {
    padding: 10px;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    color: wheat;
    right: 0;
    left: 0;
    margin: auto;
    width: 150px;
    top: 5px;
    font-size: 14px;
    border-radius: 0.4rem;
    -webkit-box-shadow: 0 2px 6px 0 rgba(114, 124, 245, 0.5);
    box-shadow: 0 2px 6px 0 rgba(114, 124, 245, 0.5);
  }
}

#tipinput {
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  border: none;
  outline: none;
}

.query-container {
  height: 40px;
  padding: 10px 20px;
}

.result {
  margin: 0;
  padding: 0;
  z-index: 999;
  position: absolute;
  background-color: white;
  height: 80%;
  overflow-y: auto;
  top: 0;
  right: 0;
  width: 295px;
  padding: 10px;
  box-shadow: 0px 3px 5px #333333;
  border-radius: 10px;

  &.left {
    left: 0;
    right: auto;
  }

  .marker-content {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;

    margin-bottom: 10px;

    &.disable {
      filter: grayscale(100%);
    }

    .name {
      font-size: 14px;
      display: flex;
      width: 220px;
      align-items: center;

      .icon {
        background: url(https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png) no-repeat;
        color: #fff;
        font-size: 12px;
        text-align: center;
        padding-top: 3px;
        width: 20px;
        height: 26px;
        background-size: cover;
        margin-right: 5px;
        display: inline-block;

        // transform: scale(0.9);
        &.active {
          background-image: url(https://webapi.amap.com/theme/v1.3/markers/n/mark_r.png);
        }
      }
    }

    .btn {
      margin-left: 10px;
      color: #4894ef;
      font-size: 14px;

      width: 40px;
    }
  }
}
</style>
