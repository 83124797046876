<template>
    <div class="repair-detail">


        <div id="printArea" :style="{ width: printWidth + 'mm' }">
            <h2 class="title">新能源营运车辆维修单</h2>

            <div class="info-table">
                <table>
                    <tr>
                        <td width="15%">车牌号</td>
                        <td width="25%">{{ mainBill.vehicleNo }}</td>
                        <td width="15%">车型品牌</td>
                        <td width="20%">{{ mainBill.brand }}</td>
                        <td width="10%">车辆颜色</td>
                        <td width="15%">{{ mainBill.color }}</td>
                    </tr>
                    <tr>
                        <td>驾驶司机</td>
                        <td>{{ mainBill.driverName }}</td>
                        <td>司机是否确认</td>
                        <td>是</td>
                        <td>维修日期</td>
                        <td>{{ (mainBill.arriveDate || mainBill.billDate) | dateFormat('yyyy-MM-dd') }}</td>
                    </tr>
                    <tr>
                        <td>维修项目</td>
                        <td colspan="5" class="content-cell">{{ repairItems }}</td>
                    </tr>
                    <tr>
                        <td>维修照片</td>
                        <td colspan="5" class="content-cell photo-cell">
                            <div class="photo-area">
                                <div class="photo-list">
                                    <div v-for="(img, index) in repairImgs" :key="index" class="photo-item">
                                        <img :src="img" alt="维修照片">
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>备注</td>
                        <td colspan="5" class="content-cell">{{ repairRemark }}</td>
                    </tr>
                    <tr>
                        <td>维修单位</td>
                        <td>{{ mainBill.providerName }}</td>
                        <td>当前里程数</td>
                        <td>{{ detail.miles }}公里</td>
                        <td>维修人员</td>
                        <td>{{ mainBill.staffName }}</td>
                    </tr>
                    <tr>
                        <td>维修签名</td>
                        <td colspan="5" class="content-cell">
                            <img :src="mainBill.staffSign" width="60px" height="auto">
                        </td>
                    </tr>
                </table>
            </div>



        </div>
    </div>
</template>

<script>
export default {
    name: 'RepairDetail',
    props: {
        printWidth: {
            type: Number,
            default: 210, // A4纸宽度，单位mm
        },
        data: {
            type: Object,
            default: () => ({})
        }
    },

    filters: {
        milesFormat(miles) {
            if (miles) {
                if (miles > 1000) {
                    return (miles / 1000).toFixed(2) + '公里';
                } else {
                    return miles + '米';
                }
            } else {
                return '';
            }
        }
    },
    watch: {
        data: {
            handler(newVal) {
                const { mainBill, detail, items } = newVal;
                this.form = detail;
                this.detail = detail;
                this.mainBill = mainBill;
                this.items = items;
            },
            deep: true
        }
    },

    computed: {
        repairItems() {
            const items = this.items || [];
            return items.map(item => item.itemName).join('、');
        },
        repairRemark() {
            const items = this.items || [];
            return items.filter(item => item.desc).map(item => item.desc).join('、');
        },
        repairImgs() {
            const items = this.items || [];
            const imgs = [];
            items.forEach(item => {
                if (item.photoes) {
                    imgs.push(...item.photoes);
                }
            });

            // 最多取2张
            if (imgs.length > 2) {
                return imgs.slice(0, 2);
            } else {
                return imgs;
            }
        }
    },
    data() {
        return {
            form: {},
            detail: {},
            mainBill: {},
            items: [],
        };
    },
    methods: {
        handlePrint() {
            window.print();
        }
    }
};
</script>

<style lang="less" scoped>
.repair-detail {
    padding: 20px;

    .print-header {
        margin-bottom: 20px;
        text-align: right;

        @media print {
            display: none;
        }
    }

    #printArea {
        margin: 0 auto;
        padding: 20px;
        background: #fff;

        .title {
            text-align: center;
            margin-bottom: 20px;
            font-size: 20px;
        }

        .info-table {
            table {
                width: 100%;
                border-collapse: collapse;
                table-layout: fixed;

                td {
                    border: 1px solid #000;
                    padding: 8px;
                    font-size: 14px;
                    line-height: 1.5;
                }

                .content-cell {
                    min-height: 60px;
                    vertical-align: top;
                }

                .photo-cell {
                    min-height: 120px;

                    .photo-area {
                        min-height: 100px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 1px dashed #ccc;
                        margin-bottom: 10px;

                        .photo-list {
                            display: flex;
                            flex-wrap: wrap;
                            gap: 10px;

                            .photo-item {
                                img {
                                    width: 200px;
                                    object-fit: contain;
                                    margin: 0 20px;
                                }
                            }
                        }

                        .empty-photo {
                            text-align: center;
                            color: #999;
                        }
                    }

                    .photo-tip {
                        font-size: 12px;
                        color: red;
                    }
                }
            }
        }
    }

    @media print {
        padding: 0;
        background: #fff;
    }
}
</style>
