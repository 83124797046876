import moment from "moment";

const WEEK = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
export default class DateUtil {
  /***
   * 将yyyy-MM-dd格式日期转为 1月13日 （周三） 今天 格式的文本
   * @param showWeek 是否显示星期
   * @param dateStr
   * @returns string
   */
  static date2Text(dateStr, showWeek = true) {
    if (typeof dateStr !== "string") {
      return "";
    }
    const date = DateUtil.str2Date(dateStr);
    const day = date.getDay(); // 星期
    const month = date.getMonth() + 1; // 月份
    const getDate = date.getDate(); // 当前日

    const dayStr = DateUtil._getDayName(date);

    if (showWeek) {
      return `${month}月${getDate}日 （${WEEK[day]}） ${dayStr}`;
    }

    return `${month}月${getDate}日 ${dayStr}`;
  }

  static date2WeekText(dateStr) {
    if (typeof dateStr !== "string") {
      return "";
    }
    const date = DateUtil.str2Date(dateStr);
    const day = date.getDay(); // 星期
    const month = date.getMonth() + 1; // 月份
    const getDate = date.getDate(); // 当前日
    return `${month}月${getDate}日 ${WEEK[day]}`;
  }

  /**
   * 判断yyyy-MM-dd格式日期是否为今天
   * @param dateStr
   * @returns {boolean}
   */
  static isToday(dateStr) {
    return dateStr === DateUtil.format(new Date());
  }

  /**
   * yyyy-mm-dd 格式字符串转date
   * @param dateStr
   * @returns {Date}
   */
  static str2Date(dateStr) {
    return new Date(dateStr.replace(/-/g, "/"));
  }

  static str2Timestamp(timeStr) {
    return DateUtil.str2Date(timeStr).getTime();
  }

  /**
   * 获取日期 今天 明天 后天
   * @param d {Date}
   * @returns {string}
   */
  static _getDayName(d) {
    let td = DateUtil.getDay();
    const xc = (d - td) / 1000 / 60 / 60 / 24;
    if (xc === 0) {
      return "今天";
    } else if (xc < 2 && xc > 0) {
      return "明天";
    } else if (xc < 3 && xc > 0) {
      return "后天";
    } else {
      return "";
    }
  }

  /**
   * 日期格式化
   * @param date
   * @param fmt
   * @returns {string}
   */
  static format(date, fmt = "yyyy-MM-dd") {
    const o = {
      "M+": date.getMonth() + 1, // 月份
      "d+": date.getDate(), // 日
      "h+": date.getHours(), // 小时
      "m+": date.getMinutes(), // 分
      "s+": date.getSeconds(), // 秒
      "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
      S: date.getMilliseconds(), // 毫秒
    };
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    }
    for (let k in o) {
      if (new RegExp("(" + k + ")").test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
        );
      }
    }
    return fmt;
  }

  static dateStrFormat(dateStr, fmt = "yyyy-MM-dd") {
    const date = DateUtil.str2Date(dateStr);
    return DateUtil.format(date, fmt);
  }

  /**
   * 获取指定日期,date
   * @param days
   */
  static getDay(days = 0) {
    let td = new Date();
    td = new Date(td.getFullYear(), td.getMonth(), td.getDate());
    const thatTime = td.getTime() + days * 24 * 60 * 60 * 1000;
    return new Date(thatTime);
  }

  /**
   * 获取指定日期字符串
   * @param days
   * @param fmt
   * @returns {string | *}
   */
  static getDayStr(days = 0, fmt) {
    const date = DateUtil.getDay(days);
    return DateUtil.format(date, fmt);
  }

  /**
   * 获取指定日期,dateTime
   * @param days
   */
  static getDateTime(days = 0) {
    let td = new Date();
    const thatTime = td.getTime() + days * 24 * 60 * 60 * 1000;
    return new Date(thatTime);
  }

  static getDateTimeStr(days = 0, fmt = "yyyy-MM-dd hh:mm:ss") {
    const date = DateUtil.getDateTime(days);
    return DateUtil.format(date, fmt);
  }

  /**
   * 获取指定日期字符串
   * @param days
   * @param fmt
   * @returns {string | *}
   */
  static getSpecDayStr(spec, days = 0, fmt = "YYYY-MM-DD") {
    const day = moment(spec);
    if (days > 0) {
      day.add(days, "days");
    } else {
      day.subtract(-days, "days");
    }
    return day.format(fmt);
  }

  /**
   * 获取最近n个月
   * @param num
   */
  static getLastMonth(num) {
    let result = [];
    for (let i = -num; i < 1; i++) {
      const m = moment().subtract(-i, "month");
      result.push(m.format("YYYY-MM"));
    }
    return result;
  }

  /**
   * 获取当前月的第一天
   */
  static getMonthFirstDay() {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), 1);
  }

  static getFormatMMSS(duration) {
    var min = Math.floor(duration / 60);
    var second = duration - min * 60;
    var timeStr = min ? min + "分" : "";
    timeStr += second ? second + "秒" : "";
    return timeStr;
  }

  static isTimeBetween(startTime, endTime, time, slack) {
    let start = moment(startTime);
    let end = moment(endTime);
    let t = moment(time);
    if (slack) {
      start = start.subtract(slack, "minutes");
      end = end.add(slack, "minutes");
    }
    return t.isBetween(start, end);
  }
}
