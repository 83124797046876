import { Http } from "@/util";

// 查询日历信息
export function getCalendarInfo(date) {
  return Http.post("/admin/driver/leave/calendar_info", { date });
}

// 设置日历信息
export function setCalendarInfo(param) {
  return Http.post("/admin/driver/leave/calendar_info/set", param);
}

// 设置日历信息
export function autoSetHoliday(year) {
  return Http.get("/admin/driver/leave/auto_set_holiday", { year });
}
