<template>
  <div class="root-box">
    <el-tabs v-model="tab" @tab-click="query">
      <el-tab-pane label="未接" name="ref1">
        <span slot="label">
          <el-badge v-if="backupNum" :value="backupNum" class="item"><span>未接</span></el-badge>
          <span v-else>未接</span>
        </span>

        <div class="box">
          <waiting-order ref="ref1" :sendSms="sendSms" :dispatchOrder="openDriverModal"
            :showTransactionDetail="showTransactionDetail"></waiting-order>
        </div>
      </el-tab-pane>
      <el-tab-pane label="待支付" name="ref4">
        <div class="box">
          <to-pay-order ref="ref4" :recallOrder="recallOrder" :sendSms="sendSms" :dispatchOrder="openDriverModal"
            :showTransactionDetail="showTransactionDetail" :auditBaggage="aduitBag"></to-pay-order>
        </div>
      </el-tab-pane>

      <el-tab-pane label="已订" name="ref2">
        <div class="box">
          <active-order ref="ref2" :recallOrder="recallOrder" :sendSms="sendSms" :dispatchOrder="openDriverModal"
            :showTransactionDetail="showTransactionDetail" :auditBaggage="aduitBag"></active-order>
        </div>
      </el-tab-pane>

      <el-tab-pane label="已派" name="ref3">
        <div class="box">
          <dispatched-order ref="ref3" :sendSms="sendSms" :recallOrder="recallOrder"
            :showTransactionDetail="showTransactionDetail" :auditBaggage="aduitBag"
            :changeRouteDriver="openChangeDriverModal"></dispatched-order>
        </div>
      </el-tab-pane>
    </el-tabs>

    <span class="tel-btn">
      <el-button type="warning" style="margin-right: 30px" size="small" plain @click="showTaskList">派单记录</el-button>
      <el-button type="info" style="margin-right: 30px" size="small" plain @click="createPunishBill">开罚单</el-button>
      <el-button type="info" style="margin-right: 30px" size="small" plain @click="showDispatchLog">系统派单日志</el-button>


      <span style="margin-right: 30px">
        <el-badge :value="todoList.length" class="item">
          <el-button type="info" size="small" plain @click="showHandoverLog">交接班日志 / 待办</el-button>
        </el-badge>
      </span>

      <tel-order title="电话下单" />
    </span>

    <div class="query">
      <div>
        <el-form size="small" :model="param" :inline="true">
          <el-form-item label="路线：">
            <el-select v-model="param.pathId" placeholder="请选择" @change="query">
              <el-option v-for="item in pathOpts" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="日期">
            <el-select v-model="param.date" placeholder="请选择" clearable @change="query">
              <el-option v-for="item in dateOpts" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="时间">
            <el-time-select @change="query" v-model="param.startTime" style="width: 150px" :picker-options="timeOpts"
              placeholder="开始时间">
            </el-time-select>

            &nbsp;&nbsp;&nbsp;

            <el-time-select @change="query" v-model="param.endTime" style="width: 150px" :picker-options="timeOpts"
              placeholder="结束时间">
            </el-time-select>
          </el-form-item>

          <el-form-item label="电话" prop="telephone">
            <el-input v-model="param.telephone" clearable style="width: 150px"></el-input>
          </el-form-item>

          <el-form-item label="所有日期" prop="date">
            <el-date-picker v-model="param.date" type="date" style="width: 180px" format="yyyy-MM-dd"
              value-format="yyyy-MM-dd" placeholder="日期">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-button type="primary" :size="size" style="width: 100px; margin-right: 20px" @click="query">查询</el-button>

        <el-button-group v-if="tab === 'ref2'">
          <el-button type="warning" style="width: 80px" :size="size" @click="autoDispatch">系统派单</el-button>
          <el-button v-if="tab === 'ref2'" type="warning" :size="size" style="width: 60px"
            @click="showDispatchMap">地图</el-button>
        </el-button-group>
      </div>
    </div>

    <el-dialog :visible.sync="driverDiagVisible" width="700px" max-height="600" title="分派司机"
      :before-close="closeDriverDiag" :close-on-click-modal="false">
      <el-form>
        <el-form-item label="指定司机：">
          <el-select style="width: 400px" :size="size" v-model="dispatchForm.driverId" filterable remote clearable
            placeholder="请输入关键词" :remote-method="getDriver" :loading="loading" @change="queryRoute">
            <el-option v-for="item in driverOptions" :key="item.id" :label="item.driverName" :value="item.driverId">
              <div style="display: flex; justify-content: space-between">
                <span style="flex: 1">{{ item.driverName }}</span>
                <span style="flex: 1">{{ item.driverNo }}</span>
                <span style="flex: 1">{{ item.seats }}坐</span>
                <span style="flex: 1">{{ item.vehicleBrand }} {{ item.bigCar ? "(大车)" : "" }}</span>

              </div>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <el-table v-loading="routeLoading" :data="routeList" stripe max-height="400" @current-change="handleSelect"
        highlight-current-row ref="routeTable" v-if="routeList.length">
        <el-table-column label="序号" type="index" show-overflow-tooltip width="50"></el-table-column>

        <el-table-column label="路线" prop="pathId">
          <template slot-scope="scope">
            <span>{{ scope.row.pathId | enumTransfer(pathOpts, "id", "name") }}</span>
          </template>
        </el-table-column>
        <el-table-column label="日期" prop="date"></el-table-column>
        <el-table-column label="时间" prop="showTime"></el-table-column>
        <el-table-column label="剩余座位数" prop="id">
          <template slot-scope="scope">
            <span class="red">{{ scope.row.number - scope.row.used }}</span>
          </template>
        </el-table-column>
      </el-table>

      <span slot="footer">
        <el-checkbox v-model="ignoreAskLeave" style="margin-right: 6px">忽略异常</el-checkbox>

        <el-button :disabled="!selectRoute" type="warning" :size="size" @click="cancelSelectDriver">取消选择</el-button>
        <el-button type="warning" :size="size" @click="dispatchDriver">确认</el-button>
        <el-button type="info" :size="size" @click="closeDriverDiag">取消</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="baggageVisible" width="1000px" max-height="600" title="行李审核"
      :close-on-click-modal="false">
      <div class="img-box">
        <div class="container">
          <div class="tip">
            <div class="item">
              1、确保照片内容无涉政、反动、暴力、恐怖、血腥、色情、赌博、传销、诈骗、谣言等违法内容，如有以上内容一律删除行李照片。
            </div>
            <div class="item">2、确保照片内容无个人联系方式</div>
            <div class="item">3、行李照片不清晰、照片不全，无关行李的照片不用删除，可直接派单</div>
            <div class="item">4、如有疑问的内容，请主管决定后再行派单</div>
            <div class="item">
              5、请保护照片内容的隐私性，仅限工作审核之用，不得私自翻拍、转发、存储
            </div>
            <div class="item">6、如有漏掉违规照片或侵犯照片隐私，审核客服需承担责任</div>
          </div>

          <div class="box">
            <div v-if="currentOrder" class="text">
              {{ currentOrder.baggageRemark | baggagesRemarkFilter }}
            </div>

            <div class="imgs">
              <div class="mbox" v-for="item in bagImgs" :key="item">
                <el-image class="bag" :src="item" :preview-src-list="bagImgs">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>

                <el-button v-if="!viewMode" type="danger" class="btn" style="width: 120px" size="small"
                  @click="delBagImgs(item)">
                  删除
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button :size="size" @click="baggageVisible = false" style="width: 120px">{{
          viewMode ? "关闭" : "取 消"
        }}</el-button>
        <el-button v-if="!viewMode" :size="size" type="primary" style="width: 160px"
          @click="finishAuditBags">审核通过</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="logVisible" width="1000px" max-height="600" title="派单日志" :close-on-click-modal="false">
      <div class="img-box">
        <div class="container">
          <el-table :data="logs" stripe max-height="500">
            <el-table-column label="序号" type="index" show-overflow-tooltip></el-table-column>

            <el-table-column label="乘客电话" prop="telephone"></el-table-column>

            <el-table-column label="路线" prop="pathId">
              <template slot-scope="scope">
                <span>{{ scope.row.pathId | enumTransfer(pathOpts, "id", "name") }}</span>
              </template>
            </el-table-column>
            <el-table-column label="时间" prop="showTime"></el-table-column>

            <el-table-column label="司机编号" prop="driverNo"></el-table-column>

            <el-table-column label="分派时间" prop="createTime"></el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button :size="size" @click="logVisible = false" style="width: 120px">关闭</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="handoverLogVisible" width="1200px" max-height="600" :close-on-click-modal="false"
      custom-class="handover-modal" :show-close="false">
      <slot name="title">

        <el-tabs v-model="activeName" @tab-click="handleTabClick">
          <el-tab-pane label="交接班日志" name="first"></el-tab-pane>
          <el-tab-pane label="待办事项" name="second">
            <span slot="label"> <el-badge style="line-height: 26px" :value="todoList.length">待办事项</el-badge></span>
          </el-tab-pane>
        </el-tabs>
      </slot>
      <div class="handover-box">
        <div v-if="activeName === 'second'">
          <el-table :data="todoList" stripe max-height="500" size="small">
            <el-table-column label="序号" type="index" show-overflow-tooltip></el-table-column>

            <el-table-column label="日期" prop="date"></el-table-column>

            <el-table-column label="路线" prop="pathId">
              <template slot-scope="scope">
                <span>{{ scope.row.pathId | enumTransfer(pathOpts, "id", "name") }}</span>
              </template>
            </el-table-column>
            <el-table-column label="时间" prop="showTime"></el-table-column>
            <el-table-column label="订单" prop="orderIndex"></el-table-column>
            <el-table-column label="乘客手机号" prop="passengerTel"></el-table-column>

            <el-table-column label="待处理事项" prop="type"></el-table-column>

            <el-table-column label="司机" prop="driverNo"></el-table-column>

            <el-table-column label="备注" prop="remark"></el-table-column>

            <el-table-column label="处理人" prop="dealUser"></el-table-column>

            <el-table-column label="处理结果" prop="result"></el-table-column>

            <el-table-column prop="id" label="操作" align="right" fixed="right" width="120">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="dealTodoList(scope.row, 1)">备注</el-button>
                <el-button type="text" size="small" @click="dealTodoList(scope.row, 2)">处理</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>


        <div v-else>
          <div class="header">
            <div>
              <el-date-picker value-format="yyyy-MM-dd" v-model="handoverlogs.logDate" style="width: 200px; "
                type="date" size="small" @change="showHandoverLog" placeholder="选择日期"></el-date-picker>
            </div>
            <div>{{ currentDate }} {{ adminName }}</div>
          </div>
          <div class="handover-container">

            <div class="notice">
              <div class="center-title">注意事项</div>
              <div class="item"><span>【1】</span> 截止次日9-10点前所有路线未分配订单已分配完毕</div>
              <div class="item"><span>【2】</span>截止次日8-9点前岳阳路线的票全部关闭</div>
              <div class="item">
                <span>【3】</span>所有交接事项已经写全、写清楚，无遗漏或表述不清的地方
              </div>
              <div class="item"><span>【4】</span>确认使用的是本人的账号</div>
            </div>
            <div class="content">
              <div class="item">
                <span class="title">武汉：</span>
                <el-input type="textarea" :rows="8" :disabled="logDisable" v-model="handoverlogs.wuhan"></el-input>
              </div>

              <div class="item">
                <span class="title">荆州：</span>
                <el-input type="textarea" :rows="6" :disabled="logDisable" v-model="handoverlogs.jingzhou"></el-input>
              </div>

              <div class="item">
                <span class="title">岳阳：</span>
                <el-input type="textarea" :rows="4" :disabled="logDisable" v-model="handoverlogs.yueyang"></el-input>
              </div>

              <div class="item">
                <span class="title">其他</span>
                <el-input type="textarea" :rows="4" :disabled="logDisable" v-model="handoverlogs.remark"></el-input>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div slot="footer" class="dialog-footer">
        <div v-if="activeName === 'second'">
          <el-button :size="size" @click="handoverLogVisible = false" style="width: 120px">关闭</el-button>
        </div>
        <div v-else>
          <el-button :size="size" type="warning" @click="saveHandoverLogOk" v-if="!logDisable" plain
            style="width: 120px">保存</el-button>

          <span style="margin-right: 30px" v-if="handoverlogs.adminName && logDisable">交接人：{{ handoverlogs.adminName }}
            &nbsp;&nbsp; 交接时间：{{
              handoverlogs.createTime
            }}</span>

          <el-button :size="size" @click="handoverLogVisible = false" style="width: 120px">关闭</el-button>
        </div>

      </div>
    </el-dialog>

    <el-dialog :visible.sync="recallDiagVisible" width="480px" max-height="400" :close-on-click-modal="false"
      custom-class="handover-modal" :show-close="false">
      <div>
        <div style="font-size: 20px; margin: 10px 0">提示</div>
        <div style="margin: 20px 0 0; display: flex; align-items: center">
          <i class="el-icon-warning" style="color: orange; font-size: 30px; margin-right: 16px"></i>
          <span>确定撤回订单吗？</span>
          <el-checkbox style="margin-left: 20px" v-model="tipDriver">提示司机</el-checkbox>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button :size="size" type="warning" @click="confirmRecallOrder" plain>确定</el-button>
        <el-button :size="size" @click="recallDiagVisible = false">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="scheduleVisible" width="1200px" max-height="600" :close-on-click-modal="false"
      :show-close="false">
      <div>
        <el-table :data="scheduleList" stripe max-height="550" ref="scheduleTable" :row-class-name="tableRowClassName"
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column label="序号" type="index" show-overflow-tooltip width="50"></el-table-column>

          <el-table-column prop="driverNo" label="司机编号" width="130">
            <template slot-scope="scope">
              {{ scope.row.driverNo }} - {{ scope.row.driverName }}
            </template>
          </el-table-column>
          <el-table-column prop="seats" label="座位数" width="80"> </el-table-column>

          <el-table-column prop="status" label="状态" width="80">
            <template slot-scope="scope">
              <span>{{ scope.row.status | enumTransfer(schedulingStatus) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="arriveTime" label="最近到达时间" width="120">
            <template slot-scope="scope">
              {{ scope.row.arriveTime | dateFormat("MM-dd hh:mm") }}
            </template>
          </el-table-column>

          <el-table-column prop="seats" label="积分" width="80">
            <template slot-scope="scope"> {{ scope.row.monthRoutePoints }}分 </template>
          </el-table-column>

          <el-table-column prop="seats" label="已发积分" width="120">
            <template slot-scope="scope">
              {{ (scope.row.routePoints / 100).toFixed(1) }}分
            </template>
          </el-table-column>
          <el-table-column prop="seats" label="过夜及晚归" width="120">
            <template slot-scope="scope">
              <span :class="{
                red: scope.row.continuousNightDay > 2 || scope.row.nightDays > 9,
              }">{{ scope.row.continuousNightDay || 0 }}/{{ scope.row.nightDays || 0 }}过夜</span>-<span
                :class="{ red: scope.row.continuousBackTooLateDay > 2 }">{{ scope.row.continuousBackTooLateDay || 0
                }}晚归</span>
            </template>
          </el-table-column>

          <el-table-column prop="seats" label="休息" width="200">
            <template slot-scope="scope">
              <span v-if="scope.row.offTime">{{ scope.row.offTime | leaveFilter }} -
                {{ scope.row.backTime | backTimeFilter }}收车</span>
              &nbsp; {{ scope.row.leaveDays }}/{{ scope.row.maxLeaveDaysPerMonth }}休
            </template>
          </el-table-column>

          <el-table-column prop="nightArea" label="签约地" width="80"> </el-table-column>
        </el-table>
      </div>

      <div slot="footer" class="dialog-footer">
        <span style="font-size: 15px; margin-right: 700px">当前工分配
          <span class="red">{{ totalNums }}人/{{ selectedOrders.length }}单</span> ，已选择
          <span class="red">{{ totalSeats }}坐/{{ selectedSchedules.length }}车</span>
        </span>

        <!-- <i
          class="el-icon-s-tools"
          style="font-size: 14px; color: #999; margin-right: 12px; cursor: pointer"
          @click="openSetting"
          >高级设置</i
        > -->
        <el-button :size="size" type="warning" @click="openSetting()" plain>执行派单任务</el-button>
        <el-button :size="size" @click="scheduleVisible = false">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="settingVisible" width="500px" max-height="600" title="派单设置" :close-on-click-modal="false"
      :show-close="false">
      <el-form :size="size">
        <el-form-item label="考虑司机位置：">
          <el-switch v-model="settingForm.considerDriverPosition"> </el-switch>
        </el-form-item>

        <el-button type="text" @click="showSetting = !showSetting">高级设置：</el-button>
        <div v-if="showSetting" @click="showSetting = false">
          <el-form-item label="使用百度数据：">
            <el-switch v-model="settingForm.useBaidu"> </el-switch>
          </el-form-item>
          <el-form-item label="最大允许延迟：">
            <el-input-number v-model="settingForm.maxAllowDelay"></el-input-number>
          </el-form-item>
          <el-form-item label="前后时间宽限：">
            <el-input-number v-model="settingForm.timeWinSlack"></el-input-number>
          </el-form-item>
        </div>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" :size="size" @click="saveSetting">开始执行</el-button>
        <el-button :size="size" @click="settingVisible = false">关闭</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="taskVisible" width="1200px" max-height="600" :close-on-click-modal="false"
      :show-close="false">
      <div>
        <el-table :data="taskList" stripe max-height="550" ref="scheduleTable"
          @selection-change="handleSelectionChange">
          <el-table-column label="序号" type="index" show-overflow-tooltip width="50"></el-table-column>

          <el-table-column prop="pathId" label="路线" width="100">
            <template slot-scope="scope">
              {{ scope.row.pathId | enumTransfer(pathOpts, "id", "name") }}
            </template>
          </el-table-column>
          <el-table-column prop="showTime" label="派单时间段" width="120"> </el-table-column>
          <el-table-column prop="totalOrderNum" label="分派订单数" width="100">
            <template slot-scope="scope">
              {{ scope.row.totalOrderNum }}单 / {{ scope.row.totalPersonNum }}人
            </template>
          </el-table-column>
          <el-table-column prop="orders" label="分派车辆数" width="100">
            <template slot-scope="scope">
              {{ scope.row.totalSchedulingNum }}车 / {{ scope.row.totalSeatsNum }}座
            </template>
          </el-table-column>

          <el-table-column prop="useBaidu" label="数据源" width="100">
            <template slot-scope="scope">
              {{ scope.row.useBaidu ? "百度数据" : "本地数据" }}
            </template>
          </el-table-column>

          <el-table-column prop="status" label="状态" width="80">
            <template slot-scope="scope">
              {{ scope.row.status | enumTransfer(taskStatusOpts) }}
              <i v-if="scope.row.status === 0" class="el-icon-loading"></i>
            </template>
          </el-table-column>

          <el-table-column prop="ctime" label="创建时间" width="160"> </el-table-column>
          <el-table-column prop="operator" label="创建人 " width="100"> </el-table-column>

          <el-table-column prop="psgMessage" width="220" label="操作">
            <template slot-scope="scope">
              <el-button type="text" style="color: #cf9236" size="small"
                @click="openSetting(scope.row)">重新规划</el-button>

              <el-button type="text" size="small" @click="manualDispatch(scope.row)">人工派单</el-button>
              <el-button type="text" size="small" @click="showPlanDetail(scope.row)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" :size="size" @click="showTaskList">刷新</el-button>
        <el-button :size="size" @click="taskVisible = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import WaitingOrder from "./WaitingOrder";
import DispatchedOrder from "./DispatchedOrder";
import ActiveOrder from "./ActiveOrder";
import ToPayOrder from "./ToPayOrder";
import { ORDER_CHANGE, SHOW_AUTO_DISPATCH_MODAL } from "@/core/const/global-event";
import { mapState } from "vuex";
import { BaseDataService } from "@/service";
import { DateUtil, Toolkit, LocalStorage } from "@/util";
import {
  hasBackupTicket,
  recall,
  searchDriver,
  getDispatchRouteList,
  sendNoAnswerMsg,
  auditBaggage,
  deleteBaggage,
  getDispatchLog,
  getHandoverLog,
  saveHandoverLog,
  dispatchOrders,
  getSchedulingMap,
  execAutoDispatchTask,
  getDispatchTaskList,
  reExecTask,
  changeRouteDriver,
  getToDoList,
  updateToDoList
} from "@/api";
import { SHOW_PUNISH_DIAG } from "@/core/const/global-event";
import { USER_INFO } from "@/core/const/global-const";
import config from "@/config/config";

export default {
  filters: {
    baggagesRemarkFilter(val) {
      if (val) {
        return val.substr(1);
      }
      return val;
    },
    leaveFilter(offTime) {
      if (offTime) {
        return DateUtil.dateStrFormat(offTime, "MM-dd");
      }
      return "";
    },
    backTimeFilter(time) {
      if (time) {
        // 当前日期减1天
        return DateUtil.getSpecDayStr(time, -1, "MM-DD");
      }
      return "";
    }
  },
  components: {
    WaitingOrder,
    ActiveOrder,
    DispatchedOrder,
    ToPayOrder,
  },
  props: {},
  events: {
    [ORDER_CHANGE]() {
      this.query();
    },
  },
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      pathOpts: (state) => state.pathList,
      schedulingStatus: (state) => state.enumMap["SCHEDULING_STATUS"],
      totalNums() {
        return this.selectedOrders.map((item) => item.number).reduce((a, b) => a + b, 0);
      },
      totalSeats() {
        return this.selectedSchedules.map((item) => item.seats).reduce((a, b) => a + b, 0);
      },
    }),
    pathId() {
      // 做中间层监听pathId变化
      return this.param.pathId;
    },

    date() {
      // 做中间层监听date变化
      return this.param.date;
    },

    logDisable() {
      return DateUtil.getDayStr(0) !== this.handoverlogs.logDate;
    },
  },
  watch: {
    pathId() {
      this.$set(this.param, "time", null);
    },
  },
  mounted() {
    this.query();
    const userinfo = LocalStorage.get(USER_INFO) || {};
    this.adminName = userinfo.name;
    this.getTaskList();
  },
  data() {
    return {
      taskStatusOpts: [
        { value: 0, label: "规划中" },
        { value: 1, label: "规划成功" },
        { value: 2, label: "规划失败" },
      ],

      showSetting: false,
      settingForm: {},
      param: {
        pathId: config.initPath,
      },
      dateOpts: BaseDataService.date(true),
      tab: "ref1",
      settingVisible: false,
      driverDiagVisible: false,
      recallDiagVisible: false,
      loading: false,
      routeList: [],
      selectRoute: null,
      dispatchForm: {},
      driverOptions: [],
      timeOpts: {
        start: "00:00",
        step: "00:60",
        end: "23:00",
      },
      baggageVisible: false,
      viewMode: true,
      currentOrder: null,
      bagImgs: [],
      logVisible: false,
      handoverLogVisible: false,
      logs: [],
      handoverlogs: {
        logDate: DateUtil.getDayStr(0),
      },
      currentDate: DateUtil.getDayStr(0),
      adminName: "",
      searchKey: "",
      ignoreAskLeave: false,
      backupNum: 0,
      tipDriver: true,
      scheduleVisible: false,
      scheduleList: [],
      selectedOrders: [],
      selectedSchedules: [],
      taskList: [],
      taskVisible: false,
      useBaidu: true,
      timeWinSlack: 0,
      considerDriverPosition: false,
      maxAllowDelay: 40,
      todoList: [],
      activeName: "first",
    };
  },
  methods: {
    query() {
      let param = { ...this.param };
      if (param.startTime) {
        param.startTime = `${param.startTime}:00`;
      }
      if (param.endTime) {
        param.endTime = `${param.endTime}:00`;
      }

      param = Toolkit.filterEmpty(param);
      this.$refs[this.tab].query(1, param);

      // 查询候补票
      hasBackupTicket(param).then((res) => {
        this.backupNum = res.data;
      });

      this.queryToDoList();
    },

    queryToDoList() {
      getToDoList().then((res) => {
        this.todoList = res.data;
      });
    },


    dealTodoList(item, type) {
      if (type === 1) {
        this.$prompt("请输入备注", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then(({ value }) => {
          const param = {
            ...item,
            remark: value
          };
          updateToDoList(param).then(() => {
            this.$message.success('操作成功');
            this.queryToDoList();
          });
        });
      } else {
        this.$prompt("请输入处理结果", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(({ value }) => {
          const param = {
            ...item,
            result: value,
            status: 'DONE'
          };
          updateToDoList(param).then(() => {
            this.$message.success('操作成功');
            this.queryToDoList();
          });
        });
      }
    },

    sendSms(orderId) {
      sendNoAnswerMsg(orderId).then(() => {
        this.$message.success("短信发送成功");
      });
    },

    showDispatchMap() {
      this.$refs[this.tab].showDispatchMap();
    },

    recallOrder(item) {
      this.selectOrder = item;
      this.tipDriver = true;
      this.recallDiagVisible = true;
    },

    confirmRecallOrder() {
      recall(this.selectOrder.id, this.tipDriver).then(() => {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        this.recallDiagVisible = false;
        this.query();
      });
    },

    handleSelect(item) {
      this.selectRoute = item;
    },

    cancelSelectDriver(e) {
      this.$refs.routeTable.setCurrentRow(e);
      this.selectRoute = null;
    },

    openDriverModal(selectOrderIds) {
      if (!selectOrderIds.length) {
        this.$message.error("请选择要分派的订单");
        return;
      }
      this.changeDriver = false;
      this.selectRoute = null;
      this.selectOrderIds = selectOrderIds;
      this.driverDiagVisible = true;
      this.dispatchForm = {
        driverId: null,
      };
    },

    openChangeDriverModal(route) {
      this.changeDriver = true;
      this.selectRoute = route;
      this.selectOrderIds = null;
      this.driverDiagVisible = true;
      this.dispatchForm = {
        driverId: null,
      };
    },

    closeDriverDiag(e) {
      this.driverDiagVisible = false;
      if (this.$refs.routeTable) {
        this.$refs.routeTable.setCurrentRow(e);
      }

      this.routeList = [];
      this.selectRoute = null;
      this.ignoreAskLeave = false;
    },

    getDriver(key) {
      if (key && key.length > 0) {
        const param = { searchKey: key, pathId: this.pathId };
        searchDriver(param).then((res) => {
          this.driverOptions = res.data || [];
        });
      }
    },

    queryRoute(val) {
      if (val && !this.changeDriver) {
        this.routeLoading = true;
        const param = {
          driverId: val,
          pathId: this.pathId,
        };
        getDispatchRouteList(param).then((res) => {
          this.routeList = res.data || [];
          this.routeLoading = false;
        });
      } else {
        this.routeList = [];
      }
    },

    dispatchDriver() {
      if (this.dispatchForm.driverId) {
        if (this.selectOrderIds && this.selectOrderIds.length) {
          const orderIds = this.selectOrderIds;
          const routeId = this.selectRoute ? this.selectRoute.id : null;
          const param = {
            orderIds,
            routeId,
            ignoreAskLeave: this.ignoreAskLeave,
            ...this.dispatchForm,
          };

          dispatchOrders(param).then(() => {
            this.closeDriverDiag();
            this.$message.success("指派司机成功");
            this.query();
          });
        } else {
          if (!this.selectRoute) {
            this.$message.error("没有选择要改派的行程");
            return;
          }

          changeRouteDriver(this.selectRoute.id, this.dispatchForm.driverId).then(() => {
            this.closeDriverDiag();
            this.$message.success("指派司机成功");
            this.query();
          });
        }
      } else {
        this.$message.error("请选择分派司机");
      }
    },

    handleTabClick(tab) {
      this.activeName = tab.name;
    },

    delBagImgs(item) {
      const order = this.currentOrder;
      let type = 0;
      if (order.baggageImg1 === item) {
        type = 1;
      } else if (order.baggageImg2 === item) {
        type = 2;
      } else if (order.baggageImg3 === item) {
        type = 3;
      }

      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteBaggage(order.id, type).then(() => {
          const index = this.bagImgs.indexOf(item);
          this.bagImgs.splice(index, 1);
        });
      });
    },

    finishAuditBags() {
      this.$confirm("确认审核完成?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        auditBaggage({ id: this.currentOrder.id }).then(() => {
          this.$message.success("操作成功");
          this.baggageVisible = false;
          const item = this.currentOrder;
          item.baggageAudit = 2;
          const index = this.list.findIndex((t) => t.id === item.id);
          this.$set(this.list, index, item);
        });
      });
    },

    aduitBag(item, viewMode) {
      this.currentOrder = item;
      const imgs = [];
      if (item.baggageImg1) {
        imgs.push(item.baggageImg1);
      }
      if (item.baggageImg2) {
        imgs.push(item.baggageImg2);
      }
      if (item.baggageImg3) {
        imgs.push(item.baggageImg3);
      }
      this.bagImgs = imgs;
      this.baggageVisible = true;
      this.viewMode = viewMode;
    },

    showDispatchLog() {
      const param = {
        pathId: this.param.pathId,
        createTime: DateUtil.getDayStr(-1, "yyyy-MM-dd 12:00:00"),
      };

      this.logVisible = true;
      getDispatchLog(param).then((res) => {
        this.logs = res.data || [];
      });
    },

    showHandoverLog() {
      this.handoverLogVisible = true;
      getHandoverLog(this.handoverlogs.logDate).then((res) => {
        if (res.data) {
          this.handoverlogs = res.data;
        } else {
          this.handoverlogs = { logDate: this.handoverlogs.logDate };
        }
      });
    },

    saveHandoverLogOk() {
      saveHandoverLog(this.handoverlogs).then(() => {
        this.handoverLogVisible = false;
        this.$message.success("日志保存成功");
      });
    },

    showTransactionDetail(item) {
      this.$orderTransaction().show(item.id);
    },

    createPunishBill() {
      this.$bus.$emit(SHOW_PUNISH_DIAG);
    },

    autoDispatch() {
      this.selectedOrders = this.$refs.ref2.getSelectedOrders();
      this.scheduleVisible = true;
      const routePath = this.pathOpts.filter((t) => t.id === this.pathId)[0];
      getSchedulingMap().then((res) => {
        this.scheduleList = res.data[routePath.startArea];
      });
    },

    handleSelectionChange(val) {
      this.selectedSchedules = val;
    },

    startAutoDispatch() {
      if (this.selectedOrders.length === 0) {
        this.$message.error("请选择要分派的订单");
        return;
      }

      if (this.selectedSchedules.length === 0) {
        this.$message.error("请选择要分派的班次");
        return;
      }

      const param = {
        orderIds: this.selectedOrders.map((t) => t.id),
        schedulingIds: this.selectedSchedules.map((t) => t.id),
        pathId: this.pathId,
        maxAllowDelay: this.maxAllowDelay,
        timeWinSlack: this.timeWinSlack,
        useBaidu: this.useBaidu,
        considerDriverPosition: this.considerDriverPosition,
      };
      execAutoDispatchTask(param)
        .then((res) => {
          if (res.data && res.data.errMsg) {
            this.$message.error(res.data.errMsg);
          } else {
            this.$message.success("自动分派任务已启动");
            this.scheduleVisible = false;
            this.getTaskList();
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },

    getTaskList() {
      const toady = this.param.date ? this.param.date : DateUtil.getDayStr(0, "yyyy-MM-dd");
      getDispatchTaskList(toady).then((res) => {
        const list = res.data;
        this.taskList = list.filter((t) => t.pathId === this.pathId);
      });
    },

    showTaskList() {
      this.taskVisible = true;
      this.taskList = [];
      this.getTaskList();
    },

    showPlanDetail(task) {
      if (task.autoDispatchResult) {
        this.$bus.$emit(SHOW_AUTO_DISPATCH_MODAL, task);
      } else {
        this.$message.error("该任务还未计算完成");
      }
    },

    rePlan(item) {
      const param = {
        taskId: item.id,
        pathId: this.pathId,
        maxAllowDelay: this.maxAllowDelay,
        considerDriverPosition: this.considerDriverPosition,
        useBaidu: this.useBaidu,
        timeWinSlack: this.timeWinSlack,
      };
      reExecTask(param)
        .then((res) => {
          if (res.data && res.data.errMsg) {
            this.$message.error(res.data.errMsg);
          } else {
            this.$message.success("自动分派任务已启动");
            this.scheduleVisible = false;
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },

    saveSetting() {
      this.useBaidu = this.settingForm.useBaidu;
      this.maxAllowDelay = this.settingForm.maxAllowDelay;
      this.considerDriverPosition = this.settingForm.considerDriverPosition;
      this.timeWinSlack = this.settingForm.timeWinSlack;

      if (this.reTask) {
        this.rePlan(this.reTask);
      } else {
        this.startAutoDispatch();
      }

      this.settingVisible = false;
    },

    openSetting(task) {
      this.reTask = task;

      this.settingForm = {
        useBaidu: this.useBaidu,
        maxAllowDelay: this.maxAllowDelay,
        considerDriverPosition: this.considerDriverPosition,
        timeWinSlack: this.timeWinSlack,
      };
      this.settingVisible = true;
    },

    manualDispatch(task) {
      const { schedulingList = [], orderList = [] } = task;

      const dispatchedScheduleList = [];
      const unDispatchedOrderList = [];
      schedulingList.forEach((t) => {
        dispatchedScheduleList.push({
          scheduling: { ...t, leftSeats: t.seats },
          pickSeq: [],
          sendSeq: [],
        });
      });

      orderList.forEach((t) => {
        unDispatchedOrderList.push({
          ...t,
          standardDuration: t.standardDuration,
        });
      });

      task.autoDispatchResult = { dispatchedScheduleList, unDispatchedOrderList };
      this.$bus.$emit(SHOW_AUTO_DISPATCH_MODAL, task);
    },

    tableRowClassName({ row }) {
      const { status } = row;
      // 请假
      if (status === 5) {
        return "warn";
      }
      return "";
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-table {
  .warn {
    color: #ff9798;

    &:hover td {
      color: #ff9798;
    }

    &.el-table__row--striped td {
      color: #ff9798;
    }
  }
}

.el-badge.item {
  line-height: 24px;
}

.root-box {
  position: relative;
}

.tel-btn {
  position: absolute;
  right: 20px;
  top: 10px;
  display: flex;
}

.box {
  margin-top: 50px;
}

.query {
  height: 50px;
  right: 20px;
  position: absolute;
  top: 70px;
  left: 20px;
  display: flex;
  justify-content: space-between;
}

.img-box {
  display: flex;

  .container {
    display: flex;
    width: 100%;

    .tip {
      width: 400px;

      .item {
        margin-bottom: 8px;
      }

      margin-right: 80px;
    }

    .box {
      display: flex;
      flex-direction: column;

      .text {
        font-size: 14px;
        margin-bottom: 20px;
      }

      .imgs {
        display: flex;

        .mbox {
          margin-right: 30px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .bag {
            width: 120px;
            height: 140px;
          }

          .btn {
            margin-top: 20px;
          }
        }
      }
    }
  }
}

/deep/ .handover-modal {
  margin-top: 10vh !important;

  .el-dialog__body {
    padding: 20px;
  }

  .el-dialog__header {
    padding: 0;
  }

  .header {
    display: flex;
    font-size: 16px;
    padding: 15px 0;
    align-items: center;
    font-weight: bold;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      align-items: center;
    }
  }
}

.handover-box {
  .handover-container {
    display: flex;

    .notice {
      width: 240px;
      border: 1px solid #ddd;
      padding: 10px 6px;
      display: flex;
      flex-direction: column;
      padding-top: 30px;

      >div {
        margin: 20px 0;
      }

      .center-title {
        width: 100%;
        text-align: center;
      }

      .item {
        display: flex;

        span {
          width: 40px;
        }
      }
    }

    .content {
      border: 1px solid #ddd;
      border-left: none;
      flex: 1;

      .item {
        display: flex;
        margin: 10px 0;
        align-items: center;

        .title {
          margin-left: 30px;
          width: 50px;
        }
      }
    }
  }
}
</style>
