import { DateUtil, ParamUtil, Http, SessionStorage } from "@/util";
import moment from "moment";
// export const videoServerIp = '47.100.10.103';
// const host = `http://${videoServerIp}:8088/`;
// const account = 'car123';
// const password = '000000';

// 登录超时时间
const loginTimeout = 15 * 60 * 1000 * 1000;
const loginTokenKey = "loginToken";

export const videoServerIp = "monitor.youxuanshunfengche.com"; //'47.100.10.103';
const host = `https://${videoServerIp}`; // `http://${videoServerIp}:8088/`;
const account = "youxuan";
const password = "000000";

async function getLoginToken() {
  const token = SessionStorage.get(loginTokenKey);

  const now = new Date().getTime();

  if (token && now < token.expiry) {
    return token.value;
  }

  const data = await videoMonitorLogin();

  SessionStorage.set(loginTokenKey, {
    value: data.jsession,
    expiry: now + loginTimeout,
  });

  return data.jsession;
}

// 监控平台登录
export function videoMonitorLogin() {
  const param = {
    account,
    password,
  };
  return getPromiseResult("StandardApiAction_login.action", param);
}

// 监控平台登出
export function videoMonitorLogout(jsession) {
  const param = {
    jsession,
  };
  return getPromiseResult("StandardApiAction_logout.action", param);
}

// 获取设备流量信息
export function getFlowInfo(jsession, devIdno) {
  const param = {
    jsession,
    devIdno,
  };
  return getPromiseResult("StandardApiAction_getFlowInfo.action", param);
}

// 打开实时视频页面
export function openLiveWindow(devIdno) {
  var param = {
    account,
    password,
    devIdno,
    channel: 4,
    stream: 1, //0主码流，1子码流
  };
  var paramStr = ParamUtil.obj2Str(param);
  var url = `${host}/808gps/open/player/video.html?${paramStr}`;
  window.open(url, "_blank");
}

// 获取实时位置
export function getRealTimeLocation(jsession, devIdno) {
  const param = {
    jsession,
    devIdno,
    geoaddress: 1,
    // toMap : 2, // 百度坐标，1位谷歌，谷歌没有详细地址
  };
  return getPromiseResult("StandardApiAction_getDeviceStatus.action", param);
}

// 设备是否在线
export function isDeviceOnline(jsession, devIdno) {
  const param = {
    jsession,
    devIdno,
  };
  return getPromiseResult("StandardApiAction_getDeviceOlStatus.action", param);
}

// 实时录像
export function recordVideo(jsession, devIdno, chn, label) {
  const param = {
    jsession,
    DevIDNO: devIdno,
    Chn: chn,
    Sec: 100,
    Label: label,
  };
  return getPromiseResult("StandardApiAction_realTimeVedio.action", param);
}

// 查询录像
export function getHisVideo(jsession, devIdno, chn, startTime, endTime) {
  const stime = moment(startTime);
  const etime = moment(endTime);
  const begSec = stime.hour() * 3600 + stime.minute() * 60 + stime.second();
  const endSec = etime.hour() * 3600 + etime.minute() * 60 + etime.second();
  const param = {
    jsession,
    DevIDNO: devIdno,
    LOC: 1,
    CHN: chn,
    YEAR: stime.year(),
    MON: stime.month() + 1,
    DAY: stime.date(),
    RECTYPE: -1, // 0表示常规，1表示报警，-1表示所有。
    FILEATTR: 2, // 1表示图像  2表示录像
    BEG: begSec,
    END: endSec,
    ARM1: 0,
    ARM2: 0,
    RES: 0,
    STREAM: 0,
    STORE: 0,
    // LABEL:0,
  };
  return getPromiseResult("StandardApiAction_getVideoFileInfo.action", param);
}

export function getDownloadTaskList(jsession, devIdno, startTime, endTime, taskTag) {
  const param = {
    jsession,
    devIdno,
    begintime: startTime,
    endtime: endTime,
    taskTag,
  };

  return getPromiseResult("StandardApiAction_downloadTasklist.action", param);
}

// 录像下载
export function videoDownload(jsession, devIdno, fph, chn, len, startTime, endTime) {
  const param = {
    jsession,
    did: devIdno,
    fbtm: startTime,
    fetm: endTime,
    sbtm: startTime,
    setm: endTime,
    lab: "",
    fph,
    vtp: 0, //0表示常规，1表示报警。
    chn,
    len,
    dtp: 1, //1表示全文件下载，2表示分段下载
  };
  return getPromiseResult("StandardApiAction_addDownloadTask.action", param);
}

// 录像下载
export function getAlarmRecords(jsession, devIdno, startTime, endTime) {
  const param = {
    jsession,
    vehiIdno: devIdno,
    begintime: startTime,
    endtime: endTime,
    currentPage: 1,
    pageRecords: 50,
    alarmType: "174,175",
  };
  return getPromiseResult("StandardApiAction_performanceReportPhotoListSafe.action", param);
}

// 抓拍
export function capturePicture(jsession, devIdno, chn) {
  const param = {
    jsession,
    DevIDNO: devIdno,
    Chn: chn,
    Type: 1,
  };
  return getPromiseResult("StandardApiAction_capturePicture.action", param);
}

// 获取抓拍图片路径
export function getCapturePicture(jsession, flength, foffset, fpath, name) {
  const param = {
    jsession,
    FLENGTH: flength,
    FOFFSET: foffset,
    FPATH: fpath,
    SAVENAME: name,
    Type: 3,
    MTYPE: 1,
  };
  return host + ":16611/3/5?" + ParamUtil.obj2Str(param);
}

export function addDownloadTask(url) {
  return new Promise((resolve, reject) => {
    $.ajax({
      url,
      cache: false,
      dataType: "json",
      success: resolve,
      error: (XMLHttpRequest, textStatus, errorThrown) => {
        reject(errorThrown);
      },
    });
  });
}

// tts语音播报
export async function ttsBroadcast(DevIDNO, Text) {
  const jsession = await getLoginToken();
  const param = {
    jsession,
    DevIDNO,
    Text,
  };
  return getPromiseResult("StandardApiAction_vehicleTTS.action", param);
}

function getPromiseResult(action, param) {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: host + "/" + action,
      cache: false,
      data: param,
      dataType: "json",
      success: resolve,
      error: (XMLHttpRequest, textStatus, errorThrown) => {
        reject(errorThrown);
      },
    });
  });
}

//////////////////////////////////////  伊爱API   ///////////////////////////////////////////////////

const deviceid = "10038860309";

function getYiaiToken(time, api) {
  const account = "wuhanyouxuan";
  const pwd = "12345678";
  const key = "kjkl5jswefg6#231%";
  const keyStr = `${account}${time}${pwd}${key}${api}`;
  return MD5(keyStr);
}

export function getYiaiApi(api, param) {
  return new Promise((resolve, reject) => {
    const time = parseInt(new Date().getTime() / 1000);
    $.ajax({
      url: "http://carapi.yiai168.net:10391/api/carGet/" + api,
      headers: {
        token: getYiaiToken(time, api),
      },
      method: "POST",
      data: {
        user: "wuhanyouxuan",
        time,
        ...param,
      },
      success: resolve,
      error: (XMLHttpRequest, textStatus, errorThrown) => {
        reject(errorThrown);
      },
    });
  });
}

// 获取车辆设备实时定位数据
export function getGpsInfo() {
  const parma = {
    deviceid,
  };
  return getYiaiApi("getDeviceByGps", parma);
}

// 获取车辆设备号
export function getDeviceByVehicle() {
  const parma = {
    plate: "湘FD08269",
  };
  return getYiaiApi("getDeviceByVehicle", parma);
}

// 获取用户车辆信息
export function queryUserVehicle() {
  return getYiaiApi("queryUserVehicle", {});
}

// 主动安全报警查询
export function queryAlarmDetail(sttime, edtime) {
  const parma = {
    deviceid,
    armType: "101",
    armSubType: "1,2,3,4,5,6",
    currentPage: 1,
    pageRecords: 50,
    // 10位时间戳
    sttime: parseInt(DateUtil.str2Timestamp(sttime) / 1000),
    edtime: parseInt(DateUtil.str2Timestamp(edtime) / 1000),
  };
  return getYiaiApi("queryAlarmDetail", parma);
}

// 主动安全报警附件查询
export function queryAlarmFileds(fileid) {
  const parma = {
    fileid,
  };
  return getYiaiApi("queryAlarmFileds", parma);
}

// 获取车辆设备流媒体拉流地址
export function queryDevicePlayerUrl(channel = "1,2,3") {
  const parma = {
    deviceid,
    channel,
  };
  return getYiaiApi("queryDevicePlayerUrl", parma);
}

// 停止播放设备流媒体拉流地址视频
export function stopDevicePlayerUrl(subid, channel = "1,2,3") {
  const parma = {
    deviceid,
    subid,
    channel,
  };
  return getYiaiApi("queryDevicePlayerUrl", parma);
}

// 获取历史录像数据
export function queryDevicePlayerHisUrlDown(sttime, edtime, channel = "2", bstype = "2") {
  const parma = {
    deviceid,
    channel,
    sttime: parseInt(DateUtil.str2Timestamp(sttime) / 1000),
    edtime: parseInt(DateUtil.str2Timestamp(edtime) / 1000),
    bstype,
  };
  return getYiaiApi("queryDevicePlayerHisUrlDown", parma);
}

export function listDownloadTask(param) {
  return Http.post(`/admin/video/task/list`, param);
}

export function addBackendDownloadTask(param) {
  return Http.post(`/admin/video/task/add`, param);
}

export function delBackendDownloadTask(id) {
  return Http.get(`/admin/video/task/delete`, { id });
}
