<template>
  <div class="root-box-detail">
    <el-form ref="form" :model="form" label-width="80px" :rules="rules" size="small" inline label-position="top"
      :disabled="readonly">
      <el-collapse class="driver-container" v-model="activeTab">
        <el-collapse-item title="车辆基本信息" name="1">
          <el-form-item label="车牌号" prop="vehicleNo">
            <el-input v-model="form.vehicleNo"></el-input>
          </el-form-item>

          <el-form-item label="汽车来源类型" prop="brand">
            <el-select v-model="form.originType" placeholder="请选择">
              <el-option v-for="item in originTypeOpts" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="车租" prop="carRental">
            <el-input-number style="width: 215px" v-model="form.carRental"></el-input-number>
          </el-form-item>

          <el-form-item label="车辆所属者" prop="ownerName">
            <el-input v-model="form.ownerName"></el-input>
          </el-form-item>

          <el-form-item label="行政区划代码" prop="addressCode">
            <el-select v-model="form.addressCode" placeholder="请选择" @change="handleAddressChange">
              <el-option v-for="item in addressCodeOpts" :key="item.value" :label="`${item.value}（${item.label}）`"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="核定载客位（含司机）" prop="seats">
            <el-select v-model="form.seats" placeholder="请选择">
              <el-option v-for="item in seatsOpts" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="级别类型" prop="vehicleType">
            <el-select v-model="form.vehicleType" placeholder="请选择">
              <el-option v-for="item in vehicleTypeOpts" :key="item.id" :label="item.content"
                :value="item.id"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="当前司机" prop="bindDriverName">
            <el-input v-model="form.bindDriverName"></el-input>
          </el-form-item>

          <el-form-item label="限制路线：" prop="limitPathIds">
            <el-select v-model="form.limitPathIds" class="spe" style="width: 560px" multiple>
              <el-option v-for="item in pathOpts" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-collapse-item>

        <el-collapse-item title="车辆详细信息" name="2">
          <el-form-item label="车辆厂牌（口头称呼）" prop="brand2">
            <el-input v-model="form.brand2"></el-input>
          </el-form-item>

          <el-form-item label="车辆厂牌" prop="brand">
            <el-input v-model="form.brand"></el-input>
          </el-form-item>

          <el-form-item label="车辆型号" prop="model">
            <el-input v-model="form.model"></el-input>
          </el-form-item>

          <el-form-item label="车身颜色" prop="vehicleColor">
            <el-select v-model="form.vehicleColor" placeholder="请选择">
              <el-option v-for="item in vehicleColorOpts" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="车牌颜色" v-model="form.plateColor" prop="plateColor">
            <el-select v-model="form.plateColor" placeholder="请选择">
              <el-option v-for="item in plateColorOpts" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="发动机号" prop="engineId">
            <el-input v-model="form.engineId"></el-input>
          </el-form-item>

          <el-form-item label="车辆VIN码 " prop="vin">
            <el-input v-model="form.vin"></el-input>
          </el-form-item>

          <el-form-item label="车辆燃料类型" prop="fuelType">
            <el-select v-model="form.fuelType" placeholder="请选择">
              <el-option v-for="item in fuelTypeOpts" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="发动机排量" prop="engineDisplace">
            <el-select v-model="form.engineDisplace" placeholder="请选择">
              <el-option v-for="item in engineDisplaceOpts" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="车辆行驶里程" prop="totalMile">
            <el-input v-model="form.totalMile"></el-input>
          </el-form-item>

          <el-form-item label="车辆检修状态" prop="fixState">
            <el-select v-model="form.fixState" placeholder="请选择">
              <el-option v-for="item in fixStateOpts" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="车辆注册日期" prop="certifyDateA">
            <el-date-picker @change="handleCertifyDateAChange" placeholder="选择时间" v-model="form.certifyDateA"
              value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>

          <el-form-item label="年度审验状态" prop="checkState">
            <el-select v-model="form.checkState" placeholder="请选择">
              <el-option v-for="item in checkStateOpts" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="下次年审时间" prop="nextFixDate">
            <el-date-picker placeholder="选择时间" :picker-options="pickerOptions" v-model="form.nextFixDate"
              value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>

          <el-form-item label="车辆照片：" prop="url">
            <image-upload v-model="form.drivingLicensePic1" filePath="/vehicle" />
          </el-form-item>
        </el-collapse-item>

        <el-collapse-item title="网约车资质信息" name="3">
          <el-form-item label="运输证号" prop="certificate">
            <el-input v-model="form.certificate"></el-input>
          </el-form-item>

          <el-form-item label="发证机构" prop="transAgency">
            <el-input v-model="form.transAgency"></el-input>
          </el-form-item>

          <el-form-item label="经营区域" prop="transArea">
            <el-input v-model="form.transArea"></el-input>
          </el-form-item>

          <el-form-item label="有效日期起" prop="transDateStart">
            <el-date-picker @change="handleVailStartChange" placeholder="选择时间" v-model="form.transDateStart"
              value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>

          <el-form-item label="有效日起止" prop="transDateStop">
            <el-date-picker placeholder="选择时间" value-format="yyyy-MM-dd" v-model="form.transDateStop"></el-date-picker>
          </el-form-item>

          <el-form-item label="车辆初次登记日期" prop="certifyDateB">
            <el-date-picker placeholder="选择时间" value-format="yyyy-MM-dd" v-model="form.certifyDateB"></el-date-picker>
          </el-form-item>

          <el-form-item label="报备日期" prop="registerDate">
            <el-date-picker placeholder="选择时间" value-format="yyyy-MM-dd" v-model="form.registerDate"></el-date-picker>
          </el-form-item>

          <el-form-item label="服务类型：" prop="commercialType">
            <el-select v-model="form.commercialType" placeholder="请选择">
              <el-option v-for="item in serviceOpts" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-collapse-item>
        <el-collapse-item title="其他信息" name="4">
          <el-form-item label="发票打印序列号" prop="feePrintId">
            <el-input v-model="form.feePrintId"></el-input>
          </el-form-item>

          <el-form-item label="卫星定位装置品牌" prop="gpsBrand">
            <el-input v-model="form.gpsBrand"></el-input>
          </el-form-item>

          <el-form-item label="卫星定位装置型号" prop="gpsModel">
            <el-input v-model="form.gpsModel"></el-input>
          </el-form-item>

          <el-form-item label="卫星定位装置IMEI号" prop="gpsImei">
            <el-input v-model="form.gpsImei"></el-input>
          </el-form-item>

          <el-form-item label="新卫星定位装置IMEI号" prop="newGpsImei">
            <el-input v-model="form.newGpsImei"></el-input>
          </el-form-item>

          <el-form-item label="监控id" prop="videoDeviceId">
            <el-input v-model="form.videoDeviceId"></el-input>
          </el-form-item>

          <el-form-item label="监控SIM卡号" prop="videoSim">
            <el-input v-model="form.videoSim"></el-input>
          </el-form-item>

          <el-form-item label="安装日期" prop="gpsInstallDate">
            <el-date-picker placeholder="选择时间" value-format="yyyy-MM-dd" v-model="form.gpsInstallDate"></el-date-picker>
          </el-form-item>
        </el-collapse-item>
      </el-collapse>

      <div class="btn-box" v-if="!readonly">
        <el-button style="width: 120px" size="small" type="success" plain @click="save(false)">暂存数据</el-button>

        <el-button style="width: 120px" size="small" type="primary" plain @click="save(true)">保存数据</el-button>

        <el-button style="width: 120px" size="small" type="info" @click="reset" plain>还原数据</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { BaseDataService } from "@/service";
import moment from "moment";
import { mapState } from "vuex";
import { getVehicleInfo, saveOrUpdateVehicleInfo } from "@/api";

const IMG_TYPE = {
  CARD_POS: "cardPosUrl",
  CARD_NEG: "cardNegUrl",
  CARD_PERSON: "cardUrl",
  DRIVER_LICENCE_ID: "licensePhotoId",
  PHOTO_ID: "photoId",
  CAR_LICENCE_ID: "carLicenseUrl",
  CONTRACT_URL: "contractUrl",
  CAR_PHOTO_URL: "photoId",
  ADVERTISEMENT: "url",
};

export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    data(val) {
      this.form = { ...val };
    },
  },

  mounted() {
    this.genFeePrint();
  },

  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      vehicleTypeOpts: (state) => state.vehicleTypeOpts,
      originTypeOpts: (state) => state.enumMap["VEHICLE_ORIGIN_TYPE"],
      pathOpts: (state) => state.pathList,
    }),
  },
  data() {
    return {
      IMG_TYPE,
      form: {
        vehicleNo: "",
        vehicleColor: "",
        seats: 5,
        plateColor: "5",
        fixState: "1",
        checkState: "1",
        gpsBrand: "途强",
        gpsModel: "GV13",
        fuelType: "C",
        commercialType: 1,
        vehicleType: 1,
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      activeTab: ["1", "2", "3", "4"],
      uploadUrl: "/admin/upload/file",
      dialogVisible: false,
      dialogImageUrl: "",
      fixStateOpts: [
        {
          value: "0",
          label: "未检修",
        },
        {
          value: "1",
          label: "已检修",
        },
        {
          value: "2",
          label: "未知",
        },
      ],
      checkStateOpts: [
        {
          value: "0",
          label: "未年审",
        },
        {
          value: "1",
          label: "年审合格",
        },
        {
          value: "2",
          label: "年审不合格",
        },
      ],
      serviceOpts: BaseDataService.serviceOpts(),
      vehicleColorOpts: ["白色", "黑色", "红色", "褐色", "银灰", "银色", "蓝色", "灰色"],
      brandOpts: [
        "大众",
        "别克",
        "大通",
        "起亚",
        "广汽传祺",
        "长城",
        "东风标致",
        "福特",
        "宝骏",
        "东风日产",
        "众泰",
        "东风雪铁龙",
        "吉利",
        "长安",
      ],
      engineDisplaceOpts: ["1498", "1599", "1798", "1998", "2384"],
      plateColorOpts: [
        {
          value: "5",
          label: "绿色",
        },
        {
          value: "1",
          label: "蓝色",
        },
        {
          value: "2",
          label: "黄色",
        },
        {
          value: "3",
          label: "黑色",
        },
        {
          value: "4",
          label: "白色",
        },
        {
          value: "9",
          label: "其他",
        },
      ],
      seatsOpts: [5, 7],
      fuelTypeOpts: [
        {
          value: "A",
          label: "汽油",
        },
        {
          value: "B",
          label: "柴油",
        },
        {
          value: "C",
          label: "电(以电能驱动的汽车)",
        },
        {
          value: "D",
          label: "混合油",
        },
        {
          value: "E",
          label: "天然气",
        },
        {
          value: "F",
          label: "液化石油气",
        },
      ],
      rules: {
        vehicleType: [{ required: true, message: "请选择解绑类型", trigger: "blur" }],
        carRental: [{ required: true, message: "请输入车租", trigger: "blur" }],
        vehicleNo: [{ required: true, message: "请输入车牌号", trigger: "blur" }],
        plateColor: [{ required: true, message: "请选择车牌颜色", trigger: "blur" }],
        ownerName: [{ required: true, message: "请输入所属者", trigger: "blur" }],
        brand: [{ required: true, message: "请输入车辆厂牌", trigger: "blur" }],
        brand2: [{ required: true, message: "请输入车辆厂牌口头称呼", trigger: "blur" }],
        model: [{ required: true, message: "请输入车辆型号", trigger: "blur" }],
        seats: [{ required: true, message: "请输入核定载客位", trigger: "blur" }],
        // [IMG_TYPE.CAR_PHOTO_URL]: [
        //     {required: true, message: '请上传车辆照片', trigger: 'blur'},
        // ],
        vehicleColor: [{ required: true, message: "请选择车身颜色", trigger: "blur" }],
        engineId: [{ required: true, message: "请输入发动机号", trigger: "blur" }],
        vin: [
          { required: true, message: "请输入VIN码", trigger: "blur" },
          { min: 17, max: 17, message: "17位字符", trigger: "blur" },
        ],
        certifyDateA: [{ required: true, message: "请选择车辆注册日期", trigger: "blur" }],
        fuelType: [{ required: true, message: "请选择车辆燃料类型", trigger: "blur" }],
        engineDisplace: [{ required: true, message: "请选择发动机排量", trigger: "blur" }],
        certificate: [{ required: true, message: "请输入运输证号", trigger: "blur" }],
        transAgency: [{ required: true, message: "请输入发证机构", trigger: "blur" }],
        transArea: [{ required: true, message: "请输入车辆经营区域", trigger: "blur" }],
        transDateStart: [
          {
            required: true,
            message: "请选择有效日期起",
            trigger: "blur",
          },
        ],
        transDateStop: [
          {
            required: true,
            message: "请选择有效日起止",
            trigger: "blur",
          },
        ],
        certifyDateB: [
          {
            required: true,
            message: "请选择初次登记日期",
            trigger: "blur",
          },
        ],
        fixState: [
          {
            required: true,
            message: "请选择车辆检修状态",
            trigger: "blur",
          },
        ],
        nextFixDate: [
          {
            required: true,
            message: "请选择下次年审时间",
            trigger: "blur",
          },
        ],
        feePrintId: [
          {
            required: true,
            message: "请输入发票打印序列号",
            trigger: "blur",
          },
        ],
        gpsBrand: [
          {
            required: true,
            message: "请输入卫星定位装置品牌",
            trigger: "blur",
          },
        ],

        gpsModel: [
          {
            required: true,
            message: "请输入卫星定位装置型号",
            trigger: "blur",
          },
        ],
        gpsImei: [
          {
            required: true,
            message: "请输入卫星定位装置IMEI号",
            trigger: "blur",
          },
        ],
        videoDeviceId: [{ required: true, message: "请输入监控id", trigger: "blur" }],
        gpsInstallDate: [
          {
            required: true,
            message: "请选择安装日期",
            trigger: "blur",
          },
        ],
        registerDate: [
          {
            required: true,
            message: "请选择报备日期",
            trigger: "blur",
          },
        ],
        commercialType: [
          {
            required: true,
            message: "请选择服务类型",
            trigger: "blur",
          },
        ],
        checkState: [
          {
            required: true,
            message: "请选择车辆年度审验状态",
            trigger: "blur",
          },
        ],
        totalMile: [
          {
            required: true,
            message: "请输入车辆行驶里程",
            trigger: "blur",
          },
        ],
      },
      addressCodeOpts: [
        { label: "武汉市", value: "420100", transAgency: "武汉市客运出租汽车管理处" },
        { label: "岳阳市", value: "430600", transAgency: "岳阳市交通运输局" },
        { label: "荆州市", value: "421087", transAgency: "荆州市交通运输局" },
      ],
    };
  },
  methods: {
    dateChange(date, field, year) {
      const day = moment(date);
      day.add(year, "y");
      day.subtract(1, "days");
      this.$set(this.form, field, day.format("YYYY-MM-DD"));
    },

    handleVailStartChange(val) {
      const random = 5 + Math.floor(Math.random() * 5);
      const day = moment(val);
      day.subtract(random, "days");
      this.$set(this.form, "gpsInstallDate", day.format("YYYY-MM-DD"));
      this.dateChange(val, "transDateStop", 3);
    },

    handleAddressChange(val) {
      const item = this.addressCodeOpts.find((item) => item.value === val);
      this.$set(this.form, "transArea", item.label);
      this.$set(this.form, "transAgency", item.transAgency);
    },

    // eslint-disable-next-line no-unused-vars
    handleCertifyDateAChange(val) {
      // const random = 5 + Math.floor(Math.random() * 5);
      // const day = moment(val);
      // day.add(random, 'days');
      // this.$set(this.form, 'certifyDateB', day.format('YYYY-MM-DD'));
      // const random2 = 20 + Math.floor(Math.random() * 7);
      // const day2 = moment(val);
      // day2.add(random2, 'days');
      // this.$set(this.form, 'registerDate', day2.format('YYYY-MM-DD'));
      // // 根据车辆初次登记日期计算下次年检日期
      // const year = day.year();
      // let addYear = 0;
      // if (year === 2019) {
      //     addYear = 2;
      // } else if (year >= 2013 && year < 2019) {
      //     year % 2 === 0 ? addYear = 2020 - year : addYear = 2019 - year;
      // } else {
      //     addYear = 2020 - year;
      // }
      // day.add(addYear, 'y');
      // day.subtract(1, 'd');
      // this.$set(this.form, 'nextFixDate', day.format('YYYY-MM-DD'));
    },

    save(checkData) {
      this.genFeePrint();
      if (checkData) {
        this.$refs.form.validate((valid) => {
          if (!valid) {
            return;
          }

          if (this.form.certifyDateB >= this.form.transDateStart) {
            this.$message.error("车辆初次登记日期必须早于有效日期起");
            return;
          }

          saveOrUpdateVehicleInfo(this.form).then(() => {
            this.$message.success("保存成功");
            this.$emit("update");
          });
        });
      } else {
        this.$refs.form.clearValidate();
        saveOrUpdateVehicleInfo(this.form).then(() => {
          this.$message.success("暂存成功");
          this.$emit("update");
        });
      }
    },

    reset() {
      this.form = { ...this.data };
    },

    query() {
      getVehicleInfo(this.id).then((res) => {
        this.form = res.data;
      });
    },

    genFeePrint() {
      if (!this.form.feePrintId) {
        const id =
          `CN` +
          this.genRandomNum() +
          this.genRandomNum() +
          this.genRandomNum() +
          this.genRandomChart() +
          this.genRandomNum() +
          this.genRandomChart() +
          this.genRandomNum() +
          this.genRandomChart();
        this.$set(this.form, "feePrintId", id);
      }
    },

    genRandomChart() {
      const chart = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      const index = Math.floor(Math.random() * chart.length);
      return chart[index];
    },

    genRandomNum() {
      return Math.floor(Math.random() * 10);
    },
  },
};
</script>

<style scoped lang="less">
.root-box-detail {
  padding: 0 10px 30px;

  :deep(.el-collapse-item__header) {
    font-weight: bold;
    font-size: 16px;
  }

  .btn-box {
    margin-top: 40px;
    text-align: center;
    display: flex;
    justify-content: flex-end;
  }

  .driver-container {
    :deep(.el-input__inner) {
      width: 215px;
    }
  }
}
</style>
