<template>
  <div class="schedule-container">
    <div class="head">
      <div class="left">
        【{{ areaId | enumTransfer(areaOpts, "id", "name") }}】共{{ total }}坐可用

        <el-button class="btn" type="primary" plain @click="onModifyProportion(areaId)" size="mini">设置发班比例</el-button>
      </div>
      <div class="right" @click="add()">+添加</div>
    </div>

    <div class="content">
      <div v-for="(driver, index) in data" class="row" :class="{
        electric: driver.teamId === 4,
        xiangf: driver.yueyangCar,
        stop: driver.rest,
      }" :key="driver.id">
        <div :span="3" style="width: 80px">
          <span class="index">{{ index + 1 }}</span>
          <el-popover placement="right" width="200" trigger="hover">
            <el-table :data="driver.limitPathIds" empty-text="全路线" size="small" row-class-name="pop-box"
              :show-header="false">
              <el-table-column type="index" label="序号"></el-table-column>
              <el-table-column label="路线">
                <template slot-scope="scope">
                  {{ scope.row | enumTransfer(pathOpts, "id", "name") }}
                </template>
              </el-table-column>
            </el-table>
            <span @click="showDriverLocation(driver.driverId)" slot="reference">
              {{ driver.driverNo }}
            </span>
          </el-popover>
        </div>
        <div>{{ driver.status | enumTransfer(schedulingStatus) }}</div>
        <div :span="3" style="width: 100px">
          <span v-if="driver.arriveTime"> {{ driver.arriveTime | dateFormat("MM-dd hh:mm") }}</span>
        </div>
        <div :span="3" style="width: 100px">
          {{ driver.tripTimes }}次/{{ (driver.routePoints / 100).toFixed(1) }}分
        </div>

        <div :span="4" style="width: 130px">
          <span :class="{
            red: driver.continuousNightDay > 2 || driver.nightDays > 9,
          }">{{ driver.continuousNightDay || 0 }}/{{ driver.nightDays || 0 }}过夜 </span>-<span
            :class="{ red: driver.continuousBackTooLateDay > 2 }">{{ driver.continuousBackTooLateDay || 0 }}晚归</span>
        </div>

        <div :span="4" style="width: 140px">
          <span :class="{ red: driver.leaveDays > driver.maxLeaveDaysPerMonth }">
            {{ driver.monthRoutePoints }}/{{ driver.basePoints }}积分&nbsp;
            {{ driver.leaveDays }}/{{ driver.maxLeaveDaysPerMonth }}休
          </span>
        </div>

        <div :span="3" style="width: 100px">
          <div v-if="driver.backTomorrow">
            <span class="green">明日销假</span>
          </div>
          <div v-else>{{ driver.offTime | leaveFilter }}</div>
        </div>
        <!-- {{ driver.nightArea }}/过{{ driver.nightDays }}夜 -->

        <div :span="2" class="btns" style="width: 8%">
          <el-dropdown @command="(command) => handleCommand(command, driver)">
            <span class="el-dropdown-link">
              操作
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="el-icon-close" command="1">取消发班</el-dropdown-item>
              <el-dropdown-item icon="el-icon-moon" command="2">临时请假</el-dropdown-item>
              <el-dropdown-item icon="el-icon-more" command="3">修正排班信息</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <driver-modal ref="driverModal" :cb="onAdd" :areaId="areaId" />

    <el-dialog :visible.sync="visible" width="500px" max-height="600" :size="size" title="修正排班信息"
      :close-on-click-modal="false">
      <el-form ref="form" :model="form" :size="size" label-width="120px">
        <el-form-item label="状态：" prop="status">
          <el-select v-model="form.status" style="width: 280px">
            <el-option v-for="item in schedulingStatus" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="是否待命：" prop="standby">
          <el-select v-model="form.standby" style="width: 280px">
            <el-option v-for="item in yesNoOpts" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="出发时间：" :size="size">
          <el-date-picker style="width: 280px" placeholder="选择时间" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
            v-model="form.leaveTime"></el-date-picker>
        </el-form-item>

        <el-form-item label="到达时间：" :size="size">
          <el-date-picker style="width: 280px" placeholder="选择时间" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
            v-model="form.arriveTime"></el-date-picker>
        </el-form-item>

        <el-form-item label="发班序号：" prop="driverIndex">
          <el-input v-model="form.driverIndex" style="width: 280px"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button size="small" @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="changeInfo" size="small">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="leaveModalVisible" width="500px" max-height="600" :size="size" title="临时请假"
      :close-on-click-modal="false">
      <el-form ref="leaveForm" :model="leaveForm" :rules="rules" :size="size" label-width="120px">
        <el-form-item label="请假司机：">
          <span>{{ leaveForm.name }}</span>
        </el-form-item>

        <el-form-item label="开始日期：" :size="size" prop="sdate">
          <el-date-picker style="width: 280px" placeholder="选择时间" type="date" value-format="yyyy-MM-dd"
            v-model="leaveForm.sdate"></el-date-picker>
        </el-form-item>

        <el-form-item label="结束日期：" :size="size" prop="edate">
          <el-date-picker style="width: 280px" placeholder="选择时间" type="date" value-format="yyyy-MM-dd"
            v-model="leaveForm.edate"></el-date-picker>
        </el-form-item>

        <el-form-item label="请假理由：" prop="leaveCause">
          <el-select v-model="leaveForm.leaveCause" style="width: 280px">
            <el-option v-for="item in leaveCauseOpt" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="请假原因描述：">
          <el-input row="3" type="textarea" v-model="leaveForm.causeBz" style="width: 280px"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button size="small" @click="leaveModalVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitDriverLeave" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import DriverModal from "./DriverModal";
import { cancelTrip, adjustScheduling, submitLeave } from "@/api";
import { mapState } from "vuex";
import _ from "lodash";
import { SHOW_DRIVER_LOCATION } from "@/core/const/global-event";
import { DateUtil } from "@/util";
import { BaseDataService } from "@/service";

export default {
  components: {
    DriverModal,
  },

  props: {
    areaId: Number,
    data: Array,
    onSchedulingChange: Function,
    onModifyProportion: Function,
  },
  filters: {
    nameFilter(val) {
      return val.substr(0, 1) + "";
    },
    leaveFilter(offTime) {
      if (offTime) {
        return DateUtil.dateStrFormat(offTime, "MM-dd收车");
      }
      return "";
    },
  },
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      areaOpts: (state) => state.areaOpts,
      schedulingStatus: (state) => state.enumMap["SCHEDULING_STATUS"],
      pathOpts: (state) => state.pathList,
    }),
    total() {
      if (this.data) {
        let sum = 0;
        this.data.forEach((item) => {
          sum += item.departStandard;
        });

        return sum;
      }
      return 0;
    },
  },
  data() {
    return {
      visible: false,
      yesNoOpts: BaseDataService.yesNoOpts(),
      form: {},
      driver: {},
      leaveModalVisible: false,
      leaveForm: {},
      leaveCauseOpt: [
        { value: "SICK", label: "临时因病" },
        { value: "PLAN", label: "临时因事" },
        { value: "CAR", label: "临时车辆故障" },
      ],
      rules: {
        sdate: [{ required: true, message: "请选择请假开始日期", trigger: "blur" }],
        edate: [{ required: true, message: "请选择请假结束日期", trigger: "blur" }],
        leaveCause: [{ required: true, message: "请选择请假原因", trigger: "blur" }],
      },
    };
  },
  methods: {
    add() {
      this.$refs.driverModal.open();
    },

    onAdd() {
      this.onSchedulingChange();
    },

    showDriverLocation(driverId) {
      this.$bus.$emit(SHOW_DRIVER_LOCATION, driverId);
    },

    handleCommand(type, data) {
      if (type === "1") {
        this.cancelTrip(data);
      } else if (type === "2") {
        this.openLeaveModal(data);
      } else if (type === "3") {
        this.openModal(data);
      }
    },

    openLeaveModal(driver) {
      this.leaveForm = {
        driverId: driver.driverId,
        areaId: driver.areaId,
        name: `${driver.driverNo} - ${driver.driverName}`,
      };
      this.leaveModalVisible = true;
    },

    cancelTrip(driver) {
      const param = {
        driverId: driver.driverId,
        pathId: this.pathId,
      };
      cancelTrip(param).then(() => {
        this.onSchedulingChange();
      });
    },

    openModal(item) {
      this.visible = true;
      this.form = _.cloneDeep(item);
    },
    changeInfo() {
      adjustScheduling(this.form).then(() => {
        this.$message.success("修改成功");
        this.visible = false;
        this.onSchedulingChange();
      });
    },

    submitDriverLeave() {
      this.$refs.leaveForm.validate((valid) => {
        if (valid) {
          submitLeave(this.leaveForm).then(() => {
            this.$message.success("提交成功");
            this.onSchedulingChange();
            this.leaveModalVisible = false;
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.schedule-container {
  display: flex;
  flex-direction: column;

  .head {
    height: 50px;
    line-height: 50px;
    background-color: #adc6ff;
    font-size: 18px;
    margin: 10px 0 0 0;
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      margin-left: 20px;

      .btn {
        position: relative;
        top: -2px;
        margin-left: 16px;
      }
    }

    .right {
      margin-right: 20px;
    }
  }

  .content {
    height: 500px;
    border: 1px solid #ddd;
    padding: 10px 0 10px 10px;
    overflow: auto;
    font-size: 15px;

    .row {
      line-height: 32px;
      padding: 0 10px;
      margin: 0;
      display: flex;

      >div {
        min-width: 50px;
      }

      .index {
        display: inline-block;
        width: 30px;
      }

      &:hover {
        background-color: #adc6ff;
      }

      &.electric {
        color: #eb8c0f;
      }

      &.xiangf {
        color: #009966 !important;
      }

      &.stop {
        color: red !important;
      }
    }

    .btns {
      margin-left: 10px;
    }
  }
}

.leave-form .title {
  text-align: center;
  margin-bottom: 24px;
  font-size: 16px;
}

.leave-form .el-form-item--small {
  display: inline-block;
  width: 50%;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}

/deep/.pop-box {
  background-color: rgb(107, 105, 105);
  color: #fdd939;
  font-size: 16px;
  font-weight: bold;
}
</style>
