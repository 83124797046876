<template>
  <el-container>
    <el-header>
      <el-row class="file-btns">
        <router-link style="display: inline-block; margin-top: 12px; text-decoration: none" to="TrainFileList"><i
            class="el-icon-back"></i>返回</router-link>
        <el-button style="position: absolute; right: 156px" @click="showDocxPannel = true">上传文档</el-button>
        <el-button style="position: absolute; right: 78px" @click="cancelEdit">取消</el-button>
        <el-button style="position: absolute; right: 0px" type="primary" @click="saveFile">确认</el-button>
      </el-row>
    </el-header>

    <el-divider style="margin: 12px 0"></el-divider>
    <el-main>
      <el-row class="file-title">
        <el-col @dblclick.native="editTitle" v-show="!titleEdit">{{
          trainFile.title
        }}</el-col>
        <el-col v-show="titleEdit">
          <el-input id="title-input" v-model="trainFile.title" @blur="titleEdit = false"></el-input>
        </el-col>
      </el-row>
      <el-row class="file-cap1" :key="cap.id" v-for="(cap, idx) in trainFile.caps">
        <el-row class="title" :class="[curCap == cap ? 'choosed' : '']">
          <i class="el-icon-arrow-down" v-if="!cap.open"></i>
          <i class="el-icon-arrow-up" v-if="cap.open"></i>
          <span style="margin-right: 24px" v-show="!cap.edit" @click="troggleOpen(cap, 1)" @dblclick="editCapital(cap)"
            :class="cap.revokeState == 1 ? 'del' : ''">{{ cap.seq ? cap.seq + cap.title : cap.title }}</span>
          <span v-show="cap.edit">
            <el-input :id="cap.id + '-seq'" style="width: 80px; margin-right: 8px" v-model="cap.seq" placeholder="编号" />
            <el-input :id="cap.id + '-title'" style="width: 400px; margin-right: 8px" v-model="cap.title" />
            <el-button @click="cap.edit = false">确定</el-button>
          </span>
          <i v-show="curCap == cap && !cap.edit" class="el-icon-circle-plus-outline" style="margin-right: 8px"
            @click="addCapital"></i>
          <i v-show="curCap == cap && !cap.edit && cap.revokeState != 1" class="el-icon-remove-outline"
            @click="revokeCapital(cap, null, idx)"></i>
          <i v-show="curCap == cap && !cap.edit && cap.revokeState == 1" class="el-icon-check"
            @click="invokeCapital(cap, null, idx)"></i>
        </el-row>

        <el-row class="file-cap2" :key="cap2.id" v-show="cap.open" v-for="cap2 in cap.cap2s">
          <el-col :span="24" class="title">
            <i class="el-icon-arrow-down" v-if="!cap2.open"></i>
            <i class="el-icon-arrow-up" v-if="cap2.open"></i>
            <span v-show="!cap2.edit" @click="troggleOpen(cap2, 2)" @dblclick="editCapital2(cap2)"
              :class="cap2.revokeState == 1 ? 'del' : ''">{{ cap2.seq ? cap2.seq + cap2.title : cap2.title }}</span>
            <span v-show="!cap2.edit" style="margin-left: 24px; font-weight: normal">
              <i v-show="cap2.revokeState != 1" class="el-icon-remove-outline"
                @click="revokeCapital(cap, cap2, idx)"></i>
              <i v-show="cap2.revokeState == 1" class="el-icon-check" @click="invokeCapital(cap, cap2, idx)"></i>
            </span>
            <span v-show="!cap2.edit" style="margin-left: 24px; font-weight: normal" @click="openMapMarkers(cap2)">
              <el-link type="primary">添加标注数据</el-link>
            </span>
            <span v-show="!cap2.edit" style="margin-left: 24px; font-weight: normal" @click="intitAmap(cap2)">
              <el-link type="primary">查看标注</el-link>
            </span>
            <span v-show="cap2.edit">
              <el-input :id="cap2.id + '-seq'" style="width: 80px;margin-right: 8px" v-model="cap2.seq"
                placeholder="编号" />
              <el-input :id="cap2.id + '-title'" style="width: 400px;margin-right: 8px" v-model="cap2.title" />
              <el-button @click="cap2.edit = false">确定</el-button>
            </span>
          </el-col>
          <span @dblclick="editContent(cap2)" v-show="cap2.open && !cap2.contentEdit">
            <el-col :span="24" class="content" v-html="cap2.content"></el-col>
          </span>
          <span v-show="cap2.open && cap2.contentEdit">
            <el-col :span="24" class="content" :id="'cr' + cap2.id">
              <el-row :id="'c' + cap2.id"></el-row>
            </el-col>
          </span>
        </el-row>
      </el-row>
    </el-main>

    <el-dialog title="上传文档" :visible.sync="showDocxPannel" width="396px">
      <el-upload class="upload-demo" drag :multiple="false" :auto-upload="true"
        :action="`/admin/training/uploadfile/${fileId}`" :on-success="upploadDocxFile">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
    </el-dialog>

    <el-dialog title="增加标题" :visible.sync="showAddCapitalDialog" width="450px">
      <el-row>
        <span>
          <el-col :span="6">标题等级</el-col>
          <el-col :span="18">
            <el-select v-model="capLvl" placeholder="请选择" style="width: 180px">
              <el-option v-for="opt in capLvlOption" :key="opt.value" :label="opt.label" :value="opt.value">
              </el-option>
            </el-select>
          </el-col>
        </span>
        <span style="margin-top: 8px">
          <el-col :span="6">数量</el-col>
          <el-col :span="18">
            <el-input-number v-model="num"></el-input-number>
          </el-col>
        </span>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAddCapital">取消</el-button>
        <el-button type="primary" @click="doAddCapital">确认</el-button>
      </span>
    </el-dialog>

    <el-dialog title="确认类型" :visible.sync="showSaveDialog" width="450px">
      <span slot="footer" class="dialog-footer">
        <el-button @click="doSaveFile">保存不提示</el-button>
        <el-button @click="updateFileVersion">保存并提示</el-button>
      </span>
    </el-dialog>

    <el-dialog title="取消编辑" :visible.sync="showCancelDialog" width="450px">
      <el-row>
        <el-col>确定放弃本次修改吗？</el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showCancelDialog = false">取消</el-button>
        <el-button type="primary" @click="doCancelEdit">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="添加标注数据" :visible.sync="showLocationDialog" width="1200px">
      <el-input type="textarea" :autosize="{ minRows: 20 }" v-model="markers"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showLocationDialog = false">取消</el-button>
        <el-button type="primary" @click="saveMarkers">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="查看标注" :visible.sync="showMapDialog" width="1200px">
      <div id="amap" style="width: 1160px; height: 600px"></div>
    </el-dialog>
  </el-container>
</template>

<script>
import WangEditor from "wangeditor";
import { DateUtil } from "@/util";

export default {
  name: "TrainFile",
  components: {},
  data() {
    return {
      showDocxPannel: false,
      fileId: "",
      titleEdit: false,
      showSaveDialog: false,
      showCancelDialog: false,
      showAddCapitalDialog: false,
      showMapDialog: false,
      showLocationDialog: false,
      mouseTool: null,
      trainFile: {},
      capLvlOption: [
        { value: 1, label: 1 },
        { value: 2, label: 2 },
      ],
      capLvl: 1,
      num: 1,
      curCap: null,
      curCap2: null,
      markers: {},
    };
  },
  created() { },
  mounted() {
    this.initPage();
    window.addEventListener("click", this.clickDocument);
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    upploadDocxFile(response, file, fileList) {
      this.showDocxPannel = false;
      if (response.code === 200) {
        this.trainFile = response.data;
        this.trainFile.caps &&
          this.trainFile.caps.forEach((c) => {
            c.cap2s &&
              c.cap2s.forEach((c2) => {
                let str = "";
                c2.mapFeatures &&
                  c2.mapFeatures.forEach((s) => {
                    if (str) {
                      str = str + "," + s;
                    } else {
                      str = s;
                    }
                  });
                this.$set(c2, "mapstr", str);
              });
          });
      } else {
        this.$message.error(response.message);
      }
    },
    openMapMarkers(cap2) {
      this.showLocationDialog = true;
      this.curCap2 = cap2;
      this.markers = this.curCap2.markers;
    },
    saveMarkers() {
      this.showLocationDialog = false;
      this.curCap2.markers = this.markers;
    },
    intitAmap(cap2) {
      this.showMapDialog = true;
      this.curCap2 = cap2;
      this.markers = this.curCap2.markers;
      this.$nextTick(() => {
        AMap.plugin(
          [
            "AMap.MouseTool",
            "AMap.PolygonEditor",
            "AMap.GeoJSON",
          ],
          () => {
            var amap = new AMap.Map("amap", {
              center: [114.254604, 30.619396], //汉口火车站
              zoom: 14,
            });
            if (this.markers) {
              var geoJSON = JSON.parse(this.markers);
              var geo = new AMap.GeoJSON({
                geoJSON: geoJSON,
              });
              amap.add(geo);
            }
          }
        );
      });
    },
    initPage() {
      this.fileId = this.$route.query.fileId;
      if (this.fileId) {
        let url = "/admin/training/getfile";
        this.$http.post(url, { fileId: this.fileId }).then((res) => {
          if (res.code === 200) {
            this.trainFile = res.data;
            this.trainFile.caps &&
              this.trainFile.caps.forEach((c) => {
                c.cap2s &&
                  c.cap2s.forEach((c2) => {
                    let str = "";
                    c2.mapFeatures &&
                      c2.mapFeatures.forEach((s) => {
                        if (str) {
                          str = str + "," + s;
                        } else {
                          str = s;
                        }
                      });
                    this.$set(c2, "mapstr", str);
                  });
              });
          }
        });
      } else {
        var trainCategory = this.$route.query.category;
        var trainType = this.$route.query.trainType;
        var teams = this.$route.query.teams;
        var seq = this.$route.query.seq;
        this.trainFile = {
          id: "",
          title: "文档标题",
          caps: [
            {
              open: false,
              edit: false,
              id: this.getRdmId(),
              seq: "",
              title: "一级标题--DEMO",
              cap2s: [
                {
                  open: false,
                  edit: false,
                  contentEdit: false,
                  mapFeatures: [],
                  showMapFeature: false,
                  id: this.getRdmId(),
                  seq: "",
                  title: "二级标题--DEMO",
                  content: "请在这里填充详细描述内容...",
                },
              ],
            },
          ],
          version: 0,
          trainCategory: trainCategory,
          trainType: trainType,
          teams: teams,
          seq: seq,
          ctime: null,
          uptime: null,
        };
      }
    },
    closeEditor() {
      this.trainFile.caps.forEach((cap) => {
        for (var i = 0; i < cap.cap2s.length; i++) {
          this.$set(cap.cap2s[i], "contentEdit", false);
        }
      });
    },
    initEditor(cap) {
      let editor = $("#cr" + cap.id).data("wangEditor");
      if (editor) {
        return;
      }
      editor = new WangEditor("#c" + cap.id);
      editor.config.onchange = (html) => {
        cap.content = html;
        cap.uptime = DateUtil.getDateTimeStr();
      };
      editor.create();
      editor.txt.html(cap.content);
      $("#cr" + cap.id).data("wangEditor", editor);
    },
    cancelEdit() {
      this.closeEditor();
      this.showCancelDialog = true;
    },
    doCancelEdit() {
      this.showCancelDialog = false;
      this.initPage();
    },
    editTitle() {
      this.closeEditor();
      this.titleEdit = true;
      this.$nextTick(() => {
        $("#title-input").focus();
      });
    },
    editCapital(cap) {
      this.closeEditor();
      this.$set(cap, "edit", true);
      this.$nextTick(() => {
        $("#" + cap.id + "-title").focus();
      });
    },
    editCapital2(cap2) {
      this.closeEditor();
      this.$set(cap2, "edit", true);
      this.$nextTick(() => {
        $("#" + cap2.id + "-title").focus();
      });
    },
    editContent(cap2) {
      this.closeEditor();
      this.$set(cap2, "contentEdit", true);
      this.$nextTick(() => {
        this.initEditor(cap2);
      });
    },
    saveFile() {
      this.showSaveDialog = true;
      this.closeEditor();
    },
    doSaveFile() {
      this.showSaveDialog = false;
      let url = "/admin/training/savefile";
      this.$http.post(url, this.trainFile).then((res) => {
        if (res.code === 200) {
          this.trainFile = res.data;
          this.fileId = res.data.id;
          this.$message.success("保存成功。");
        }
      });
    },
    updateFileVersion() {
      this.trainFile.version++;
      this.doSaveFile();
    },
    troggleOpen(cap, lvl) {
      this.closeEditor();
      let opened = cap.open;
      this.$set(cap, "open", !opened);
      if (lvl == 1) {
        this.curCap = cap;
      } else {
        this.curCap = null;
      }
    },
    addCapital() {
      this.showAddCapitalDialog = true;
      this.num = 1;
    },
    doAddCapital() {
      var arr, def;
      if (this.capLvl == 2) {
        def = {
          open: false,
          edit: false,
          contentEdit: false,
          id: "",
          title: "二级标题--DEMO",
          content: "请在这里填充详细描述内容...",
        };
        arr = this.curCap.cap2s;
      } else {
        def = {
          open: false,
          edit: false,
          id: "",
          title: "一级标题--DEMO",
          cap2s: [],
        };
        arr = this.trainFile.caps;
      }

      for (var i = 0; i < this.num; i++) {
        var cap = $.extend({}, def);
        cap.id = this.getRdmId();
        if (this.capLvl == 1) {
          cap.cap2s = [];
        }
        arr.push(cap);
      }
      this.showAddCapitalDialog = false;
    },
    revokeCapital(cap, cap2, idx) {
      this.$confirm('作废当前章节， 用户将不可见， 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var isRdmId = false;
        if (cap2) {
          isRdmId = cap2.id.indexOf("rdm-") == 0;
        } else {
          isRdmId = cap.id.indexOf("rdm-") == 0;
        }
        if (isRdmId) {
          if (cap2) {
            cap.cap2s.splice(idx, 1);
          } else {
            this.trainFile.caps.splice(idx, 1);
          }
        } else {
          this.$http.post("/admin/training/revokechapter/" + this.trainFile.id, {
            cap1Id: cap.id,
            cap2Id: cap2 ? cap2.id : null, revokeState: 1
          }).then(() => {
            if (cap2) {
              cap2.revokeState = 1;
            } else {
              cap.revokeState = 1;
            }
          });
        }
      });
    },
    invokeCapital(cap, cap2) {
      this.$confirm('撤销作废， 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post("/admin/training/revokechapter/" + this.trainFile.id, {
          cap1Id: cap.id,
          cap2Id: cap2 ? cap2.id : null, revokeState: 0
        }).then(() => {
          if (cap2) {
            cap2.revokeState = 0;
          } else {
            cap.revokeState = 0;
          }
        });
      });
    },
    cancelAddCapital() {
      this.showAddCapitalDialog = false;
    },
    getRdmId() {
      return "rdm-" + Math.round(Math.random() * 1000000000000);
    },
    editFeature(cap) {
      this.$set(cap, "showMapFeature", true);
    },
    clickDocument(e) {
      if (!$(e.target).hasClass("edit-feature")) {
        this.trainFile.caps.forEach((c) => {
          c.cap2s.forEach((cp2) => {
            let str = "";
            (cp2.mapFeatures || []).forEach((s) => {
              if (str) {
                str = str + "," + s;
              } else {
                str = s;
              }
            });
            this.$set(cp2, "mapstr", str);
            this.$set(cp2, "showMapFeature", false);
          });
        });
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("click", this.clickDocument);
  },
};
</script>

<style scoped>
.choosed {
  background-color: #eee;
}

.file-btns {
  margin-top: 12px;
  margin-right: 8px;
}

.file-title {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  height: 42px;
  line-height: 36px;
}

.file-cap1 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
}

.file-cap1 .title {
  line-height: 36px;
  cursor: pointer;
}

.file-cap2 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  margin-left: 32px;
}

.file-cap2 .title {
  line-height: 32px;
  cursor: pointer;
}

.file-cap2 .content {
  line-height: 28px;
  font-size: 14px;
  font-weight: normal;
  margin-left: 20px;
}

.box-card {
  width: 400px;
}

.box-card .text {
  font-size: 14px;
}

.box-card .item {
  margin-bottom: 14px;
}

.del {
  text-decoration: line-through;
}
</style>