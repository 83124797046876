<template>
    <div class="address-search">
        <el-autocomplete :disabled="disabled" style="width: 100%" class="inline-input" value-key="name"
            v-model="searchKey" :fetch-suggestions="fetchSuggestions" placeholder="请输入地址关键字" :clearable="true"
            @clear="clearText" @select="handleChoose">
            <template slot="prepend" v-if="areaInfo">{{ areaInfo.name }}</template>
        </el-autocomplete>
    </div>
</template>

<script>
import { searchPlace } from "@/api";

export default {
    name: "AddressSearch",
    props: {
        value: {
            type: Object,
            default: () => null
        },
        area: {
            type: Object,
            default: () => null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            searchKey: "",
            areaInfo: {}
        };
    },

    watch: {
        value: {
            handler(val) {
                if (val) {
                    this.searchKey = val.name;
                } else {
                    this.searchKey = null;
                }
            },
            immediate: true
        },
        searchKey(val) {
            if (this.value && val !== this.value.name) {
                this.$emit("input", null);
            }
        },

        area: {
            handler(val) {
                this.areaInfo = val;
            },
            immediate: true
        }
    },

    methods: {
        handleChoose(item) {
            const address = {
                name: item.name,
                lng: `${item.lng}`,
                lat: `${item.lat}`,
            };
            this.$emit("input", address);
            this.$emit("select", address);
        },

        async fetchSuggestions(query, cb) {
            if (!query) {
                cb([]);
                return;
            }
            const res = await searchPlace(this.areaInfo.id, query);
            cb(res.data);
        },

        clearText() {
            this.searchKey = "";
        }
    }
};
</script>

<style lang="less" scoped>
.address-search {
    display: inline-block;
    width: 100%;
}
</style>