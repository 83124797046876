import { Http } from "@/util";

// 区域设置
export function addArea(param) {
  return Http.post("/admin/setting/area/add", param);
}

export function modifyArea(param) {
  return Http.post("/admin/setting/area/update", param);
}

export function listArea(param) {
  return Http.post("/admin/setting/area/list", param);
}

export function listSpecArea(pathId) {
  return Http.post(`/admin/setting/spec/area/list?pathId=${pathId}`, { _useCache: true });
}

// 路线设置
export function addPath(param) {
  return Http.post("/admin/setting/path/add", param);
}

export function modifyPath(param) {
  return Http.post("/admin/setting/path/update", param);
}

// 路线配置设置
export function addPathConfig(param) {
  return Http.post("/admin/setting/path/config/add", param);
}

export function modifyPathConfig(param) {
  return Http.post("/admin/setting/path/config/update", param);
}

export function listPathConfig() {
  return Http.get("/admin/setting/path/config/list");
}

// 车型设置
export function listVehicleType(useCache = true) {
  return Http.get("/admin/vehicle/type/list", { _useCache: useCache });
}

// 新增车型
export function addVehicleType(param) {
  return Http.post("/admin/vehicle/type/add", param);
}

// 修改车型
export function modifyVehicleType(param) {
  return Http.post("/admin/vehicle/type/modify", param);
}

// 删除车型
export function delVehicleType(param) {
  return Http.post("/admin/vehicle/type/del", param);
}

// 车票模板
export function addTicketTemplate(param, applyToCurrent) {
  return Http.post(`/admin/setting/ticket_template/add?applyToCurrent=${applyToCurrent}`, param);
}

export function modifyTicketTemplate(param, applyToCurrent) {
  return Http.post(`/admin/setting/ticket_template/update?applyToCurrent=${applyToCurrent}`, param);
}

export function listTicketTemplate(param) {
  return Http.post("/admin/setting/ticket_template/list", param);
}

export function listTicketTemplateDetail(param) {
  return Http.get("/admin/setting/ticket_template/detail/list", param);
}

export function modifyTicketTemplateDetail(param) {
  return Http.post("/admin/setting/ticket_template/detail/list/update", param);
}

// 加价区域
export function listUpriceArea(param) {
  return Http.post("/admin/setting/uprice/area/list", param);
}

export function addUpriceArea(param) {
  return Http.post("/admin/setting/uprice/area/add", param);
}

export function modifyUpriceArea(param) {
  return Http.post("/admin/setting/uprice/area/update", param);
}

export function removeUpriceArea(param) {
  return Http.post("/admin/setting/uprice/area/delete", param);
}

export function copyUpriceArea(param) {
  return Http.post("/admin/setting/uprice/area/copy", param);
}

// 早价规则设置

export function listAheadPriceRule(param) {
  return Http.post("/admin/setting/rule/ahead/list", param);
}

export function addAheadPriceRule(param) {
  return Http.post("/admin/setting/rule/ahead/add", param);
}

export function modifyAheadPriceRule(param) {
  return Http.post("/admin/setting/rule/ahead/update", param);
}

// 送券规则设置

export function listCouponRule(param) {
  return Http.post("/admin/setting/rule/coupon/list", param);
}

export function addCouponRule(param) {
  return Http.post("/admin/setting/rule/coupon/add", param);
}

export function modifyCouponRule(param) {
  return Http.post("/admin/setting/rule/coupon/update", param);
}

// 积分兑换规则设置

export function listPointsRule(param) {
  return Http.post("/admin/setting/rule/points/list", param);
}

export function addPointsRule(param) {
  return Http.post("/admin/setting/rule/points/add", param);
}

export function modifyPointsRule(param) {
  return Http.post("/admin/setting/rule/points/update", param);
}

// 默认推荐地址设置
export function listRecommendAddress(param) {
  return Http.post("/admin/setting/recommend/address/list", param);
}

export function addRecommendAddress(param) {
  return Http.post("/admin/setting/recommend/address/add", param);
}

export function modifyRecommendAddress(param) {
  return Http.post("/admin/setting/recommend/address/update", param);
}

export function removeRecommendAddress(param) {
  return Http.post("/admin/setting/recommend/address/delete", param);
}

// 查询高速路口
export function listHighways(pathId) {
  return Http.get("/admin/setting/highways/list", { pathId, _useCache: true });
}

export function listAllHighway() {
  return Http.get("/admin/setting/all/highways", { _useCache: true });
}

// 隐号设置
export function listPrinum(param) {
  return Http.post("/admin/prinum/list", param);
}

export function addPrinum(param) {
  // return Http.post('/example/vue/add',param);
  return Http.post("/admin/prinum/add", param);
}

export function modifyPrinum(param) {
  // return Http.post('/example/vue/edit',param);
  return Http.post("/admin/prinum/update", param);
}

export function delPrinum(param) {
  // return Http.post('/example/vue/del',param);
  return Http.get("/admin/prinum/del", param);
}

// 系统设置
export function getSystemSetting() {
  return Http.get(`/admin/setting/system/list`);
}

// 新增或修改车队
export function driverTeamSaveOrUpdate(param) {
  return Http.post(`/admin/setting/driver_team/saveorupdate`, param);
}

// 查询最近不加价的点
export function getNearestAddress(param) {
  return Http.post(`/admin/order/nearest_address`, param);
}

// 创建微信菜单
export function creatWxMenu(menuJson) {
  return Http.post("/admin/setting/createmenu", { menuJson });
}

// 退款规则设置
export function listRefundRule(param) {
  return Http.post("/admin/setting/rule/refund/list", param);
}

export function addRefundRule(param) {
  return Http.post("/admin/setting/rule/refund/add", param);
}

export function updateRefundRule(param) {
  return Http.post("/admin/setting/rule/refund/update", param);
}

export function delRefundRule(param) {
  return Http.post("/admin/setting/rule/refund/del", param);
}

// 发车标准
export function listDepartStandard(param) {
  return Http.post("/admin/setting/path/config/depart_standard/list", param);
}

export function removeDepartStandard(param) {
  return Http.post("/admin/setting/path/config/depart_standard/del", param);
}

export function saveOrUpdateDepartStandard(param) {
  return Http.post("/admin/setting/path/config/depart_standard/save_update", param);
}

// 规则文本
export function delRuleDescList(param) {
  return Http.post("/admin/setting/rule/desc/del", param);
}

export function saveOrUpdateRuleDescList(param) {
  return Http.post("/admin/setting/rule/desc/saveorupdate", param);
}

export function getRuleDescList(param) {
  return Http.post("/admin/setting/rule/desc/list", param);
}

export function saveOrUpdateVehicleTypeConfig(param) {
  return Http.post("/admin/vehicle/vehicle_type_config/save_update", param);
}

export function deleteVehicleTypeConfig(param) {
  return Http.post("/admin/vehicle/vehicle_type_config/delete", param);
}

export function listVehicleTypeConfig() {
  return Http.post("/admin/vehicle/vehicle_type_config/list", {});
}

// 质检
export function listQcSetting(param) {
  return Http.post("/admin/qc_setting/list", param);
}

export function saveOrUpdateQcSetting(param) {
  return Http.post("/admin/qc_setting/save_or_update", param);
}

export function delQcSetting(param) {
  return Http.post("/admin/qc_setting/del", param);
}

// 获取媒体资源列表
export function listMediaResource(param) {
  return Http.post("/admin/setting/media/resource/list", param);
}

// 保存或更新媒体资源
export function saveOrUpdateMediaResource(param) {
  return Http.post("/admin/setting/media/resource/saveorupdate", param);
}

// 删除媒体资源
export function deleteMediaResource(param) {
  return Http.post("/admin/setting/media/resource/del", param);
}
