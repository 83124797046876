<template>
  <div id="app-container">
    <ErrorPage v-if="invalidRoute" />
    <div v-else id="app">
      <div class="nav">
        <Navigation />
      </div>
      <div class="content">
        <Header />

        <div class="main-content">
          <transition name="fade-transform" mode="out-in">
            <router-view></router-view>
          </transition>
        </div>
      </div>
    </div>

    <el-backtop target=".main-content"></el-backtop>
    <audio style="display: none" controls="controls" id="video">
      <source src="./assets/video/tip.wav" />
    </audio>

    <driver-location ref="driverMap" />
    <passenger-location-map ref="passengerMap" />
    <dispatch-map ref="dispatchMap" />
    <route-map ref="routeMap" />
    <shunt-map ref="shuntMap" />
    <book-address-map ref="bookAddressMap" />
    <route-plan ref="routePlan" />
    <location-select-map ref="locationSelectMap" />
    <change-order-modal ref="changeOrderModal" />
    <monitor-modal ref="monitorModal" />
    <punish-modal ref="punishModal" />
    <img-mosaic ref="imgMosaic" />
    <auto-dispatch ref="autoDispatch" />
    <route-alarm ref="routeAlarm" />
  </div>
</template>

<script>
import DriverLocation from "@/components/DriverLocationMap";
import Header from "./pages/Header";
import Navigation from "./pages/Navigation";
import PassengerLocationMap from "@/components/PassengerLocationMap";
import DispatchMap from "@/components/DispatchMap";
import LocationSelectMap from "@/components/LocationSelectMap";
import RouteMap from "@/components/RouteMap";
import ShuntMap from "@/components/ShuntMap";
import BookAddressMap from "@/components/BookAddressMap";
import RoutePlan from "@/components/RoutePlan";
import ErrorPage from "./404";
import ChangeOrderModal from "@/components/ChangeOrderModal";
import MonitorModal from "@/components/Monitor";
import PunishModal from "@/components/PunishModal";
import ImgMosaic from "@/components/ImgMosaic";
import AutoDispatch from "@/components/AutoDispatch";
import RouteAlarm from "@/components/RouteAlarm";

import { BaseDataService, CommonService, SocketService, PushNoticeService } from "@/service";
import {
  SHOW_DRIVER_LOCATION,
  SHOW_PASSENGER_MAP,
  SHOW_DISPATCH_MAP,
  SHOW_ROUTE_MAP,
  SHOW_DRIVER_SPEC_LOCATION,
  SHOW_SHUNT_MAP,
  SHOW_BOOK_ADDRESS_MAP,
  SHOW_REALTIME_LOCATION,
  SHOW_ROUTE_PLAN,
  SHOW_LOCATION_SELECT_MAP,
  SHOW_CHANGE_ORDER_MODAL,
  SHOW_REFUND_ORDER_MODAL,
  SHOW_MONITOR_MODAL,
  SHOW_PUNISH_DIAG,
  SHOW_EDIT_IMG_DIAG,
  SHOW_AUTO_DISPATCH_MODAL,
  SHOW_ROUTE_ALARM_MODAL,
} from "@/core/const/global-event";
import store from "@/store";
import { ADD_GLOBAL_DATA } from "@/store/mutation-types";
import { LocalStorage } from "@/util";
import config from "@/config/config";
import { USER_INFO } from "@/core/const/global-const";

export default {
  name: "app",
  components: {
    Header,
    Navigation,
    ErrorPage,
    PassengerLocationMap,
    DispatchMap,
    RouteMap,
    ShuntMap,
    BookAddressMap,
    RoutePlan,
    LocationSelectMap,
    ChangeOrderModal,
    MonitorModal,
    PunishModal,
    ImgMosaic,
    DriverLocation,
    AutoDispatch,
    RouteAlarm,
  },
  events: {
    [SHOW_DRIVER_LOCATION](driverId) {
      this.$refs.driverMap.showLocation(driverId);
    },

    [SHOW_DRIVER_SPEC_LOCATION](item) {
      this.$refs.driverMap.showSpecLocation(item);
    },

    [SHOW_REALTIME_LOCATION](item) {
      this.$refs.driverMap.showRealTimeLocation(item);
    },

    [SHOW_PASSENGER_MAP](...args) {
      this.$refs.passengerMap.show(...args);
    },

    [SHOW_DISPATCH_MAP](...args) {
      this.$refs.dispatchMap.show(...args);
    },

    [SHOW_ROUTE_MAP](...args) {
      this.$refs.routeMap.show(...args);
    },

    [SHOW_SHUNT_MAP](...args) {
      this.$refs.shuntMap.show(...args);
    },

    [SHOW_BOOK_ADDRESS_MAP](...args) {
      this.$refs.bookAddressMap.show(...args);
    },

    [SHOW_ROUTE_PLAN](...args) {
      this.$refs.routePlan.show(...args);
    },

    [SHOW_LOCATION_SELECT_MAP](...args) {
      this.$refs.locationSelectMap.show(...args);
    },

    [SHOW_CHANGE_ORDER_MODAL](...args) {
      this.$refs.changeOrderModal.show(...args);
    },

    [SHOW_REFUND_ORDER_MODAL](...args) {
      this.$refs.changeOrderModal.showRefundConfim(...args);
    },

    [SHOW_MONITOR_MODAL](...args) {
      this.$refs.monitorModal.show(...args);
    },

    [SHOW_PUNISH_DIAG](...args) {
      this.$refs.punishModal.show(...args);
    },

    [SHOW_EDIT_IMG_DIAG](...args) {
      this.$refs.imgMosaic.show(...args);
    },

    [SHOW_AUTO_DISPATCH_MODAL](...args) {
      this.$refs.autoDispatch.show(...args);
    },

    [SHOW_ROUTE_ALARM_MODAL](...args) {
      this.$refs.routeAlarm.show(...args);
    },
  },
  computed: {
    invalidRoute() {
      return !this.$route.matched || this.$route.matched.length === 0;
    },
  },

  created() {
    const isLogin = CommonService.isLogin();
    // 如果已经登录，重新进行websocket连接
    if (isLogin) {
      this.init();
    } else {
      this.$router.push({
        name: "Login",
      });
    }
  },

  data() {
    return {
      showLogin: false,
      form: {},
      rules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      timer: null,
    };
  },
  methods: {
    init() {
      // 不需要连接 websocket的页面
      const page = this.$route.name;
      const whiteList = ["RouteQc", "VideoMonitor"];
      if (!whiteList.includes(page)) {
        this.subscribeAlarm();
      }

      // 获取基础数据
      BaseDataService.getCacheData();

      // 初始化用户角色
      this.initRole();

      // 维持session
      this.heartbeatCheck();
    },

    heartbeatCheck() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    },

    subscribeAlarm() {
      const noNeedAlarm = ["shixiaofen"];
      const user = LocalStorage.get(USER_INFO);
      if (!noNeedAlarm.includes(user.username)) {
        SocketService.subscribeTopic("/topic/all", (data) => {
          PushNoticeService.handlePushNotice(data);
        });
      }
    },

    initRole() {
      const user = LocalStorage.get(USER_INFO);
      // 判断当期角色是否包括“高级管理”
      const roles = user.roles.map((t) => t.id) || [];
      if (roles.indexOf(config.manageId) > -1 || user.name === "admin") {
        store.commit(ADD_GLOBAL_DATA, {
          key: "isManager",
          value: true,
        });
      }
    },
  },
};
</script>

<style lang="less" type="text/less" scoped>
#app-container {
  height: 100%;
}

#app {
  display: flex;
  height: 100%;

  .nav {
    width: 220px;
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    overflow-x: auto;

    .main-content {
      flex: 1;
      height: 100%;
      overflow-x: hidden;
    }
  }
}
</style>
