<template>
    <div class="root-box">
        <div class="query-container">
            <el-form size="small" :inline="true">
                <el-form-item label="活动名称：">
                    <el-input v-model="params.name"></el-input>
                </el-form-item>

                <el-form-item label="活动类型：">
                    <el-select v-model="params.type" placeholder="请选择活动类型">
                        <el-option v-for="item in typeOptions" :key="item.value" :label="item.label"
                            :value="item.value"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-button style="width: 100px; margin-right: 20px" type="primary" @click="query()">查询</el-button>
                </el-form-item>

                <el-form-item style="float: right">
                    <el-button style="width: 100px" type="primary" @click="openForm()">添加活动</el-button>
                </el-form-item>
            </el-form>
        </div>

        <div class="table">
            <el-table :data="list" stripe max-height="600">
                <el-table-column label="序号" type="index" show-overflow-tooltip width="50"></el-table-column>
                <el-table-column prop="name" label="活动名称"></el-table-column>
                <el-table-column prop="coverImgUrl" label="活动封面">
                    <template slot-scope="scope">
                        <file-preview :value="scope.row.coverImgUrl"></file-preview>
                    </template>
                </el-table-column>
                <el-table-column prop="tag" label="标记"></el-table-column>
                <el-table-column prop="type" label="活动类型">
                    <template slot-scope="scope">
                        {{ scope.row.type | enumTransfer(typeOptions) }}
                    </template>
                </el-table-column>
                <el-table-column prop="couponAmount" label="优惠券金额"></el-table-column>
                <el-table-column prop="validityPeriod" label="领取有效期"></el-table-column>
                <el-table-column prop="receiveBefore" label="领取截止时间"></el-table-column>
                <el-table-column prop="voucherCode" label="优惠口令"></el-table-column>
                <el-table-column prop="createTime" label="创建时间"></el-table-column>

                <el-table-column fixed="right" label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="openForm(scope.row)">修改</el-button>
                        <el-button type="text" size="small" @click="deleteCampaign(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <el-dialog :visible.sync="visible" width="600px" title="活动信息" :close-on-click-modal="false">
            <el-form ref="form" :model="form" label-width="150px" :size="size" :rules="rules">
                <el-form-item label="活动名称：" prop="name">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>

                <el-form-item label="活动标记：" prop="tag">
                    <el-input v-model="form.tag"></el-input>
                </el-form-item>

                <el-form-item label="活动描述：" prop="description">
                    <el-input type="textarea" v-model="form.description"></el-input>
                </el-form-item>

                <el-form-item label="活动规则：" prop="ruleType">
                    <el-select v-model="form.ruleType" placeholder="请选择活动规则">
                        <el-option v-for="item in ruleDescTypeOpts" :key="item.value" :label="item.label"
                            :value="item.value"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="活动页面：" prop="page">
                    <el-input v-model="form.page"></el-input>
                </el-form-item>

                <el-form-item label="封面图片：" prop="coverImgUrl">
                    <file-upload v-model="form.coverImgUrl" filePath="/miniapp/img/action/daily" bucket="yxmgt-public"
                        uploadType="button"></file-upload>
                </el-form-item>

                <el-form-item label="背景图片：" prop="backgroundImgUrl">
                    <file-upload v-model="form.backgroundImgUrl" filePath="/miniapp/img/action/daily"
                        bucket="yxmgt-public" uploadType="button"></file-upload>
                </el-form-item>

                <el-form-item label="背景颜色：" prop="backgroundColor">
                    <el-color-picker v-model="form.backgroundColor"></el-color-picker>
                </el-form-item>

                <el-form-item label="登录提示：" prop="loginTip">
                    <el-input v-model="form.loginTip"></el-input>
                </el-form-item>

                <el-form-item label="活动类型：" prop="type">
                    <el-select v-model="form.type" placeholder="请选择活动类型">
                        <el-option v-for="item in typeOptions" :key="item.value" :label="item.label"
                            :value="item.value"></el-option>
                    </el-select>
                </el-form-item>

                <!-- 优惠券活动特有字段 -->
                <template v-if="form.type === 'COUPON'">
                    <el-form-item label="优惠券金额：" prop="couponAmount">
                        <el-input-number v-model="form.couponAmount" :min="0"></el-input-number>
                    </el-form-item>

                    <el-form-item label="优惠券有效期(天)：" prop="expireDays">
                        <el-input-number v-model="form.expireDays" :min="1"></el-input-number>
                    </el-form-item>

                    <el-form-item label="优惠券路线：" prop="pathIds">
                        <el-select v-model="form.pathIds" placeholder="请选择优惠券路线" multiple style="width: 400px">
                            <el-option v-for="item in pathOpts" :key="item.id" :label="item.name"
                                :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="领取有效期(天)：" prop="validityPeriod">
                        <el-input-number v-model="form.validityPeriod" :min="1"></el-input-number>
                    </el-form-item>

                    <el-form-item label="领取截止时间：" prop="receiveBefore">
                        <el-date-picker v-model="form.receiveBefore" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择日期时间"></el-date-picker>
                    </el-form-item>

                </template>

                <!-- 优惠口令活动特有字段 -->
                <template v-if="form.type === 'VOUCHER_CODE'">
                    <el-form-item label="优惠口令：" prop="voucherCode">
                        <el-input v-model="form.voucherCode"></el-input>
                    </el-form-item>
                </template>



            </el-form>

            <span slot="footer">
                <el-button type="warning" :size="size" @click="saveOrUpdate">保存</el-button>
                <el-button type="info" :size="size" @click="visible = false">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import FilePreview from '@/components/FilePreview';
import { listCampaign, saveOrUpdateCampaign, deleteCampaign } from '@/api';

export default {
    components: {
        FilePreview
    },
    created() {
        this.query();
    },
    computed: {
        ...mapState({
            size: state => state.btnSize,
            ruleDescTypeOpts: state => state.enumMap['RULE_DESC_TYPE'],
            pathOpts: (state) => state.pathList,
        })
    },
    data() {
        return {
            visible: false,
            list: [],
            form: {},
            params: {},
            typeOptions: [
                { label: '优惠券', value: 'COUPON' },
                { label: '优惠口令', value: 'VOUCHER_CODE' },
                { label: '积分兑换', value: 'EXCHANGE_POINTS' },
            ],
            rules: {
                name: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                ],
                type: [
                    { required: true, message: '请选择活动类型', trigger: 'change' },
                ],
                ruleType: [
                    { required: true, message: '请选择活动规则', trigger: 'change' },
                ],
                page: [
                    { required: true, message: '请输入活动页面', trigger: 'blur' },
                ],
                validityPeriod: [
                    {
                        validator: (rule, value, callback) => {
                            if (this.form.type === 'COUPON' && !value && !this.form.receiveBefore) {
                                callback(new Error('请至少设置领取有效期或领取截止时间'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                receiveBefore: [
                    {
                        validator: (rule, value, callback) => {
                            if (this.form.type === 'COUPON' && !value && !this.form.validityPeriod) {
                                callback(new Error('请至少设置领取有效期或领取截止时间'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'change'
                    }
                ],
                // 优惠券活动特有验证规则
                couponAmount: [
                    {
                        required: true, message: '请输入优惠券金额', trigger: 'blur', validator: (rule, value, callback) => {
                            if (this.form.type === 'COUPON' && !value) {
                                callback(new Error('请输入优惠券金额'));
                            } else {
                                callback();
                            }
                        }
                    },
                ],
                expireDays: [
                    {
                        required: true, message: '请输入优惠券有效期', trigger: 'blur', validator: (rule, value, callback) => {
                            if (this.form.type === 'COUPON' && !value) {
                                callback(new Error('请输入优惠券有效期'));
                            } else {
                                callback();
                            }
                        }
                    },
                ],
                // 优惠口令活动特有验证规则
                voucherCode: [
                    {
                        required: true, message: '请输入优惠口令', trigger: 'blur', validator: (rule, value, callback) => {
                            if (this.form.type === 'VOUCHER_CODE' && !value) {
                                callback(new Error('请输入优惠口令'));
                            } else {
                                callback();
                            }
                        }
                    },
                ],
            },
        };
    },
    methods: {
        query() {
            listCampaign(this.params).then(res => {
                this.list = res.data;
            });
        },
        openForm(item) {
            this.visible = true;
            if (item) {
                this.form = { ...item };
            } else {
                this.form = {};
            }
        },
        saveOrUpdate() {
            this.$refs.form.validate((valid) => {
                if (!valid) {
                    return;
                }
                this.visible = false;
                saveOrUpdateCampaign(this.form).then(() => {
                    this.$message.success(this.form.id ? '修改成功' : '添加成功');
                    this.query();
                });
            });
        },
        deleteCampaign(item) {
            this.$confirm('确认删除该活动吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteCampaign(item).then(() => {
                    this.$message.success('删除成功');
                    this.query();
                });
            });
        }
    }
};
</script>

<style scoped>
.root-box {
    padding: 10px;
}

.query-container {
    margin-bottom: 20px;
}

.table {
    margin-bottom: 20px;
}
</style>
