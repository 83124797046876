import { Http } from "@/util";

// 保存或更新司机招聘信息
export function saveOrUpdateHire(param) {
  return Http.post("/admin/driver/hire/save_or_update", param);
}

// 获取司机招聘列表
export function getHireList(param) {
  return Http.post("/admin/driver/hire/list", param);
}

// 获取司机联系记录列表
export function getContactList(hireDriverId) {
  return Http.get("/admin/driver/hire/contact/list", { hireDriverId });
}

// 添加司机联系记录
export function addDriverContact(param) {
  return Http.post("/admin/driver/hire/contact/save", param);
}

// 重新上传录音
export function reSaveRecord2Oss(contactId) {
  return Http.get("/admin/driver/hire/contact/re_save_record", { contactId });
}
