<template>
    <div class="hire-container">
        <!-- 搜索区域 -->
        <div class="search-area">
            <el-form :inline="true" :model="searchForm" size="small">
                <el-form-item>
                    <el-input v-model="searchForm.name" placeholder="姓名" clearable style="width: 180px"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="searchForm.telephone" placeholder="手机电话" clearable
                        style="width: 180px"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-date-picker v-model="searchForm.startTime" type="date" placeholder="开始日期" clearable
                        value-format="yyyy-MM-dd 00:00:00" style="width: 180px" @change="handleSearch"></el-date-picker>
                    <span style="margin: 0 8px">至</span>
                    <el-date-picker v-model="searchForm.endTime" type="date" placeholder="结束日期" clearable
                        value-format="yyyy-MM-dd 23:59:59" style="width: 180px" @change="handleSearch"></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="searchForm.followUpPersonInChargeId" placeholder="跟进负责人" clearable>
                        <el-option v-for="item in driverAdminList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="searchForm.leadReporter" placeholder="线索上报人" clearable
                        style="width: 180px"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="searchForm.leadSource" placeholder="线索渠道" clearable
                        style="width: 180px"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSearch">查询</el-button>
                    <el-button type="primary" @click="addLead">添加线索</el-button>
                </el-form-item>
            </el-form>
        </div>

        <!-- 标签页 -->
        <el-tabs v-model="activeTab" @tab-click="handleTabClick">
            <el-tab-pane label="全部" name="all"></el-tab-pane>
            <el-tab-pane v-for="item in statusOpts" :key="item.value" :label="item.label" :name="item.value">
            </el-tab-pane>
        </el-tabs>

        <!-- 表格 -->
        <el-table :data="tableData" border style="width: 100%" max-height="550">
            <el-table-column type="index" label="序号" width="50"></el-table-column>
            <el-table-column prop="name" label="姓名"></el-table-column>
            <el-table-column prop="telephone" label="手机号" width="120"></el-table-column>
            <el-table-column prop="age" label="年龄"></el-table-column>
            <el-table-column prop="contactTimes" label="联系次数"></el-table-column>
            <el-table-column prop="hometown" label="籍贯"></el-table-column>
            <el-table-column prop="residence" label="居住地"></el-table-column>
            <el-table-column prop="maritalStatus" label="婚姻状态"></el-table-column>
            <el-table-column prop="childBearingStatus" label="生育情况"></el-table-column>
            <el-table-column prop="drivingLicenseType" label="驾照类型"></el-table-column>
            <el-table-column prop="drivingAge" label="驾龄"></el-table-column>
            <el-table-column prop="leadSource" label="线索渠道" width="100"></el-table-column>
            <el-table-column prop="followUpPersonInCharge" label="跟进负责人"></el-table-column>
            <el-table-column prop="leadReporter" label="线索上报人"></el-table-column>
            <el-table-column prop="leadReportTime" label="线索上报时间" width="120"></el-table-column>
            <el-table-column prop="lastFollowUpTime" label="最后跟进时间" width="120"></el-table-column>
            <el-table-column prop="remark" label="备注">
                <template slot-scope="scope">
                    <el-tooltip v-if="scope.row.remark && scope.row.remark.length > 10" :content="scope.row.remark"
                        placement="top">
                        <span>{{ scope.row.remark.slice(0, 10) }}...</span>
                    </el-tooltip>
                    <span v-else>{{ scope.row.remark }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="currentStatus" label="当前状态">
                <template slot-scope="scope">
                    <span>{{ scope.row.currentStatus | enumTransfer(statusOpts) }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="operate" label="操作" width="120" fixed="right">
                <template slot-scope="scope">
                    <div class="operate-btn">
                        <div>
                            <el-button type="text" size="small" @click="handleEdit(scope.row)">修改信息</el-button>
                        </div>
                        <div>
                            <el-button type="text" size="small" @click="addContact(scope.row)">添加联系记录</el-button>
                        </div>
                        <div>
                            <el-button type="text" size="small" @click="handleDetail(scope.row)">查看联系记录</el-button>
                        </div>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <!-- 分页 -->
        <div class="page">
            <el-pagination :current-page="currentPage" :page-size="pageSize" :total="total"
                @size-change="handleSizeChange" @current-change="handleCurrentChange"
                layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>

        <!-- 表格部分后添加弹框组件 -->
        <el-dialog :title="form.id ? '编辑线索' : '新增线索'" :visible.sync="dialogVisible" width="60%">
            <el-form :model="form" :rules="rules" ref="form" label-width="120px" :size="size">
                <el-row :gutter="20">
                    <el-col :span="6">
                        <el-form-item label="姓名" prop="name">
                            <el-input v-model="form.name"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="手机号" prop="telephone">
                            <el-input v-model="form.telephone"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="面试时间" prop="interviewTime">
                            <el-date-picker v-model="form.interviewTime" type="datetime" placeholder="选择面试时间"
                                value-format="yyyy-MM-dd HH:mm:ss" class="w-full"></el-date-picker>
                        </el-form-item>
                    </el-col>

                </el-row>

                <el-row :gutter="20">
                    <el-col :span="6">
                        <el-form-item label="年龄" prop="age">
                            <el-input-number v-model="form.age" class="w-full"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="籍贯" prop="hometown">
                            <el-input v-model="form.hometown"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="现居住地" prop="residence">
                            <el-input v-model="form.residence"></el-input>
                        </el-form-item>
                    </el-col>

                </el-row>
                <el-row :gutter="20">
                    <el-col :span="6">
                        <el-form-item label="婚姻状态" prop="maritalStatus">
                            <el-select v-model="form.maritalStatus" placeholder="请选择" class="w-full">
                                <el-option v-for="item in maritalStatusOpts" :key="item" :label="item"
                                    :value="item"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="生育情况" prop="childBearingStatus">
                            <el-input v-model="form.childBearingStatus"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="驾照类型" prop="drivingLicenseType">
                            <el-select v-model="form.drivingLicenseType" placeholder="请选择" class="w-full">
                                <el-option v-for="item in drivingLicenseTypeOpts" :key="item" :label="item"
                                    :value="item"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="驾龄" prop="drivingAge">
                            <el-input-number v-model="form.drivingAge" :min="0" class="w-full"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="20">

                    <el-col :span="6">
                        <el-form-item label="线索渠道" prop="leadSource">
                            <el-select v-model="form.leadSource" filterable allow-create default-first-option
                                placeholder="请选择" class="w-full">
                                <el-option v-for="item in leadSourceOpts" :key="item" :label="item" :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="跟进负责人" prop="followUpPersonInCharge">
                            <el-select v-model="form.followUpPersonInChargeId" placeholder="请选择" class="w-full"
                                @change="handleFollowUpChange">
                                <el-option v-for="item in driverAdminList" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="6">
                        <el-form-item label="线索上报人" prop="leadReporter">
                            <el-input v-model="form.leadReporter"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="6">
                        <el-form-item label="当前状态" prop="currentStatus">
                            <el-select v-model="form.currentStatus" placeholder="请选择" class="w-full">
                                <el-option v-for="item in statusOpts" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="备注" prop="remark">
                            <el-input v-model="form.remark" type="textarea" :rows="2"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>


            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" :size="size">取 消</el-button>
                <el-button type="primary" @click="handleSubmit" :size="size">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="添加联系记录" :visible.sync="contactVisible" width="500px">
            <el-form :model="contactForm" :rules="contactRules" ref="contactForm" label-width="100px" :size="size">


                <el-form-item label="联系类型" prop="contactType">
                    <el-select v-model="contactForm.contactType" placeholder="请选择" class="w-full">
                        <el-option v-for="item in contactTypes" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="是否仅备注" prop="isRemark">
                    <el-select v-model="contactForm.isRemark" placeholder="请选择" class="w-full">
                        <el-option :label="'是'" :value="true"></el-option>
                        <el-option :label="'否'" :value="false"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="内容" prop="content">
                    <el-input type="textarea" v-model="contactForm.content" :rows="4"></el-input>
                </el-form-item>
                <el-form-item label="附件" prop="files">
                    <multiple-file-upload v-model="contactForm.files"
                        :filePath="`/partner/hire/${this.contactForm.driverId}`" />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="contactVisible = false" :size="size">取 消</el-button>
                <el-button type="primary" @click="submitContact" :size="size">确 定</el-button>
            </div>
        </el-dialog>

        <el-drawer title="联系记录" :visible.sync="drawerVisible" direction="rtl" size="50%">
            <div class="contact-list" v-loading="loading">
                <el-timeline>
                    <el-timeline-item v-for="(item, index) in contactList" :key="item.id" :timestamp="item.createTime"
                        placement="left" :color="item.isSponsor ? '#0bbd87' : '#F56C6C'">
                        <el-card>
                            <div class="contact-item">
                                <div class="contact-header">
                                    <span v-if="item.index">第{{ item.index }}次联系</span>
                                    <span v-else>添加信息：</span>
                                    <span class="contact-person">{{ item.contactPerson }}</span>
                                    <span class="contact-type">{{ item.contactType }}</span>
                                    <span>{{ item.isSponsor ? '主动联系' : '被动联系' }}</span>
                                </div>

                                <div class="contact-time">
                                    <span>联系时间：</span>
                                    <span>{{ item.ctime }}</span>
                                </div>
                                <div class="contact-content">
                                    <div>联系内容：</div>
                                    <div>{{ item.content }}</div>

                                </div>

                                <div v-if="item.files && item.files.length === 1 && item.files[0] === 'null'">
                                    <el-button type="text"
                                        @click="reSaveRecord(item.id, index)">录音文件下载失败，点击重新下载录音文件</el-button>
                                </div>
                                <div class="contact-files" v-else-if="item.files && item.files.length">
                                    <div v-for="(file, index) in item.files" :key="index" class="file-item">
                                        <file-preview :value="file" />
                                    </div>
                                </div>
                            </div>
                        </el-card>
                    </el-timeline-item>
                </el-timeline>
                <el-empty v-if="!contactList.length" description="暂无联系记录"></el-empty>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { getHireList, saveOrUpdateHire, getDriverAdminList, getContactList, addDriverContact, reSaveRecord2Oss } from '@/api';
import { LocalStorage, ParamUtil } from "@/util";
import FilePreview from '@/components/FilePreview';
import {
    USER_INFO,

} from "@/core/const/global-const";

export default {
    name: 'DriverHire',
    components: {
        FilePreview
    },
    computed: {
        ...mapState({
            size: state => state.btnSize,
            statusOpts: state => state.enumMap['DRIVER_RECRUITMENT_STATUS'],
        }),
    },
    data() {
        return {
            searchForm: {

            },
            currentPage: 1,
            pageSize: 50,
            total: 0,
            activeTab: 'all',
            tableData: [], // 这里填充表格数据
            dialogVisible: false,
            leadSourceOpts: ["监利招聘网",
                "监利直聘网",
                "抖音号",
                '司机APP',
                "司机推荐",
                "微信推广",
                "客服提交",
                "视频号",
                "过往招聘",
                "内部推荐",
                "公众号",
                "其他"],
            contactTypes: ["电话", "面谈", "微信"],
            driverAdminList: [],
            maritalStatusOpts: ["未婚", "已婚", "离异"],
            drivingLicenseTypeOpts: ["A", "B", "C"],
            form: {},
            rules: {
                name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
                telephone: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { pattern: /^1\d{10}$/, message: '请输入正确的手机号', trigger: 'blur' }
                ],
                currentStatus: [{ required: true, message: '请选择当前状态', trigger: 'change' }],
                followUpPersonInChargeId: [{ required: true, message: '请选择跟进负责人', trigger: 'change' }],
                leadSource: [{ required: true, message: '请选择线索渠道', trigger: 'change' }],
            },
            contactVisible: false,
            contactForm: {
                content: '',
                files: [],
                hireDriverId: null
            },
            contactRules: {
                content: [
                    { required: true, message: '请输入联系内容', trigger: 'blur' }
                ],
                contactType: [{ required: true, message: '请选择联系类型', trigger: 'change' }],
            },
            drawerVisible: false,
            contactList: [],
            loading: false,
        };
    },

    mounted() {
        this.handleSearch();
        this.listDriverAdmin();
    },

    methods: {


        handleSearch() {

            const queryParam = this.activeTab === 'all' ? this.searchForm : { ...this.searchForm, currentStatus: this.activeTab };
            const params = {
                page: {
                    current: this.currentPage,
                    size: this.pageSize
                },
                param: ParamUtil.filterEmptyData(queryParam),
                startTime: this.searchForm.startTime,
                endTime: this.searchForm.endTime,
            };
            getHireList(params).then(res => {
                const { records = [], current = 1, total = 0 } = res.data || {};
                this.tableData = records;
                this.currentPage = current;
                this.total = total;
            });
        },

        reSaveRecord(contactId, index) {
            reSaveRecord2Oss(contactId).then((res) => {
                const newData = res.data;
                this.$set(this.contactList, index, newData);
            });
        },

        listDriverAdmin() {
            getDriverAdminList({}).then(res => {
                this.driverAdminList = res.data;
            });
        },

        handleSizeChange(size) {
            this.pageSize = size;
            this.handleSearch();
        },

        handleCurrentChange(current) {
            this.currentPage = current;
            this.handleSearch();
        },


        handleTabClick() {
            this.currentPage = 1;
            this.handleSearch();
        },

        addLead() {
            this.dialogVisible = true;
            this.form = {};
            this.$refs.form.resetFields();
        },

        handleSubmit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    saveOrUpdateHire(this.form).then(() => {
                        this.dialogVisible = false;
                        this.handleSearch();
                    }).catch((err) => {
                        if (err && err.message === '已有该司机信息，请勿重复录入') {
                            this.dialogVisible = false;
                            // 定位到已有的司机信息
                            this.$set(this.searchForm, 'telephone', this.form.telephone);
                            this.activeTab = 'all';
                            this.handleSearch();
                        }
                    });
                }
            });
        },

        handleEdit(row) {
            this.dialogVisible = true;
            this.form = { ...row };
        },

        handleFollowUpChange(val) {
            this.form.followUpPersonInCharge = this.driverAdminList.find(item => item.id === val)?.name;
        },

        addContact(row) {
            this.contactVisible = true;
            this.contactForm = {
                content: '',
                files: [],
                driverId: row.id
            };

        },

        submitContact() {
            this.$refs.contactForm.validate(valid => {
                if (valid) {
                    // 获取当前登录用户
                    const userinfo = LocalStorage.get(USER_INFO) || {};
                    const param = {
                        ...this.contactForm,
                        files: this.contactForm.files.map(item => item.url),
                        isSponsor: true,
                        contactPerson: userinfo.name,
                        contactPersonId: userinfo.id,
                    };
                    addDriverContact(param).then(() => {
                        this.$message.success('添加联系记录成功');
                        this.contactVisible = false;
                    });
                }
            });
        },

        handleDetail(row) {
            this.drawerVisible = true;
            this.loading = true;
            getContactList(row.id).then(res => {
                this.contactList = res.data || [];
            }).finally(() => {
                this.loading = false;
            });
        },
    },

};
</script>

<style scoped lang="less">
.hire-container {
    padding: 10px;

    .search-area {
        background-color: rgba(0, 0, 0, 0.1);
        padding: 20px 20px 0;
        margin-bottom: 10px;
    }

    .w-full {
        width: 100%;
    }

    .page {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
    }

    .contact-list {
        padding: 20px;

        .contact-header {
            margin: 10px 0;
            color: #409EFF;

            span {
                margin-right: 10px;
            }
        }

        .contact-time {
            margin-bottom: 10px;
        }

        .contact-content {
            display: flex;
            margin-bottom: 20px;

        }

        .contact-files {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;


        }
    }

    .operate-btn .el-button {
        padding-top: 0;
        padding-bottom: 0;
    }
}
</style>
