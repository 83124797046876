<template>
  <div class="root">
    <div class="condition">
      <div class="title">
        今日司机
        <span v-if="list.length > 0">（共{{ list.length }}辆）</span>
      </div>

      <el-form ref="form" size="small" class="form" label-width="90px">
        <el-form-item label="关键字：">
          <el-input placeholder="司机编号/车牌号" v-model="inputKey" clearable style="width: 205px">
            <el-button slot="append" icon="el-icon-search" @click="query"></el-button>
          </el-input>
        </el-form-item>

        <el-form-item label="路线：">
          <el-select v-model="pathIds" placeholder="请选择路线" @change="query" multiple collapse-tags clearable>
            <el-option v-for="item in pathOpts" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="汽车状态：" v-if="pathIds.length > 0">
          <el-select v-model="status" placeholder="请选择" style="width: 205px" clearable @change="query">
            <el-option v-for="item in schedulingStatus" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="定位来源：">
          <el-select v-model="vendor" placeholder="请选择定位数据来源" @change="query" clearable>
            <el-option v-for="item in positionOpts" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="自动刷新：">
          <el-checkbox v-model="checked">{{ checked ? "开启" : "关闭" }}</el-checkbox>
        </el-form-item>
      </el-form>

      <div class="list" v-infinite-scroll="loadMore" style="overflow: auto">
        <div v-for="item in list" class="car" @click="showCar(item)" style="cursor: pointer" :key="item.telephone">
          <div class="img" :class="{
            c0: !!item.scheduling,
            c1: item.scheduling.status === 1,
            c2: item.scheduling.status === 2,
            c3: item.scheduling.status === 3,
          }"></div>
          <div class="name">{{ item.driver.no }}</div>
          <div class="name">{{ item.driver.name }}</div>
          <!-- <div class="tel">{{item.telephone | encryptTel}}</div> -->
          <!-- <div class="tel">{{ item.driver.telephone }}</div> -->
          <div class="car-no">{{ item.driver.vehicleNo }}</div>
          <div class="car-track" @click="trackReplaying(item)">轨迹回放</div>
          <!-- <div class="car-track">
            <el-button type="primary" size="mini" @click="trackReplaying">轨迹回放</el-button>
          </div>-->
        </div>
      </div>
    </div>

    <div class="map">
      <div id="map-driver">
        <div class="time-slider" v-show="controlIsActive">
          <el-slider v-model="controlValue" range :max="288" :format-tooltip="timeTransfer"
            :show-tooltip="false"></el-slider>
        </div>
        <div class="control-container">
          <div class="input-card" v-show="controlIsActive">
            <div class="input-card-time">{{ timeObserve }}</div>
            <div class="input-item">
              <el-radio-group v-model="radio">
                <el-radio :label="0" border size="mini">今天</el-radio>
                <el-radio :label="-1" border size="mini">昨天</el-radio>
                <el-radio :label="-2" border size="mini">前天</el-radio>
              </el-radio-group>
            </div>
            <div class="input-item">
              <input type="button" class="btn" value="查询" id="trackArr" @click="trackQuery()" />
              <input type="button" class="btn" value="开始" id="start" @click="startAnimation()" />
              <input type="button" class="btn" value="关闭" id="close" @click="closeAndQuit()" />
            </div>
          </div>
          <div class="d2" @click="showOrHidden">
            <div class="d3">
              <i :class="[controlIsActive ? 'el-icon-arrow-left' : 'el-icon-arrow-right']"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DateUtil, ParamUtil } from "@/util";
import { getAllDriverLocation, getDriverTrack } from "@/api";
import moment from "moment";

const carPng = require("../../../assets/image/car_yellow.png");
const carRedPng = require("../../../assets/image/car_red.png");
const carGreenPng = require("../../../assets/image/car_green.png");
export default {
  mounted() {
    this.initMap();
    this.query();
  },
  computed: {
    ...mapState({
      schedulingStatus: (state) => state.enumMap["SCHEDULING_STATUS"],
      pathOpts: (state) => {
        const pathList = state.pathList;
        return pathList.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
      },
    }),

    timeObserve() {
      return (
        (this.radio === 0 ? "今天" : this.radio === -1 ? "昨天" : "前天") +
        "  " +
        this.dateToString(this.controlValue[0], this.radio) +
        "-" +
        this.dateToString(this.controlValue[1], this.radio)
      );
    },
  },

  watch: {
    checked: {
      handler(val) {
        if (val) {
          this.startTimer();
        } else {
          this.stopTimer();
        }
      },
      immediate: true,
    },
  },

  beforeDestroy() {
    this.stopTimer();
  },
  data() {
    return {
      positionOpts: [
        {
          label: "最新定位",
          value: "",
        },
        {
          label: "司机端",
          value: "driver",
        },
        {
          label: "GPS设备",
          value: "jimi",
        },
      ],
      vendor: "",
      controlValue: [60, 240],
      timeInfo: [],
      radio: 0,
      pathIds: "",
      inputKey: "",
      status: null,
      currentCar: null,
      checked: false,
      list: [],
      controlIsActive: false,
      lineArr: [],
      locationStartTime: null,
      locationEndTime: null,
    };
  },

  methods: {
    initMap() {
      AMap.plugin(["AMap.MoveAnimation"], () => { });

      this.map = new AMap.Map("map-driver", {
        resizeEnable: true,
        dragEnable: true,
        zoom: 14,
      });
    },

    startTimer() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          this.query();
        }, 15 * 1000);
      }
    },
    stopTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },

    drawTrack() {

      if (this.lineArr.length) {
        this.marker = new AMap.Marker({
          map: this.map,
          position: this.lineArr[0],
          icon: "https://a.amap.com/jsapi_demos/static/demo-center-v2/car.png",
          offset: new AMap.Pixel(-13, -26),
        });

        // 绘制轨迹
        new AMap.Polyline({
          map: this.map,
          path: this.lineArr,
          showDir: true,
          strokeColor: "#28F", //线颜色
          strokeOpacity: 0.5, //线透明度
          strokeWeight: 6, //线宽
          // strokeStyle: "solid"  //线样式
        });

        const passedPolyline = new AMap.Polyline({
          map: this.map,
          strokeColor: "#AF5", //线颜色
          strokeWeight: 6, //线宽
        });

        this.marker.on("moving", function (e) {
          passedPolyline.setPath(e.passedPath);
        });
        this.map.setFitView();
      }

    },
    query() {
      const param = {
        driverNo: this.inputKey,
        status: this.status === 0 ? null : this.status,
        pathIds: this.pathIds,
        vendor: this.vendor,
      };

      getAllDriverLocation(ParamUtil.filterEmptyData(param)).then((res) => {
        const { data = [] } = res;
        this.list = data;
        this.drawMap(data);
      });
    },
    trackQuery() {
      if (!this.currentCar) {
        this.$message.error("请选择要查看回放的司机");
        return;
      }

      this.stopAnimation();
      const queryParam = {
        startTime: this.timeInfo[0],
        endTime: this.timeInfo[1],
        vendor: this.vendor ? this.vendor : "jimi",
        driverId: this.currentCar.driverId,
      };

      getDriverTrack(queryParam).then((res) => {
        const { locations = [], locationStartTime, locationEndTime } = res.data || {};
        const lineArr = [];
        locations.forEach((item) => {
          lineArr.push([+item[0], +item[1]]);
        });

        this.lineArr = lineArr;
        this.map.clearMap();
        this.drawTrack();

        this.locationStartTime = locationStartTime;
        this.locationEndTime = locationEndTime;
      });
    },
    transStatus(driverType, driverStatus) {
      if (driverStatus === 2) {
        return 3;
      } else {
        if (driverType === 1) {
          return 1;
        }
        return 2;
      }
    },
    drawMap(data = []) {
      const map = this.map;
      map.clearMap();
      const markers = [];
      const markerMap = {};
      data.forEach((item) => {
        const { driver, scheduling, gpsTime, lng, lat, vendor } = item;
        const { status } = scheduling || {};
        const time = gpsTime.substr(5);
        const locationSource = vendor === "jimi" ? "GPS" : "手机";
        // const icon = new AMap.Icon({
        //     image : 'https://webapi.amap.com/images/car.png',
        //     size : new AMap.Size(52, 26),
        // });

        // 根据状态显示颜色
        let carImg = carGreenPng;
        // 异常状态
        if (status === 3) {
          carImg = carRedPng;
        } else if (status === 2) {
          carImg = carPng;
        }

        const carIcon = new AMap.Icon({
          size: new AMap.Size(40, 40), //图标大小
          image: carImg,
          imageSize: new AMap.Size(40, 40),
        });

        if (item.lat && item.lng) {
          const marker = new AMap.Marker({
            icon: carIcon,
            position: new AMap.LngLat(lng, lat),
            draggable: true,
            label: {
              content: `<div class="car-info">  ${driver.no} | ${time} | ${locationSource}</div>`,
              direction: "top",
            },
          });
          markers.push(marker);
          markerMap[item.driverId] = marker;
        }
      });
      map.add(markers);
      map.setFitView();
      this.markerMap = markerMap;
    },
    showCar(item) {
      const marker = this.markerMap[item.driverId];
      // TODO marker动画暂不支持
      console.log(marker);
    },
    timeTransfer(index) {
      var tooltipValue = moment(DateUtil.getDayStr(0)).add(index * 5, "minutes");
      tooltipValue = moment(tooltipValue).format("HH:mm");
      return tooltipValue;
    },

    dateToString(val, date) {
      var now = moment(DateUtil.getDayStr(date)).add(val * 5, "minutes");
      now = moment(now).format("HH:mm");
      //起始时间，终点时间转化为moment对象，存入timeInfo数组
      var startTime = moment(DateUtil.getDayStr(date)).add(this.controlValue[0] * 5, "minutes");
      var endTime = moment(DateUtil.getDayStr(date)).add(this.controlValue[1] * 5, "minutes");
      this.timeInfo[0] = moment(startTime).format("YYYY-MM-DD HH:mm:ss");
      this.timeInfo[1] = moment(endTime).format("YYYY-MM-DD HH:mm:ss");

      return now;
    },
    trackReplaying(item) {
      this.currentCar = item;
      this.checked = false;
      const map = this.map;
      const marker = this.markerMap[item.driverId];
      map.setFitView(marker);
      this.showOrHidden();
      this.dateToString();
    },
    startAnimation() {
      if (this.marker && this.lineArr && this.lineArr.length) {
        this.marker.moveAlong(this.lineArr, {
          // 每一段的时长
          duration: 500,
          // JSAPI2.0 是否延道路自动设置角度在 moveAlong 里设置
          autoRotation: true,
        });
      }
    },

    stopAnimation() {
      if (this.marker) {
        this.marker.stopMove();
      }
      this.marker = null;
    },

    closeAndQuit() {
      this.stopAnimation();
      this.showOrHidden();
      this.drawMap();
      this.checked = true;
    },
    showOrHidden() {
      this.controlIsActive = !this.controlIsActive;
    },

    loadMore() { },
  },
};
</script>

<style scoped lang="less">
.root {
  display: flex;
  height: 100%;

  .condition {
    width: 320px;
    background-color: #edf1f5;

    .title {
      background-color: #ffffff;
      color: #333;
      text-align: center;
      line-height: 40px;
    }

    .form {
      padding: 10px 15px 0 10px;

      .el-form-item {
        margin-bottom: 10px;
      }

      border-bottom: 1px solid #ddd;
    }

    .list {
      max-height: 550px;
      overflow: auto;
      font-size: 14px;
      color: #676464;

      .car {
        padding: 0 3px 3px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ddd;
        margin: 5px 0;

        >div {
          padding-right: 2px;
        }

        .name {
          width: 50px;
          text-align: center;
        }

        .tel {
          margin: 0 3px 0 1px;
        }

        .car-no {
          background-color: #2ba6ef;
          color: white;
          padding: 2px 1px;
          font-size: 14px;
          margin-left: 5px;
          width: 80px;
          display: inline-block;
          text-align: center;
        }

        .car-track {
          background-color: #2ba6ef;
          color: white;
          padding: 2px 1px;
          font-size: 14px;
          margin-left: 5px;
          width: 70px;
          display: inline-block;
          text-align: center;
        }

        .car-track:hover {
          background-color: #1a91f1;
        }
      }

      .img {
        width: 25px;
        height: 25px;
        background-size: cover;
        position: relative;
        top: -2px;
      }

      .c0 {
        background-image: url("../../../assets/image/c3.png");
      }

      .c1 {
        background-image: url("../../../assets/image/c1.png");
      }

      .c2 {
        background-image: url("../../../assets/image/c2.png");
      }

      .c3 {
        background-image: url("../../../assets/image/c3.png");
      }
    }
  }

  .map {
    height: 100%;
    flex: 1;
  }
}

#map-driver {
  height: 100%;
  position: relative;
}

.control-container {
  width: 220px;
  height: 140px;
  position: fixed;
  z-index: 1000;
  margin-top: 60px;
}

.input-card {
  float: left;
  width: 180px;
  height: 140px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;

  .input-card-time {
    font-size: 15px;
    text-align: center;
    margin: 15px 0;
    border-radius: 0 5px 5px 0;
  }

  /deep/ .el-radio {
    color: whitesmoke;
  }

  .btn {
    margin: 10px 3px 0 3px;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    width: 50px;
    color: #25a5f7;
    background-color: transparent;
    border-color: #25a5f7;
    border: 1px solid #25a5f7;
    border-radius: 8px;
    cursor: pointer;
    color: white;
    outline: none;
  }

  .information {
    width: 150px;
    margin: 5px 3px 0 10px;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
  }

  >div {
    margin: 5px 5px;
    padding: 5 6px;
  }
}

.d2 {
  width: 40px;
  height: 140px;
  display: flex;
  align-items: center;
  text-align: center;
}

.d3 {
  width: 16px;
  height: 60px;
  background: rgba(00, 22, 33, 0.3);
  display: flex;
  align-items: center;

  i {
    color: white;
  }
}

.time-slider {
  height: 40px;
  position: absolute;
  background: rgba(0, 22, 33, 0.5);
  z-index: 1000;
  left: 0;
  right: 0;
}

.el-radio {
  color: #606266;
  cursor: pointer;
  width: 50px;
  margin: 5px 0px 0 5px !important;
}

.el-radio--mini.is-bordered {
  padding: 4px 6px 5px 10px;
  border-radius: 5px;
  height: 25px;
}

.el-radio--mini.is-bordered /deep/ .el-radio__label {
  padding-left: 2px;
}

.el-radio--mini.is-bordered /deep/ .el-radio__inner {
  height: 10px;
  width: 10px;
  margin-left: -5px;
}

/deep/ .car-info {
  background-color: rgba(0, 0, 0, 0.65);
  color: white;
  padding: 5px 8px;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 6px 0px;
  display: flex;
  align-items: center;
}
</style>
