const fillColor = "#80d8ff";
const strokeColor = "#00b0ff";

const defaultOpts = {
  strokeColor: strokeColor,
  strokeWeight: 1,
  strokeOpacity: 1,
  fillOpacity: 0.6,
  draggable: false,
  fillColor,
};

const PRICE_MAP = [
  {
    strokeColor: "",
    fillColor: "#bae7ff",
  },
  {
    strokeColor: "",
    fillColor: "#91d5ff",
  },
  {
    strokeColor: "",
    fillColor: "#69c0ff",
  },
  {
    strokeColor: "",
    fillColor: "#40a9ff",
  },
  {
    strokeColor: "",
    fillColor: "#1890ff",
  },
  {
    strokeColor: "",
    fillColor: "#f5222d",
  },
  {
    strokeColor: "",
    fillColor: "#cf1322",
  },
  {
    strokeColor: "",
    fillColor: "#820014",
  },
];

export default class MapUtil {
  static overlay2Json(data) {
    const detail = {};
    detail.type = data.CLASS_NAME;
    if (data.getPath) {
      // 构造成后台存储需要的结构
      const path = data.getPath().map((item) => {
        return {
          lat: item.lat,
          lng: item.lng,
        };
      });
      detail.options = {
        path,
      };
    }
    return detail;
  }

  static overlay2Points(data) {
    // 构造成后台存储需要的结构
    const path = data.getPath().map((item) => {
      return {
        x: item.lat,
        y: item.lng,
      };
    });

    return path;
  }

  static json2Overlay(item, colorInfo = PRICE_MAP[0]) {
    const options = MapUtil.getOverlayDefaultOpts(item, colorInfo);
    return new AMap.Polygon(options);
  }

  static getOverlayDefaultOpts(item, colorInfo) {
    const path = item.areaPoints.map((t) => {
      return [t.y, t.x];
    });

    const mergeOpts = {
      ...defaultOpts,
      ...colorInfo,
      path,
      extData: {
        yxId: item.id,
        originData: item,
      },
    };

    return mergeOpts;
  }

  static getNearestPoint(currentPoint, points) {
    let minDistance = Number.MAX_VALUE;
    let nearestPoint = null;

    points.forEach((point) => {
      const distance = MapUtil.getLineDistance(point, currentPoint);
      if (distance < minDistance) {
        minDistance = distance;
        nearestPoint = point;
      }
    });

    return nearestPoint;
  }
}
