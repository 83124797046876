import { DateUtil } from "@/util";
import store from "@/store";
import {
  getPathList,
  getSysEnum,
  getTeams,
  getPassengerRemark,
  optTrackMetas,
  listVehicleType,
  listArea,
  // getAmapKey,
} from "@/api";
import { ADD_GLOBAL_DATA } from "@/store/mutation-types";

const today = DateUtil.getDayStr();

let areaMap = {};
let pathMap = {};

export class BaseDataService {
  static getCacheData() {
    // 获取全局常用数据
    getPathList().then((res) => {
      pathMap = res.data.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});
      store.commit(ADD_GLOBAL_DATA, {
        key: "pathList",
        value: res.data,
      });
    });
    getSysEnum().then((res) => {
      store.commit(ADD_GLOBAL_DATA, {
        key: "enumMap",
        value: res.data,
      });
    });
    getTeams().then((res) => {
      store.commit(ADD_GLOBAL_DATA, {
        key: "teamOpts",
        value: res.data || [],
      });
    });

    getPassengerRemark().then((res) => {
      store.commit(ADD_GLOBAL_DATA, {
        key: "pasMsgs",
        value: res.data || [],
      });
    });

    optTrackMetas().then((res) => {
      store.commit(ADD_GLOBAL_DATA, {
        key: "apmRs",
        value: res.data || [],
      });
    });
    listVehicleType().then((res) => {
      let list = res.data || [];
      list = list.filter((item) => item.delFlag);
      store.commit(ADD_GLOBAL_DATA, {
        key: "vehicleTypeOpts",
        value: list || [],
      });
    });

    listArea({}).then((res) => {
      areaMap = res.data.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});
      store.commit(ADD_GLOBAL_DATA, {
        key: "areaOpts",
        value: res.data || [],
      });
    });

    // getAmapKey().then((res) => {
    //   const script = document.createElement("script");
    //   script.type = "text/javascript";
    //   script.src = `https://webapi.amap.com/maps?v=2.0&key=${res.data}`;
    //   document.head.appendChild(script);
    // });
  }

  static getRouteAreaByPathId(pathId, isStart) {
    const path = pathMap[pathId];
    if (isStart) {
      return areaMap[path.startArea];
    } else {
      return areaMap[path.endArea];
    }
  }

  static date(showAll = false) {
    const yesterday = DateUtil.getDayStr(-1);
    const tomorrow = DateUtil.getDayStr(1);
    const dayAfterTomorrow = DateUtil.getDayStr(2);

    let res = [
      {
        value: today,
        label: `今天 （${today}）`,
      },
      {
        value: tomorrow,
        label: `明天 （${tomorrow}）`,
      },
      {
        value: dayAfterTomorrow,
        label: `后天 （${dayAfterTomorrow}）`,
      },
      {
        value: DateUtil.getDayStr(3),
        label: DateUtil.getDayStr(3),
      },
      {
        value: DateUtil.getDayStr(4),
        label: DateUtil.getDayStr(4),
      },
      {
        value: DateUtil.getDayStr(5),
        label: DateUtil.getDayStr(5),
      },
      {
        value: DateUtil.getDayStr(6),
        label: DateUtil.getDayStr(6),
      },
      {
        value: yesterday,
        label: "昨天",
      },
    ];
    if (showAll) {
      res.push({
        value: "",
        label: `全部`,
      });
    }
    return res;
  }

  static orderSource() {
    const orderSource = {
      WX: {
        value: 1,
        label: "微信",
      },
      DZ: {
        value: 2,
        label: "电招",
      },
      DL: {
        value: 5,
        label: "代理",
      },
      GM: {
        value: 6,
        label: "购买",
      },
      JH: {
        value: 7,
        label: "交换",
      },
      SJDX: {
        value: 8,
        label: "司机代下",
      },
      MP: {
        value: 3,
        label: "小程序",
      },
      APP: {
        value: 4,
        label: "APP",
      },
    };
    return orderSource;
  }

  static dateSpe() {
    const beforeYesterday = DateUtil.getDayStr(-2);
    const yesterday = DateUtil.getDayStr(-1);
    const tomorrow = DateUtil.getDayStr(1);
    const dayAfterTomorrow = DateUtil.getDayStr(2);

    let res = [
      {
        value: beforeYesterday,
        label: `前天 （${beforeYesterday}）`,
      },
      {
        value: yesterday,
        label: `昨天 （${yesterday}）`,
      },
      {
        value: today,
        label: `今天 （${today}）`,
      },
      {
        value: tomorrow,
        label: `明天 （${tomorrow}）`,
      },
      {
        value: dayAfterTomorrow,
        label: `后天 （${dayAfterTomorrow}）`,
      },
    ];

    return res;
  }

  static hwRoutes() {
    return [
      {
        label: "白班",
        value: "90",
      },
      {
        label: "夜班",
        value: "94",
      },
      {
        label: "咨询和回访",
        value: "93",
      },
    ];
  }

  static phoneLines() {
    return [
      "3001",
      "3002",
      "3003",
      "3004",
      "3005",
      "3006",
      "3007",
      "3009",
      "3011",
      "3012",
      "3013",
      "3014",
      "3015",
      "3016",
      "3017",
    ];
  }

  static getHbStatus(val) {
    let msg = "";
    switch (val) {
      case "1":
        msg = "已收";
        break;
      case "-1":
        msg = "已退";
        break;
      case "0":
        msg = "待收";
        break;
    }
    return msg;
  }

  static sexOpts() {
    return [
      {
        label: "男",
        value: 1,
      },
      {
        label: "女",
        value: 0,
      },
    ];
  }

  static yesNoOpts() {
    return [
      {
        label: "是",
        value: true,
      },
      {
        label: "否",
        value: false,
      },
    ];
  }

  static serviceOpts() {
    return [
      {
        label: "网络预约出租车",
        value: 1,
      },
      {
        label: "巡游出租车",
        value: 2,
      },
      {
        label: "私人小客车合乘",
        value: 3,
      },
    ];
  }

  static auditOpts() {
    return [
      {
        label: "待审核",
        value: 1,
      },
      {
        label: "通过审核",
        value: 2,
      },
      {
        label: "驳回审核",
        value: 3,
      },
    ];
  }

  static vehicleColorOpts() {
    return ["白色", "黑色", "红色", "褐色", "银灰", "银色", "蓝色", "灰色", "绿色"];
  }

  static seatsOpts() {
    return [
      { label: "5座", value: 4 },
      { label: "7座", value: 6 },
    ];
  }

  static engineDisplaceOpts() {
    return ["1498", "1599", "1798", "1998", "2384"];
  }

  static fuelTypeOpts() {
    return [
      {
        value: "A",
        label: "汽油",
      },
      {
        value: "B",
        label: "柴油",
      },
      {
        value: "C",
        label: "电(以电能驱动的汽车)",
      },
      {
        value: "D",
        label: "混合油",
      },
      {
        value: "E",
        label: "天然气",
      },
      {
        value: "F",
        label: "液化石油气",
      },
    ];
  }
}
