<template>
    <div class="file-preview">
        <!-- 图片预览 -->
        <div v-if="isImage" class="image-preview">
            <el-image :src="value" :preview-src-list="[value]" fit="cover">
                <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                </div>
            </el-image>
        </div>

        <!-- 音频预览 -->
        <div v-else-if="isAudio" class="audio-preview">
            <audio controls>
                <source :src="value" type="audio/mpeg">
                您的浏览器不支持音频播放
            </audio>
        </div>

        <!-- 视频预览 -->
        <div v-else-if="isVideo" class="video-preview">
            <video controls :src="value" class="video-player">
                您的浏览器不支持视频播放
            </video>
        </div>

        <!-- 其他文件 -->
        <div v-else class="other-preview">
            <el-link type="primary" :href="value" target="_blank" :underline="false">
                <i class="el-icon-document"></i>
                查看附件
            </el-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FilePreview',
    props: {
        value: {
            type: String,
            required: true
        }
    },
    computed: {
        isImage() {
            return /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(this.value);
        },
        isAudio() {
            return /\.(mp3|wav|ogg|m4a)$/i.test(this.value);
        },
        isVideo() {
            return /\.(mp4|webm|ogg)$/i.test(this.value);
        }
    }
};
</script>

<style scoped lang="less">
.file-preview {
    display: inline-block;

    .image-preview {
        width: 100px;
        height: 100px;
        cursor: pointer;

        .el-image {
            width: 100%;
            height: 100%;
            border-radius: 4px;
        }

        .image-slot {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background: #f5f7fa;
            color: #909399;
        }
    }

    .audio-preview {
        min-width: 300px;
        padding: 10px;
        background: #f5f7fa;
        border-radius: 4px;

        audio {
            width: 100%;
        }
    }

    .video-preview {
        width: 320px;
        background: #f5f7fa;
        border-radius: 4px;
        overflow: hidden;

        .video-player {
            width: 100%;
            max-height: 240px;
        }
    }

    .other-preview {
        padding: 10px;

        .el-link {
            display: flex;
            align-items: center;

            i {
                margin-right: 5px;
            }
        }
    }
}
</style>