import { Http } from "@/util";

export function changePwd(param) {
  return Http.post("/admin/changepwd", param);
}

export function resetPwd(param) {
  return Http.post("/admin/resetpwd", param);
}

export function getSysEnum() {
  return Http.get("/admin/common/enum/list", { _useCache: true });
}

export function getRoleAdminList(roleId) {
  return Http.get("/admin/role/admin/list", { _hideLoading: true, roleId });
}

export function clearPassengerInitDataCache() {
  return Http.get("/admin/clear/passenger/init_data");
}

// 生成位置链接
export function genLocationUrlLink(lat, lng) {
  return Http.get("/admin/gen/location/url_link", { lat, lng });
}

export function getAmapKey() {
  return Http.get("/admin/get/amap_web_key");
}
