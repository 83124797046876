<template>
  <div>
    <div class="head-menu" v-if="name">
      <div class="left">
        <span class="menu-icon" @click="expandMenu">
          <i v-if="expand" class="el-icon-s-fold" style="color: white; font-size: 24px"></i>
          <i v-else class="el-icon-s-unfold" style="color: white; font-size: 24px"></i>
        </span>

        <div class="search">
          <el-input placeholder="请输入搜索内容" v-model="keywords" class="input-with-select" ref="searchInput"
            @keyup.enter.native="search">
            <el-select style="width: 100px" v-model="searchType" slot="prepend">
              <el-option label="乘客" :value="1"></el-option>
              <el-option label="司机" :value="2"></el-option>
            </el-select>
            <i slot="suffix" @click="search" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>

        <span class="call-tel" v-if="isOnline">

          <i class="iconfont" @click="call">&#xe601;</i>
          <i class="iconfont" @click="changeNumber">&#xe646;</i>
          <i class="iconfont" @click="showLastView">&#xe6e7;</i>
          <el-checkbox v-model="autoAddZero"><span style="color: white; margin-right: 10px;">自动加0</span></el-checkbox>
          <span style="color: wheat">{{ showTel }}</span>
        </span>
      </div>
      <div class="right">
        <div class="hw-box" :class="{ wrong: isWrongStatus }">
          <div>{{ runTypeStr }} {{ ring }}</div>
          <template v-if="!justWatch">
            <div>
              设备状态： <span>{{ statusinfo }}</span>
            </div>
            <div>
              线路状态：{{ lineInfo.queueno }}
              {{ lineInfo.ismember ? "上线" : "下线" }}
            </div>
          </template>
        </div>

        <span class="right-menu">
          <div class="route-check">
            <route-check />
          </div>
          <el-dropdown @command="handleChoose" trigger="click" v-if="name" style="cursor: pointer">
            <span class="el-dropdown-link">
              {{ name }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="1">{{
                isKf ? "退出微信客服" : "登录微信客服"
              }}</el-dropdown-item>
              <el-dropdown-item :command="4">设置电话线路</el-dropdown-item>
              <el-dropdown-item :command="5">坐席分离</el-dropdown-item>
              <el-dropdown-item :command="2">修改密码</el-dropdown-item>
              <el-dropdown-item :command="6">{{
                silent ? "取消静音" : "网页静音"
              }}</el-dropdown-item>
              <el-dropdown-item :command="7">显示在线客服</el-dropdown-item>
              <el-dropdown-item :command="3">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </span>
      </div>
    </div>
    <el-dialog :visible="telDialog" width="500px">
      <div slot="title" class="title">请选择话机编号</div>

      <div class="tel-content">
        <div class="tel-line">
          <br />
          <strong>话机编号：</strong>
          <el-radio-group v-model="param.line" size="medium">
            <el-radio :label="item" v-for="item in phoneLines" :key="item">{{ item }}</el-radio>
          </el-radio-group>
        </div>
        <div class="tel-queue">
          <br />
          <strong>通话队列：</strong>
          <el-radio-group v-model="param.queue" size="medium">
            <el-radio :label="item.value" v-for="item in queueList" :key="item.value">{{ item.name }}</el-radio>
          </el-radio-group>
        </div>
      </div>

      <span slot="footer" class="footer">
        <el-button :size="size" @click="handleLineCancel">取 消</el-button>
        <el-button :size="size" type="primary" @click="handleLineOk">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible="passwordDialog" width="500px" :before-close="closePwdDiag">
      <div slot="title" class="title">修改密码</div>

      <div class="tel-content">
        <el-form :model="pwdForm" :rules="pwdFormRules" ref="pwdForm" label-width="120px" class="form">
          <el-form-item label="旧密码：" prop="oldPwd">
            <el-input type="password" v-model="pwdForm.oldPwd">
              <template slot="prepend">
                <i class="el-icon-key"></i>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="新密码：" prop="password">
            <el-input type="password" v-model="pwdForm.password">
              <template slot="prepend">
                <i class="el-icon-unlock"></i>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="新密码确认：" prop="pwdConfirm">
            <el-input type="password" v-model="pwdForm.pwdConfirm">
              <template slot="prepend">
                <i class="el-icon-unlock"></i>
              </template>
            </el-input>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="footer">
        <el-button :size="size" @click="closePwdDiag">取 消</el-button>
        <el-button :size="size" type="primary" @click="changePwd">确 定</el-button>
      </span>
    </el-dialog>

    <kefu ref="kefu" />

    <tel-pop-win ref="telPopWin" />

    <online-kefu ref="onlineKefu" />
  </div>
</template>

<script>
import { MENU_EX } from "@/store/mutation-types";
import { Toolkit, SessionStorage, LocalStorage } from "@/util";
import { BaseDataService, CommonService } from "@/service";
import {
  USER_INFO,
  CHOOSE_HW_QUEUE,
  CHOOSE_HW_PHONE,
  MVB2000WEB_KEY,
} from "@/core/const/global-const";
import { EV_INTO_TEL } from "@/core/const/global-event";
import Kefu from "@/components/Kefu";
import TelPopWin from "@/components/TelPopWin/index";
import OnlineKefu from "@/components/OnlineKefu";
import RouteCheck from "@/components/RouteCheck";
import { mapState } from "vuex";
import { changePwd, getTelInfo, saveCallinRecord } from "@/api";
import { whiteList } from "@/config/config";

const jmvbcti_lastwebkeygenday = new Date().getDate();
const IS_KF = "IS_KF";

const confirmQuit = (e) => {
  var confirmationMessage = "请从菜单退出系统";
  (e || window.event).returnValue = confirmationMessage; // 兼容 Gecko + IE
  return confirmationMessage; // 兼容 Gecko + Webkit, Safari, Chrome
};
export default {
  components: {
    Kefu,
    TelPopWin,
    OnlineKefu,
    RouteCheck,
  },
  created() {
    const userinfo = LocalStorage.get(USER_INFO) || {};
    this.name = userinfo.name;
    if (process.env.NODE_ENV !== "development") {
      this.cti_init();
    }
  },
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
    }),
    runTypeStr() {
      if (this.runType === 1) {
        return "运行";
      } else if (this.runType === 2) {
        return "等待";
      } else {
        return "未连接";
      }
    },
    isWrongStatus() {
      return this.lineInfo && !this.lineInfo.ismember && !this.justWatch;
    },
    isOnline() {
      return this.lineInfo && this.lineInfo.ismember;
    },
  },
  watch: {},
  mounted() {
    // 如果已经登录客服，则继续登录
    const isKf = SessionStorage.get(IS_KF);

    // 不需要连接 websocket的页面
    const page = this.$route.name;
    if (whiteList.indexOf(page) < 0 && isKf) {
      this.isKf = false;
      this.handleChoose(1);
    }
    // window.addEventListener("beforeunload", confirmQuit);

    // this.whencall(1, 1, 1, { callerid: "015527616552" });
  },
  events: {
    [EV_INTO_TEL](tel) {
      this.$refs.searchInput.focus();
      this.keywords = tel;
    },

    // [CHANGE_RUIMA_QUEUE] (isDay) {
    //     // 如果是上线状态，自动切换队列
    //     if (this.lineInfo && this.lineInfo.ismember) {
    //         // 设置队列
    //         let queue = isDay ? '90' : '94';
    //         this.$set(this.param, 'queue', queue);
    //         this.handleLineOk();

    //         if (isDay) {
    //             this.$message.success(`已为您自动切换白班队列`);
    //         } else {
    //             this.$message.success(`已为您自动切换夜班队列`);
    //         }
    //     }
    // }
  },
  data() {
    return {
      name: "",
      telDialog: false,
      passwordDialog: false,
      hwRoutes: BaseDataService.hwRoutes(),
      phoneLines: BaseDataService.phoneLines(),
      checkAll: false,
      keywords: "",
      queueList: [{ name: '客服队列', value: '90' }, { name: '招聘队列', value: '95' }],
      param: {
        line: SessionStorage.get(CHOOSE_HW_PHONE),
        queue: SessionStorage.get(CHOOSE_HW_QUEUE),
      },
      pwdForm: {},
      expand: true,
      isKf: false,
      pwdFormRules: {
        oldPwd: [{ required: true, message: "旧密码必填", trigger: "blur" }],
        password: [{ required: true, message: "新密码必填", trigger: "blur" }],
        pwdConfirm: [{ required: true, message: "新密码确认必填", trigger: "blur" }],
      },
      searchType: 1,
      justWatch: true,
      runType: 0,
      ring: "",
      statusinfo: "",
      lineInfo: {},
      silent: LocalStorage.get("silent"),
      showTel: "",
      autoAddZero: true,
    };
  },
  methods: {
    closePwdDiag() {
      this.passwordDialog = false;
    },
    changeNumber() {
      const num = this.keywords;
      if (num) {
        if (num.startsWith("0")) {
          this.keywords = num.substr(1);
        } else {
          this.keywords = "0" + num;
        }
      }
    },
    cti_init() {
      const jmvbcti_mvb2000webkey = LocalStorage.get(MVB2000WEB_KEY);
      if (!jmvbcti_mvb2000webkey) {
        return;
      }
      const md5webkey = this.cti_createwebkey(jmvbcti_mvb2000webkey);
      const hwQueue = SessionStorage.get(CHOOSE_HW_QUEUE);
      const hwPhone = SessionStorage.get(CHOOSE_HW_PHONE);
      const userInfo = LocalStorage.get(USER_INFO);
      jMVBCTI.Init("https://huawu.youxuanshunfengche.com");
      jMVBCTI.SetWebKey(md5webkey);
      jMVBCTI.SetDebug(2);
      jMVBCTI.OnHeart(this.cti_onheart_handler);
      if (hwPhone) {
        jMVBCTI.SetAgent({ agentno: userInfo.agentNo, deviceno: hwPhone });
        // 固定位90队列;
        jMVBCTI.SetQueue({ queueno: hwQueue, penalty: "0" });
        jMVBCTI.OnCalled(this.whencall);
      }
      jMVBCTI.OnStatus(this.cti_onstatus_handler);
      jMVBCTI.OnGUIRefresh(this.cti_onguirefresh_handler);
      jMVBCTI.OnAjaxError(this.cti_onajaxerror_handler);
      jMVBCTI.OnStarted(this.cti_onstarted_handler);
      jMVBCTI.Start();
    },

    whencall(agentno, device, channel, popupcalls) {
      this.showTel = popupcalls.callerid;

      // 记录话单
      if (popupcalls.direct === "IN") {
        saveCallinRecord(popupcalls.callerid);
      }
      this.$refs.telPopWin.show(popupcalls);
    },

    showLastView() {
      this.$refs.telPopWin.showLast();
    },
    cti_split() {
      const userInfo = LocalStorage.get(USER_INFO);
      jMVBCTI.Split({ agentno: userInfo.agentNo }, () => {
        this.$message({
          message: "坐席分离成功",
          type: "success",
        });
      });
      // SessionStorage.clear(CHOOSE_HW_QUEUE);
      SessionStorage.clear(CHOOSE_HW_PHONE);
      this.justWatch = true;
    },
    expandMenu() {
      this.expand = !this.expand;
      this.$store.commit(MENU_EX);
    },
    handleChoose(index) {
      if (index === 1) {
        if (this.isKf) {
          this.isKf = false;
          this.$refs.kefu.logout();
          SessionStorage.set(IS_KF, false);
        } else {
          this.isKf = true;
          this.$refs.kefu.login();
          SessionStorage.set(IS_KF, true);
        }
      } else if (index === 2) {
        this.passwordDialog = true;
      } else if (index === 3) {
        this.quit();
      } else if (index === 4) {
        this.telDialog = true;
      } else if (index === 5) {
        this.cti_split();
      } else if (index === 6) {
        // 网页静音
        this.silent = !this.silent;
        LocalStorage.set("silent", this.silent);
      } else if (index === 7) {
        this.$refs.onlineKefu.show();
      }
    },
    quit() {
      this.cti_split();
      window.removeEventListener("beforeunload", confirmQuit);
      this.$refs.kefu.logout();
      SessionStorage.set(IS_KF, false);
      CommonService.logout();
    },
    handleLineOk() {
      const { line, queue } = this.param;
      if (!line) {
        this.$message({
          message: "请选择线路和编号",
          type: "error",
        });
        return;
      }
      if (!queue) {
        this.$message({
          message: "请选择通话队列",
          type: "error",
        });
        return;
      }
      this.telDialog = false;
      SessionStorage.set(CHOOSE_HW_QUEUE, queue);
      SessionStorage.set(CHOOSE_HW_PHONE, line);
      this.cti_init();
    },

    handleLineCancel() {
      this.telDialog = false;
      this.hwRoutes = BaseDataService.hwRoutes();
      this.phoneLines = BaseDataService.phoneLines();

      // 还原之前设置
      this.param.line = SessionStorage.get(CHOOSE_HW_PHONE);
      // this.param.queue = SessionStorage.get(CHOOSE_HW_QUEUE);
    },

    call() {
      const phone = this.keywords;

      // this.$refs.telPopWin.show({callerid : phone});
      if (phone && phone.length > 10) {


        if (this.autoAddZero) {
          // 查询号码信息，判断是不是本地号码
          getTelInfo(phone)
            .then((res) => {
              let realTel = phone;
              // 非本地号码 要加 0
              if (res.data.city_code !== "027") {
                realTel = `0${phone}`;
              }
              jMVBCTI.Dial({ phoneno: realTel }, (resdata) => {
                if (resdata.errcode == 0) {
                  this.$message({
                    message: "坐席摘机,请等待接通目标...",
                    type: "success",
                  });
                } else {
                  this.$message({
                    message: resdata.message,
                    type: "error",
                  });
                }
              });
            })
            .catch((err) => {
              console.error(err);
              jMVBCTI.Dial({ phoneno: phone }, (resdata) => {
                if (resdata.errcode == 0) {
                  this.$message({
                    message: "坐席摘机,请等待接通目标...",
                    type: "success",
                  });
                } else {
                  this.$message({
                    message: resdata.message,
                    type: "error",
                  });
                }
              });
            });
        } else {
          jMVBCTI.Dial({ phoneno: phone }, (resdata) => {
            if (resdata.errcode == 0) {
              this.$message({
                message: "坐席摘机,请等待接通目标...",
                type: "success",
              });
            } else {
              this.$message({
                message: resdata.message,
                type: "error",
              });
            }
          });
        }
      } else {
        this.$message.error("号码不正确");
      }
    },
    changePwd() {
      this.$refs.pwdForm.validate((valid) => {
        if (!valid) {
          return;
        }
        const param = {
          oldPwd: Toolkit.encrypt(this.pwdForm.oldPwd),
          password: Toolkit.encrypt(this.pwdForm.password),
          pwdConfirm: Toolkit.encrypt(this.pwdForm.pwdConfirm),
        };
        changePwd(param).then(() => {
          this.passwordDialog = false;
          this.$message.success("密码修改成功");
          this.quit();
        });
      });
    },
    search() {
      const type = this.searchType;
      const keyWords = this.keywords;
      if (type === 1) {
        const { href } = this.$router.resolve({
          name: "passengerList",
          query: { tel: keyWords },
        });
        window.open(href, "_blank");
      } else if (type === 2) {
        const { href } = this.$router.resolve({
          name: "driverList",
          query: { tel: keyWords },
        });
        window.open(href, "_blank");
      }
    },
    cti_onstatus_handler(agentno, device, channel, status) {
      let statusinfo = agentno + " " + channel;
      let ring = jMVBCTI.GetStatusDesc(status) + "(" + status + ")";
      this.statusinfo = statusinfo;
      this.ring = ring;
    },
    cti_onheart_handler() {
      const jmvbcti_mvb2000webkey = LocalStorage.get(MVB2000WEB_KEY);
      const mytoday = new Date().getDate();
      //日期变化，更新密钥
      if (jmvbcti_lastwebkeygenday != mytoday) {
        jMVBCTI.SetWebKey(this.cti_createwebkey(jmvbcti_mvb2000webkey));
      }
      if (this.runType === 1) {
        this.runType = 2;
      } else {
        this.runType = 1;
      }
    },
    cti_onstarted_handler() {
      console.log("话务连接已建立");
      if (SessionStorage.get(CHOOSE_HW_PHONE)) {
        // 绑定并上线
        jMVBCTI.Bind({}, () => {
          jMVBCTI.OnLine();
        });
        this.justWatch = false;
      } else {
        //监控状态
        this.justWatch = true;
      }
    },
    cti_onajaxerror_handler() {
      this.$message({
        message: "话务ajax错误",
        type: "error",
      });
    },
    cti_onguirefresh_handler(Agents) {
      if (typeof Agents == "undefined" || Agents.length == 0) return;
      if (Agents[0].queues.length > 0) {
        const queueno = Agents[0].queues[0].queue;
        const ismember = parseInt(Agents[0].queues[0].ismember);
        let queuenostr = "";
        for (let index = 0; index < this.hwRoutes.length; index++) {
          const item = this.hwRoutes[index];
          if (item.value === queueno) {
            queuenostr = item.label;
            break;
          }
        }

        this.lineInfo = {
          queueno: queuenostr,
          ismember: ismember,
        };
      }

      try {
        let devicestatus = Agents[0].devicestatus;
        /*    '0' => '空闲',		'1' => '使用',		'2' => '忙',		'4' => '不可用',		'8' => '振铃',		'9' => '振铃'*/
        switch (devicestatus) {
          case 0:
            this.telStatus = "空闲";
            this.seconds = null;
            break;
          case 1:
            this.telStatus = "正在通话中";
            if (this.seconds === null) {
              this.seconds = 0;
            } else {
              this.seconds += 1;
            }
            break;
          case 2:
            this.telStatus = "电话忙";
            this.seconds = null;
            break;
          case 8:
          case 9:
            this.telStatus = "振铃";
            this.seconds = null;
            break;
          default:
            this.seconds = null;
            break;
        }
      } catch (e) {
        console.error(e.message);
      }
    },
    cti_createwebkey(keystring) {
      let today = new Date();
      let date = today.getDate();
      if (date <= 9) {
        date = "0" + date;
      }
      let month = today.getMonth();
      month = month + 1;
      if (month <= 9) {
        month = "0" + month;
      }
      const year = today.getFullYear();
      const nowDate = year + "-" + month + "-" + date;
      return MD5(keystring + nowDate);
    },
  },
};
</script>

<style lang="less" type="text/less" scoped>
.head-menu {
  height: 50px;
  border-bottom: 1px solid #ddd;
  background: #4894ef;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    display: flex;
    align-items: center;

    .search {
      display: inline-block;
      width: 400px;
      transform: scale(0.9);

      .el-input-group__prepend {
        background-color: white !important;
      }

      i {
        cursor: pointer;
      }
    }

    .menu-icon {
      height: 50px;
      line-height: 50px;
      text-align: center;
      width: 50px;
      display: inline-block;
      cursor: pointer;

      i {
        color: #999;
        position: relative;
        top: 2px;
      }
    }

    .call-tel {
      .iconfont {
        color: #75e475;
        font-size: 20px;
        cursor: pointer;
        margin-right: 15px;
      }
    }
  }

  .right {
    color: white;
    display: flex;
    align-items: center;

    .right-menu {
      color: white;
      margin-right: 10px;
      display: flex;
      align-items: center;

      .route-check {
        margin-right: 16px;
        position: relative;
        top: -2px;
      }

      .el-dropdown {
        .el-dropdown-link {
          color: white;
        }
      }

      .old-mgt {
        position: relative;
        top: 15px;
        color: #409eff;
        margin-right: 60px;
        font-size: 14px;
      }
    }
  }
}

.tel-content {
  display: flex;

  /* 允许换行以实现两列显示 */

  .el-radio {
    margin: 8px 8px 8px 0;
  }

  .tel-line {
    flex: 2;
    display: flex;
    flex-wrap: wrap;
    width: 100px;
    max-height: 200px;
    margin-right: 20px;

  }

  .tel-queue {
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
  }
}

.footer {
  display: flex;
  justify-content: center;
}

.hw-box {
  font-size: 12px;
  position: fixed;
  left: 10px;
  bottom: 10px;
  color: #3fb73f;
  width: 300px;

  &.wrong {
    color: red;
  }
}
</style>
