<template>
    <div>
        <!-- <div class="print-header">
            <el-button type="primary" size="small" @click="handlePrint">打印</el-button>
        </div> -->

        <div class="print-content">

            <div :style="{ width: printContentWidth + 'mm' }" class="print-content-inner">
                <repair-detail v-if="billType === 'repair'" :data="data" :print-width="printWidth"></repair-detail>
                <check-detail v-if="billType === 'inspection'" :data="data" :print-width="printWidth"></check-detail>
                <mtc-detail v-if="billType === 'maintenance'" :data="data" :print-width="printWidth"></mtc-detail>

                <div class="company-seal">
                    <img src="@/assets/image/yxsign.png" alt="公司公章" style="width: 150px; height: auto;">
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import repairDetail from './repairDetail.vue';
import checkDetail from './checkDetail.vue';
import mtcDetail from './mtcDetail.vue';

import { getPrintBillInfo } from '@/api/partner_admin';
export default {
    name: 'RepairDetail',
    components: {
        repairDetail,
        checkDetail,
        mtcDetail
    },

    created() {
        this.id = this.$route.query.id;
        this.billType = this.$route.query.billType;
        this.getData();
    },

    computed: {
        printContentWidth() {
            return this.data.printWidth + 20;
        }
    },

    data() {
        return {
            billType: null,
            printWidth: 200,
            data: {}
        };
    },
    methods: {

        getData() {
            getPrintBillInfo(this.id).then(res => {
                this.data = res.data;
            });
        },

        handlePrint() {
            window.print();
        }
    }
};
</script>

<style lang="less" scoped>
.print-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.print-content {
    display: flex;
    justify-content: center;
    align-items: center;


    .print-content-inner {
        position: relative;

        .company-seal {
            position: absolute;
            bottom: 30px;
            right: 40px;
        }
    }


}
</style>
