<template>
  <el-drawer :visible.sync="visible" direction="ltr" :with-header="false" custom-class="drawer-modal" @opened="onOpen"
    :show-close="false" :before-close="beforeClose" :withHeader="false">
    <div class="header">
      <el-form size="small" :inline="true">

        <el-form-item label="地址搜索" v-if="visible">
          <address-search :area="area" @select="select" />
        </el-form-item>
        <el-form-item>
          <el-button style="width: 100px; margin-right: 20px" type="primary" @click="onOK">确定选择</el-button>
        </el-form-item>
      </el-form>

    </div>

    <div id="locationSelectMap" class="map-b"></div>

  </el-drawer>
</template>

<script>

const markB = require("../assets/image/mark_b.png");

export default {

  data() {
    return {
      visible: false,
      area: null,
    };
  },

  methods: {
    show(point, area, callback) {
      this.point = point;
      this.area = area;
      this.callback = callback;
      this.visible = true;
    },

    onOpen() {
      this.showMap();
    },

    showMap() {
      const map = new AMap.Map("locationSelectMap", {
        resizeEnable: true,
        dragEnable: true,
        zoom: 14,
      });

      this.map = map;

      // AMap.plugin(["AMap.PlaceSearch", "AMap.AutoComplete"], () => {
      //   const autoOptions = {
      //     // input 为绑定输入提示功能的input的DOM ID
      //     input: "locationSelectInput",
      //     city: this.area || "全国",
      //   };
      //   const autoComplete = new AMap.AutoComplete(autoOptions);
      //   autoComplete.on("select", this.select);
      // });

      const { lat, lng } = this.point || {};
      if (lat && lng) {
        this.addMarker(this.point);
      }
    },

    onOK() {
      if (this.marker) {
        this.visible = false;
        const { lat, lng } = this.marker.getPosition();
        const extData = this.marker.getExtData();
        this.callback({ lat, lng, name: extData.title });
      } else {
        this.$message.error("请确定一个地点");
      }
    },

    select(poi) {
      this.addMarker(poi);
    },

    addMarker(poi) {
      if (this.marker) {
        this.map.remove(this.marker);
      }
      const position = [+poi.lng, +poi.lat];
      const marker = new AMap.Marker({
        position,
        title: poi.name,
        icon: new AMap.Icon({
          image: markB,
        }),
        anchor: "bottom-center",
        draggable: true,
        label: {
          //修改label相对于maker的位置
          direction: "top",
          content: "<div class='info active'>" + poi.name + "</div>",
        },
        extData: {
          title: poi.name,
        },
      });

      this.marker = marker;
      this.map.add(marker);
      this.map.setCenter(position);
    },

    beforeClose(done) {
      if (this.map) {
        this.map.clearMap();
      }
      done && done();
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .drawer-modal {
  width: 50% !important;
}

#locationSelectMap {
  width: 100%;
  height: 100%;
  flex: 1;
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 16px 0 0 20px;
  background-color: #ddd;
}

#locationSelectInput {
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  border: none;
  outline: none;
}
</style>
