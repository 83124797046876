<template>
  <div class="address" @click="show = true">
    <el-autocomplete :disabled="disabled" style="width: 100%" class="inline-input" value-key="name" v-model="searchKey"
      :fetch-suggestions="fetchSuggestions" placeholder="请输入内容" :clearable="true" @clear="clearText"
      @select="handleChoose">
      <template slot="prepend">{{ scope }}</template>

      <el-button slot="append" icon="el-icon-map-location" @click="showMap"><span v-if="isOnWay && price"
          class="red">+{{
            price
          }}</span>
        <span class="red" v-if="!isOnWay">不顺路</span>
      </el-button>
    </el-autocomplete>
  </div>
</template>

<script>
import { getRecommendAddress, getUpPrice, getPathAreas, searchPlace } from "@/api";
import { Message } from "element-ui";
import { SHOW_PASSENGER_MAP } from "@/core/const/global-event";


export default {
  name: "PoiSelector",
  props: {
    value: Object,
    suggest: Array,
    pathId: Number,
    ticketId: Number,
    isStart: Boolean,
    disabled: Boolean,
    modifyOrder: Object,
  },

  computed: {
    scope() {
      return this.areaInfo && this.areaInfo.name;
    },
  },

  mounted() {
    this.initData();
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.searchKey = val.name;
        } else {
          this.$emit("onUpPriceGet", 0);
          this.price = null;
          this.searchKey = null;
        }
      },
      immediate: true,
    },
    searchKey(val) {
      // 输入无效地址清空value
      if (this.value && val !== this.value.name) {
        this.$emit("input", null);
        this.$emit("onUpPriceGet", 0);
        this.price = null;
      }
    },
    pathId() {
      this.initData();
    },

    suggest() {
      if (this.suggest) {
        this.defaultAddress = this.suggest.map((item) => {
          return {
            name: item.address,
            lng: item.longitude,
            lat: item.latitude,
          };
        });
      }
    },
  },
  data() {
    return {
      searchKey: "",
      price: "",
      show: false,
      defaultAddress: [],
      isOnWay: true,
      areaInfo: null,
    };
  },
  methods: {
    initData() {
      if (this.pathId) {
        getPathAreas({ pathId: this.pathId }).then((res) => {
          this.areaInfo = this.isStart ? res.data[0] : res.data[1];

          // 实例化Autocomplete
          // const autoOptions = {
          //   city: this.areaInfo.adcode || "全国", // city 限定城市，默认全国
          //   citylimit: false,
          // };
          // window.AMap.plugin("AMap.AutoComplete", () => {
          //   this.autoComplete = new AMap.AutoComplete(autoOptions);
          // });
        });
      }
    },

    handleChoose(item) {
      const name = item.name.replace("🍉", "");
      this.$emit("input", {
        name,
        jd: `${item.lng}`,
        wd: `${item.lat}`,
      });

      // 查询加价
      const param = {
        pathId: this.pathId,
        longitude: item.lng,
        latitude: item.lat,
        isStart: this.isStart,
      };
      getUpPrice(param).then((res) => {
        this.price = res.data;
        this.$emit("onUpPriceGet", res.data);
      });
    },
    fetchSuggestions(val, cb) {
      if (!this.pathId) {
        cb([]);
        Message.error("请先选择路线");
        return;
      }
      if (!this.ticketId) {
        cb([]);
        Message.error("请先选择时间");
        return;
      }

      if (val) {
        // this.autoComplete.search(val, (status, result) => {
        //   if (status === "complete") {
        //     let res = result.tips || [];
        //     cb(this.transMapData(res));
        //   }
        // });
        searchPlace(this.areaInfo.id, val).then((res) => {
          if (res.data && res.data.length) {
            cb(res.data);
          } else {
            cb([]);
          }
        });
      } else {
        const df = this.defaultAddress || [];
        getRecommendAddress({
          areaId: this.areaInfo.id,
          type: 1,
        }).then((res) => {
          cb(df.concat(this.transRecommendData(res.data)));
        });
      }
    },
    showMap() {
      if (this.value && this.value.jd) {
        const { orderStatus, driverId, ticketId } =
          this.modifyOrder || {};
        const order = {
          pathId: this.pathId,
          lng: this.value.jd,
          lat: this.value.wd,
          address: this.value.name,
          orderStatus,
          driverId,
          ticketId,
        };
        const param = {
          type: 2,
          order: order,
          recommendAddressParam: this.recommendAddressParam,
          isStart: this.isStart,
          chooseCb: this.handleChoose,
        };
        this.$bus.$emit(SHOW_PASSENGER_MAP, param);
      }
    },


    transRecommendData(data = []) {
      const list = [];
      data.forEach((item) => {
        list.push({
          name: `${item.name}`,
          lng: `${item.longitude}`,
          lat: `${item.latitude}`,
        });
      });
      return list;
    },

    clearText() {
      this.searchKey = "";
    },

    setIsOnWay(val, param) {
      this.isOnWay = val;
      this.recommendAddressParam = param;
    },
  },
};
</script>

<style lang="less" scoped></style>
