<template>
  <div class="root-box">
    <div>
      <div class="route-detail">
        <el-table :data="list" :default-expand-all="false" max-height="650" :show-header="false"
          :row-class-name="tableRowClassName" class="no-hover" ref="table" stripe>
          <el-table-column type="expand" show-overflow-tooltip>
            <template slot-scope="props">
              <div class="table-expand">
                <el-table :data="props.row.orderList" :show-header="false" class="no-hover">
                  <el-table-column prop="orderIndex" width="50" label="订单序号">
                    <template slot-scope="scope">
                      <span class="order-index">{{ scope.row.orderIndex + 1 }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column prop="showTime" width="120" label="时间"></el-table-column>
                  <el-table-column prop="orderSource" width="80" label="渠道">
                    <template slot-scope="scope">
                      <div v-order-source="scope.row.orderSource"></div>
                      <vip-label :vip="scope.row.vip" />
                    </template>
                  </el-table-column>

                  <el-table-column prop="number" width="60" label="订票">
                    <template slot-scope="scope">
                      <div>
                        {{ scope.row | orderTypeTransfer }}
                      </div>
                      <div v-if="scope.row.togetherPwd" class="together">
                        {{ scope.row.togetherPwd }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="price" width="100" label="总价">
                    <template slot-scope="scope">
                      <span v-operate-order:bill="scope.row.id">{{ scope.row.price | moneyFormat }}元</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="telephone" label="手机" width="130">
                    <template slot-scope="scope">
                      <tel-cell :order="scope.row" />
                    </template>
                  </el-table-column>

                  <el-table-column prop="departure" label="起点">
                    <template slot-scope="scope">
                      <span class="pointer" @click="showPassengetMap(props.row.route, props.row.orderList, true)">{{
                        scope.row.departure }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="destination" label="终点">
                    <template slot-scope="scope">
                      <span class="pointer" @click="showPassengetMap(props.row.route, props.row.orderList, false)">{{
                        scope.row.destination }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="seatDesc" width="80" label="座位"></el-table-column>
                  <el-table-column prop="psgMessage" width="130" label="乘客留言">
                    <template slot-scope="scope">
                      <limit-text :width="130" :text="scope.row.psgMessage"></limit-text>

                      <limit-text :width="150" :text="scope.row.baggageRemark | baggagesRemarkFilter"></limit-text>

                      <el-button v-if="scope.row.baggageAudit === 2" type="text" size="small"
                        @click="aduitBag(scope.row, true)">查看行李</el-button>
                    </template>
                  </el-table-column>
                  <el-table-column prop="remark" label="司机备注">
                    <template slot-scope="scope">
                      <limit-text :width="130" :text="scope.row.remark"></limit-text>
                    </template>
                  </el-table-column>
                  <el-table-column prop="adminMessage" label="客服备注">
                    <template slot-scope="scope">
                      <limit-text :width="130" :text="scope.row.adminMessage"></limit-text>
                      <span v-if="scope.row.sysTipMessage">{{ scope.row.sysTipMessage }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="realTimePositioning">
                    <template slot-scope="scope">
                      <el-button v-if="scope.row.realTimePositioning" type="text" size="small"
                        @click="showRealTimePosition(scope.row)">实时位置</el-button>
                    </template>
                  </el-table-column>

                  <el-table-column prop="orderStatus" width="110" label="订单状态">
                    <template slot-scope="scope">
                      <span v-if="scope.row.abnormalFinish">超时未上车</span>
                      <span v-else>{{
                        scope.row.routeStatus | enumTransfer(routeDetailOpts)
                      }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column prop="payStatus" width="80" label="支付状态">
                    <template slot-scope="scope">
                      <span :class="{ red: scope.row.payStatus === 0 }">{{
                        scope.row.payStatus | enumTransfer(payStatusOpts)
                      }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column width="100" prop="createTime" label="下单时间"></el-table-column>

                  <el-table-column prop="id" label="操作" align="right" fixed="right" width="120">
                    <template slot-scope="scope">
                      <el-button type="text" size="small" @click="openMsgModal(scope.row)">留言</el-button>
                      <el-dropdown @command="(command) => handleCommand(command, scope.row, props.row.route)">
                        <span class="el-dropdown-link">
                          其他
                          <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <!-- <el-dropdown-item :disabled="scope.row.orderStatus === 'FINISH'"
                            command="1">取消</el-dropdown-item> -->
                          <el-dropdown-item v-if="scope.row.orderType !== 4" command="2">改签/退票</el-dropdown-item>
                          <el-dropdown-item :disabled="scope.row.orderStatus === 'FINISH'"
                            command="3">撤回</el-dropdown-item>
                          <el-dropdown-item v-if="scope.row.orderType !== 4" command="10">乘客违约关单</el-dropdown-item>
                          <el-dropdown-item>
                            <span v-goRouteDetail="scope.row.id">行程详情</span>
                          </el-dropdown-item>
                          <el-dropdown-item>
                            <span v-goRouteReplay="{
                              id: scope.row.routeId,
                              orderId: scope.row.id,
                            }">行程回放</span>
                          </el-dropdown-item>
                          <el-dropdown-item command="12">获取乘客定位</el-dropdown-item>
                          <el-dropdown-item command="11">开罚单</el-dropdown-item>
                          <el-dropdown-item command="8">显示下单地址</el-dropdown-item>
                          <el-dropdown-item command="4">发送短信</el-dropdown-item>
                          <el-dropdown-item command="9">交易明细</el-dropdown-item>
                          <el-dropdown-item command="5">操作日志</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="司机编号" width="100px">
            <template slot-scope="scope">
              <span @click="showDriverLocation(scope.row.route.driverId)" class="route-info pointer"
                :class="{ blue: scope.row.bigCar }">{{
                  scope.row.route.driverNo }}
                <span class="red" v-if="scope.row.route.number !== 4">{{ scope.row.route.number }}座车</span>
              </span>
            </template>
          </el-table-column>

          <el-table-column label="剩余座位数">
            <template slot-scope="scope">
              <div class="route-info pointer" @click="toggleRow(scope.row)">
                <div class="info">
                  剩余座位数:
                  <span class="red">{{ scope.row.route.number - scope.row.route.used }}</span>
                  <span>{{ scope.row.route.date }} / {{ scope.row.route.showTime }}</span>
                  <span v-if="!param.pathId">{{ scope.row.route.pathId | pathNameTransfer }}</span>

                  <span v-if="scope.row.routeSequence" :class="{ red: !scope.row.routeSequence.isConfirmed }"
                    @click.stop="showRoutePlan(scope.row.route, scope.row.route.needToMatch)">
                    <i class="el-icon-share"></i>
                    {{
                      scope.row.routeSequence.isConfirmed ? "行程规划" : "行程规划（未确认）"
                    }}</span>
                  <span v-else class="red">行程规划计算中,请3秒后刷新</span>

                  <span v-if="!scope.row.flowPlan" :class="scope.row.flowPlan ? 'green' : 'red'">{{
                    scope.row.flowPlan ? "遵循接送规划" : "不遵循接送规划"
                  }}</span>

                  <span v-if="scope.row.baggageWarningLevel > 2"
                    :class="scope.row.baggageWarningLevel === 3 ? 'warn' : 'red'">行李预警</span>

                  <!-- <span class="green" @click.stop="showMonitorView(scope.row.route)">
                    <i class="el-icon-monitor"></i> 监控
                  </span> -->

                  <span v-if="scope.row.qcTask" class="qc" @click.stop="go2QcPage(scope.row.route)"
                    :class="`process${scope.row.qcTask.process}`">
                    <span v-if="scope.row.qcTask.process === 0">待质检</span>
                    <span v-if="scope.row.qcTask.process === 1">质检中</span>
                    <span v-if="scope.row.qcTask.process === 2">质检通过</span>
                    <span v-if="scope.row.qcTask.process === 3">质检不合格</span>
                  </span>

                  <span class="qc process1" @click.stop="go2MonitorPage(scope.row.route)">
                    监控
                  </span>

                  <span v-if="scope.row.hasShunt" :class="{ red: scope.row.hasShunt, grey: scope.row.shuntAllFinished }"
                    @click.stop="showShuntMap(scope.row.route, scope.row.orderList)">
                    <i class="el-icon-s-flag"></i>分流</span>
                  <img src="../../../assets/image/notonway.png" v-if="!scope.row.route.controlArea"
                    style="width: 20px; height: 20px; position: relative; top: 3px" />

                  <el-popover placement="right" width="600" trigger="hover" v-if="scope.row.punishBillList.length">
                    <el-table :data="scope.row.punishBillList" size="small" :show-header="false">
                      <el-table-column property="violateClassify2" label="违规项">
                        <template slot-scope="scope">
                          {{ scope.row.violateClassify2 | enumTransfer(violateClassify2Opts) }}
                        </template>
                      </el-table-column>
                      <el-table-column property="orderDesc" label="订单"></el-table-column>
                      <el-table-column property="violateSrc" label="罚单来源">
                        <template slot-scope="scope">
                          {{ scope.row.violateSrc | enumTransfer(violateSrcOpts) }}
                        </template>
                      </el-table-column>
                      <el-table-column property="punishResult" label="处罚结果"></el-table-column>
                      <el-table-column property="id" label="详情">
                        <template slot-scope="scope2">
                          <el-link type="primary" @click="showPunishBill(scope2.row)">详情</el-link>

                          <el-link type="primary" style="margin-left: 6px" :disabled="scope2.row.appealed"
                            @click="cancelPunish(scope.$index, scope2.$index)">{{ scope2.row.appealed ? "已免责" : "免责"
                            }}</el-link>
                        </template>
                      </el-table-column>
                    </el-table>
                    <span style="color: #03a9f4" slot="reference">
                      <i class="el-icon-scissors"></i> 罚单
                    </span>
                  </el-popover>
                </div>

                <div class="right-op-box">
                  <el-button type="text" @click.stop="showShuntMap(scope.row.route, scope.row.orderList)">
                    安排分流</el-button>

                  <el-button type="text" @click.stop="pre2Matched(scope.row.route)" v-if="scope.row.route.needToMatch">
                    预派转正式</el-button>

                  <el-dropdown @click.stop @command="(command) => handleRouteCommand(command, scope.row.route, scope.row.orderList)
                  ">
                    <span>
                      <span>行程操作</span>
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="3" v-if="scope.row.route.status !== 'FINISH'">行程到达</el-dropdown-item>
                      <el-dropdown-item command="4" v-if="!scope.row.orderList.length">取消空行程</el-dropdown-item>
                      <el-dropdown-item command="10"
                        v-if="scope.row.route.status === 'PRE_DISPATCH'">撤回行程</el-dropdown-item>
                      <el-dropdown-item command="13"
                        v-if="scope.row.route.status === 'PRE_DISPATCH'">改派司机</el-dropdown-item>
                      <el-dropdown-item command="5" v-if="scope.row.route.status === 'PRE_DISPATCH'">
                        {{ scope.row.route.display2Driver ? "不展示给司机" : "展示给司机" }}
                      </el-dropdown-item>
                      <el-dropdown-item command="6" v-if="scope.row.route.status !== 'FINISH'">重新计算规划</el-dropdown-item>
                      <el-dropdown-item command="7" v-if="scope.row.route.status !== 'FINISH'">
                        顺路控制</el-dropdown-item>
                      <el-dropdown-item command="8">开罚单</el-dropdown-item>
                      <el-dropdown-item command="9" v-if="scope.row.route.status !== 'FINISH'">提醒司机联系</el-dropdown-item>
                      <el-dropdown-item command="11" v-if="scope.row.route.status !== 'FINISH'">修改座位数</el-dropdown-item>
                      <el-dropdown-item command="12" v-if="isManager">导出行程数据</el-dropdown-item>
                      <el-dropdown-item command="14">行程备注</el-dropdown-item>
                      <el-dropdown-item command="15">语音播报</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="page">
        <el-pagination @current-change="handleCurrentChange" background :current-page="currentPage"
          :page-size="pageSize" layout="slot, prev, pager, next, jumper" :total="total">
          <span class="pagination-sum">共{{ list.length }}个行程， {{ totalPerson }}人 </span>
        </el-pagination>
      </div>
    </div>

    <el-dialog :visible.sync="onwayVisible" width="400px" max-height="400" :close-on-click-modal="false">
      <el-radio-group v-model="selectedRoute.controlArea">
        <el-radio :label="0">不控制顺路</el-radio>
        <el-radio :label="1">宽松控顺路</el-radio>
        <el-radio :label="2">严格控顺路</el-radio>
      </el-radio-group>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" :size="size" @click="changeAreaControl">确定</el-button>
        <el-button :size="size" @click="onwayVisible = false">关闭</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="routeSeatsVisible" width="400px" max-height="400" :close-on-click-modal="false">
      <el-input-number style="width: 180px" size="small" v-model="selectedRoute.number"></el-input-number>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" :size="size" @click="changeRouteSeats">确定</el-button>
        <el-button :size="size" @click="routeSeatsVisible = false">关闭</el-button>
      </div>
    </el-dialog>

    <order-cancel ref="cancelModal" :onCancel="handleCurrentChange" />
    <tel-order-modify-modal ref="modifyModal" :simpleMode="true" />
  </div>
</template>

<script>
import {
  getActiveRouteList,
  updatePreDispatch2Match,
  preDispatchShow2Driver,
  cancelEmptyRoute,
  createSysRoutePlan,
  changeRouteAreaControl,
  abnormalFinishOrder,
  getDriverInfo,
  tipDriver,
  dealPunishAppeal,
  rollbackRoute,
  modifyRouteSeats,
  updateRouteRemark,
  sendLocationRequest,
  ttsBroadcast
} from "@/api";
import TelOrderModifyModal from "@/components/TelOrder/TelOrderModifyModal";
import TelCell from "@/components/TelCell";
import { mapState } from "vuex";
import {
  SHOW_DRIVER_LOCATION,
  SHOW_ROUTE_MAP,
  SHOW_SHUNT_MAP,
  SHOW_BOOK_ADDRESS_MAP,
  SHOW_REALTIME_LOCATION,
  SHOW_ROUTE_PLAN,
  SHOW_CHANGE_ORDER_MODAL,
  SHOW_MONITOR_MODAL,
  SHOW_PUNISH_DIAG,
} from "@/core/const/global-event";
import _ from "lodash";
import Router from "@/route";

export default {
  filters: {
    baggagesRemarkFilter(val) {
      if (val) {
        return val.substr(1);
      }
      return val;
    },
  },
  components: {
    TelOrderModifyModal,
    TelCell,
  },
  props: {
    recallOrder: Function,
    auditBaggage: Function,
    sendSms: Function,
    showTransactionDetail: Function,
    changeRouteDriver: Function,
  },
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      isManager: (state) => state.isManager,
      orderStatusOpts: (state) => state.enumMap["ORDER_STATUS"],
      routeDetailOpts: (state) => state.enumMap["ROUTE_DETAIL_STATUS"],
      payStatusOpts: (state) => state.enumMap["PAY_STATUS"],
      violateClassify2Opts: (state) => state.enumMap["DRIVER_VIOLATE_OPT_TYPE"],
      violateSrcOpts: (state) => state.enumMap["EVA_SOURCE_CHANNEL"],
    }),
  },
  data() {
    return {
      ticketDiagVisible: false,
      list: [],
      pageSize: 100,
      currentPage: 1,
      total: 0,
      currentOrderId: null,
      ticketList: [],
      loading: false,
      param: {},
      totalPerson: 0,
      selectedRoute: {},
      onwayVisible: false,
      routeSeatsVisible: false,
    };
  },
  methods: {
    query(page = 1, param = this.param || {}) {
      this.param = param;
      param.orderStatus = "MATCHED";
      const query = {
        page: {
          current: page,
          size: this.pageSize,
        },
        param,
      };
      getActiveRouteList(query).then((res) => {
        const { records = [], current = 1, total = 0 } = res.data || {};
        this.list = records;
        this.currentPage = current;
        this.total = total;

        let totalPerson = 0;
        records.forEach((item) => {
          totalPerson += item.route.used;
          // 是否查看分流信息
          const isUnreadShunt =
            (item.route.isStartShunt && !item.route.isReadStartShunt) ||
            (item.route.isEndShunt && !item.route.isReadEndShunt);
          item.route.unreadShunt = isUnreadShunt;

          for (let index = 0; index < item.orderList.length; index++) {
            const order = item.orderList[index];
            if (order.orderStatus === "PRE_DISPATCH") {
              item.route.needToMatch = true;
              break;
            }
          }
        });
        this.totalPerson = totalPerson;
      });
    },

    toggleRow(row) {
      this.$refs.table.toggleRowExpansion(row);
    },
    showDriverLocation(driverId) {
      this.$bus.$emit(SHOW_DRIVER_LOCATION, driverId);
    },
    showRealTimePosition(order) {
      this.$bus.$emit(SHOW_REALTIME_LOCATION, order);
    },

    showMonitorView(route) {
      this.$bus.$emit(SHOW_MONITOR_MODAL, route.driverId);
    },

    handleCommand(command, order, route) {
      command = parseInt(command);
      switch (command) {
        case 1:
          this.openCancelModal(order);
          break;
        case 2:
          this.openUpdateModal(order);
          break;
        case 3:
          this.recallOrder(order);
          break;
        case 4:
          this.sendSms(order.id);
          break;
        case 5:
          this.$orderLog().show(order.id);
          break;
        case 8:
          this.showBookAddress(order);
          break;
        case 9:
          this.showTransactionDetail(order);
          break;
        case 10:
          this.abnormalFinish(order);
          break;
        case 11:
          this.createPunishBill(route, order);
          break;
        case 12:
          this.getPassengerLocation(order);
          break;
      }
    },

    handleRouteCommand(command, route) {
      this.selectedRoute = { ...route };
      command = parseInt(command);
      switch (command) {
        case 3:
          this.routeArrive(route);
          break;
        case 4:
          this.cancelRoute(route);
          break;
        case 5:
          this.preShow2Driver(route);
          break;
        case 6:
          this.calcRoutePlan(route);
          break;
        case 7:
          this.onwayVisible = true;
          break;
        case 8:
          this.createPunishBill(route);
          break;
        case 9:
          this.hurryUpDriver(route);
          break;
        case 10:
          this.rollbackRoute(route);
          break;
        case 11:
          this.routeSeatsVisible = true;
          break;
        case 12:
          this.exportRouteCheckData(route);
          break;
        case 13:
          this.changeRouteDriver(route);
          break;
        case 14:
          this.remarkRoute(route);
          break;
        case 15:
          this.ttsBroadcast(route);
          break;
      }
    },

    remarkRoute(route) {
      this.$prompt("请输入备注", "备注", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          const param = {
            id: route.id,
            remark: value,
          };
          updateRouteRemark(param).then(() => {
            this.$message.success("操作成功");
            this.query();
          });
        })
        .catch(() => { });
    },

    exportRouteCheckData(route) {
      window.open(`/admin/route/check/download/route_data?routeId=${route.id}`);
    },

    rollbackRoute(route) {
      this.$confirm("确认撤回吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        rollbackRoute(route.id).then(() => {
          this.$message.success("操作成功");
          this.query();
        });
      });
    },

    hurryUpDriver(route) {
      this.$confirm("确认催促司机吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        tipDriver(route.id).then(() => {
          this.$message.success("操作成功");
        });
      });
    },

    createPunishBill(route, order) {
      this.$bus.$emit(SHOW_PUNISH_DIAG, { order, route });
    },

    showPunishBill(punishBill) {
      this.$bus.$emit(SHOW_PUNISH_DIAG, { punishBill: _.cloneDeep(punishBill), viewMode: true });
    },

    abnormalFinish(item) {
      this.$confirm("确认关单吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        abnormalFinishOrder(item.id).then(() => {
          this.$message.success("操作成功");
          this.query();
        });
      });
    },

    changeAreaControl() {
      changeRouteAreaControl(this.selectedRoute).then(() => {
        this.onwayVisible = false;
        this.$message.success("操作成功");
        this.query();
      });
    },

    changeRouteSeats() {
      const { id, number } = this.selectedRoute;

      modifyRouteSeats(id, number).then(() => {
        this.routeSeatsVisible = false;
        this.$message.success("操作成功");
        this.query();
      });
    },

    calcRoutePlan(route) {
      const { pathId, id } = route;
      const param = { pathId, routeId: id };
      createSysRoutePlan(param).then(() => {
        this.$message.success("操作成功");
        this.query();
      });
    },

    showRoutePlan(route, needToMatch) {
      this.$bus.$emit(SHOW_ROUTE_PLAN, route, needToMatch);
    },

    cancelRoute(route) {
      this.$confirm("确认取消行程吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        cancelEmptyRoute(route.id).then(() => {
          this.$message.success("操作成功");
          this.query();
        });
      });
    },

    pre2Matched(route) {
      this.$confirm("确认正式指派吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const param = {
          routeId: route.id,
          pathId: route.pathId,
        };
        updatePreDispatch2Match(param).then(() => {
          this.$message.success("操作成功");
          this.query();
        });
      });
    },

    preShow2Driver(route) {
      const val = !route.display2Driver;
      this.$confirm(val ? "确认展示给司机查看吗？" : "确认取消展示给司机查看吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const param = {
          routeId: route.id,
          isShow: val,
        };
        preDispatchShow2Driver(param).then(() => {
          this.$message.success("操作成功");
          this.query();
        });
      });
    },

    showPassengetMap(route, orders, isStart) {
      this.$bus.$emit(SHOW_ROUTE_MAP, route, orders, isStart);
    },

    showShuntMap(route, orders) {
      this.$bus.$emit(SHOW_SHUNT_MAP, route, orders);
    },

    showBookAddress(item) {
      this.$bus.$emit(SHOW_BOOK_ADDRESS_MAP, item);
    },

    routeArrive(route) {
      this.$confirm("确认司机已到达吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http.get("/admin/scheduling/end_trip", { routeId: route.id }).then(() => {
          this.$message.success("操作成功");
          this.query();
        });
      });
    },

    getPassengerLocation(order) {
      this.$confirm("确认申请乘客定位吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        sendLocationRequest({ driverId: order.driverId, orderId: order.id }).then(() => {
          this.$message.success("发送成功");
        });
      });
    },

    handleCurrentChange(page) {
      this.query(page);
    },

    openCancelModal(item) {
      this.$refs.cancelModal.open(item.id);
    },

    openUpdateModal(item) {
      this.$bus.$emit(SHOW_CHANGE_ORDER_MODAL, item);
    },

    openMsgModal(item) {
      this.$refs.modifyModal.open(item);
    },

    tableRowClassName({ row }) {
      return row.route.status === "FINISH"
        ? "finish"
        : row.route.status === "PRE_DISPATCH"
          ? "pre-dispatch"
          : "";
    },

    aduitBag(item, viewMode) {
      this.auditBaggage(item, viewMode);
    },

    go2QcPage(item) {
      const { href } = Router.resolve({
        path: "/route/qc",
        query: { routeId: item.id },
      });
      window.open(href, "_blank");
    },

    async go2MonitorPage(item) {
      const driverResp = await getDriverInfo({ id: item.driverId });
      const { videoDeviceId } = driverResp.data || {};
      const { href } = Router.resolve({
        path: "/video/monitor",
        query: { deviceNo: videoDeviceId },
      });
      window.open(href, "_blank");
    },

    cancelPunish(routeIndex, punishIndex) {
      this.$confirm("确认免责吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const route = this.list[routeIndex];
        const item = route.punishBillList[punishIndex];
        dealPunishAppeal(item.id)
          .then(() => {
            route.punishBillList[punishIndex].appealed = 1;
            this.$set(this.list, routeIndex, route);
            this.$message.success("操作成功");
          })
          .catch((e) => {
            console.log(e);
          });
      });
    },

    async ttsBroadcast(item) {
      const driverResp = await getDriverInfo({ id: item.driverId });
      const { videoDeviceId } = driverResp.data || {};
      await ttsBroadcast(videoDeviceId, "请注意行车安全。");
    },
  },
};
</script>
<style lang="less" scoped>
.together {
  font-size: 12px;
  color: red;
}

.grey {
  color: #909399 !important;
}

/deep/ .finish td {
  background: blanchedalmond !important;
}

/deep/ .pre-dispatch td {
  background: #e3fbc7 !important;
}

/deep/ .route-detail .no-hover {
  .table-expand {
    padding-left: 40px;
  }

  .expanded+tr {
    .el-table tr {
      background-color: rgba(224, 232, 251, 0.23) !important;

      &:hover {
        background-color: rgba(224, 232, 251, 0.23) !important;
      }
    }

    .table-expand {
      background-color: rgba(224, 232, 251, 0.23);
    }
  }

  .el-table--striped .el-table__body tr.el-table__row--striped td {
    background: #fff;
  }

  .expanded.el-table__row--striped+tr {
    .el-table tr {
      background-color: rgba(194, 255, 164, 0.26) !important;

      &:hover {
        background-color: rgba(194, 255, 164, 0.26) !important;
      }
    }

    .table-expand {
      background-color: rgba(194, 255, 164, 0.26);
    }
  }
}

.route-info {
  font-size: 15px;
  font-weight: bold;
  align-items: center;
  display: flex;
  justify-content: space-between;

  &.blue {
    color: #409eff;
  }

  .info {
    cursor: pointer;

    >span {
      margin-right: 20px;
    }
  }
}

.el-dropdown-link {
  margin-left: 10px;
  cursor: pointer;
  color: #409eff;
  font-size: 12px;
}

.pagination-sum {
  color: #f56c6c;
  margin: 0 10px;
}

.right-op-box {
  display: flex;
  align-items: center;

  .el-button--text {
    padding: 0;
    margin-right: 10px;
  }
}

.qc {
  margin-left: 10px;
  background-color: #409eff;
  cursor: pointer;
  color: white;
  padding: 5px 16px;
  border-radius: 6px;
  font-size: 13px;

  &.process0 {
    background-color: #409eff;
  }

  &.process1 {
    background-color: orange;
  }

  &.process2 {
    background-color: #56c556;
  }

  &.process3 {
    background-color: #f56c6c;
  }
}
</style>
